const comment = {
  'admin.comment.hetmet': 'Comment',
  'admin.comment.breadcrumb.heading': 'Comment List',
  'admin.comment.breadcrumb.parent': 'Comment',
  'admin.comment.column.author': 'Author',
  'admin.comment.column.comment': 'Comment',
  'admin.comment.label.message': 'Message',
  'admin.comment.error.message': 'Please enter the message!',
  'admin.comment.column.reply-for': 'Reply to',
  'admin.comment.column.created_at': 'Posted in',
  'admin.comment.reply-for': 'Reply to',
  'admin.comment.noti.reply-comment.success': 'Reply successfully!',
  'admin.comment.noti.reply-comment.failure': 'Reply unsuccessfully!',
  'admin.comment.noti.update-comment.success': 'Update successfully!',
  'admin.comment.noti.update-comment.failure': 'Update unsuccessfully!',
  'admin.comment.noti.delete-comment.success': 'Delete successfully!',
  'admin.comment.noti.delete-comment.failure': 'Delete unsuccessfully!',
  'admin.comment.modal.action.title.reply': 'Reply to $x',
  'admin.comment.modal.action.title.update': 'Update',
  'admin.comment.modal.action.btn.reply': 'Reply',
  'admin.comment.modal.action.btn.update': 'Update comment',
  'admin.comment.modal.delete.title': 'Confirm delete comment!',
  'admin.comment.modal.delete.description': 'You definitely want to delete this comment?',
};

const usAdminCommentLangLib = {
  ...comment,
};

export default usAdminCommentLangLib;
