/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllComments({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/comments?include=comment,post&${payload.params}` : '/comments?limit=0&include=comment,post',
    ));
    yield put(Actions.getAllCommentsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllCommentsFailure(messages));
    }
  }
}

function* createComment({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/comments', payload));
    yield put(Actions.createCommentSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createCommentFailure(messages));
    }
  }
}

function* createUserComment({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/comments/user', payload));
    yield put(Actions.createUserCommentSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createUserCommentFailure(messages));
    }
  }
}

function* updateComment({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/comments/${payload.id}`, payload.body));
    yield put(Actions.updateCommentSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateCommentFailure(messages));
    }
  }
}

function* deleteComment({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/comments/${payload}`));
    yield put(Actions.deleteCommentSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteCommentFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllCommentsRequest, getAllComments);
  yield takeLatest(Actions.createCommentRequest, createComment);
  yield takeLatest(Actions.createUserCommentRequest, createUserComment);
  yield takeLatest(Actions.updateCommentRequest, updateComment);
  yield takeLatest(Actions.deleteCommentRequest, deleteComment);
}
