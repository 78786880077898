const seo = {
  'admin.seo.hetmet': 'SEO management',
  'admin.seo.breadcrumb.heading': 'SEO management',
  'admin.seo.breadcrumb.parent': 'SEO',
  'admin.seo.btn.add': 'Add seo',
  'admin.seo.noti.create-seo.success': 'Add SEO success!',
  'admin.seo.noti.create-seo.failure': 'More SEO failed!Please try again later.',
  'admin.seo.noti.update-seo.success': 'SEO SEO update!',
  'admin.seo.noti.update-seo.failure': 'SEO update failed!Please try again later.',
  'admin.seo.noti.delete-seo.success': 'Delete SEO successfully',
  'admin.seo.noti.delete-seo.failure': 'Delete seo failed!Please try again later.',
  'admin.seo.modal.action.title.create': 'Add new',
  'admin.seo.modal.action.title.update': 'Update',
  'admin.seo.modal.action.title.details': 'Detail',
  'admin.seo.modal.action.btn.create': 'More',
  'admin.seo.modal.action.btn.update': 'Update',
  'admin.seo.modal.action.label.url': 'Path',
  'admin.seo.modal.action.label.type': 'Type',
  'admin.seo.modal.action.label.title': 'Title',
  'admin.seo.modal.action.label.image': 'Image',
  'admin.seo.modal.action.label.description': 'Describe',
  'admin.seo.modal.action.label.keyword': 'Key word',
  'admin.seo.modal.delete.title': 'Confirm delete SEO!',
  'admin.seo.modal.delete.description': 'Are you sure to delete this SEO?',
  'admin.seo.column.url': 'Path',
  'admin.seo.column.type': 'Type',
  'admin.seo.column.title': 'Title',
  'admin.seo.type.item.website': 'website',
  'admin.seo.type.item.article': 'Posts',
  'admin.seo.type.item.profile': 'profile',
  'admin.seo.type.item.book': 'Book',
  'admin.seo.error-msg.url': 'Please fill out this school!',
  'admin.seo.error-msg.type': 'Please fill out this school!',
  'admin.seo.error-msg.title': 'Please fill out this school!',
  'admin.seo.error-msg.image': 'Please fill out this school!',
};

const usAdminSEOLangLib = {
  ...seo,
};

export default usAdminSEOLangLib;
