const feedback = {
  'admin.feedback.hetmet': 'Feedback',
  'admin.feedback.breadcrumb.heading': 'List of feedback',
  'admin.feedback.breadcrumb.parent': 'Feedback',
  'admin.feedback.btn.add': 'Add new feedback',
  'admin.feedback.noti.create-feedback.success': 'Add successful feedback!',
  'admin.feedback.noti.create-feedback.failure': 'Add feedback failure!Please try again later.',
  'admin.feedback.noti.update-feedback.success': 'Update successful feedback!',
  'admin.feedback.noti.update-feedback.failure': 'Update feedback failure!Please try again later.',
  'admin.feedback.noti.delete-feedback.success': 'Delete successful feedback',
  'admin.feedback.noti.delete-feedback.failure': 'Delete failure!Please try again later.',
  'admin.feedback.modal.action.title.create': 'Add new feedback',
  'admin.feedback.modal.action.title.update': 'Update response',
  'admin.feedback.modal.action.title.details': 'Feedback details',
  'admin.feedback.modal.action.btn.create': 'Add feedback',
  'admin.feedback.modal.action.btn.update': 'Update response',
  'admin.feedback.modal.action.label.type': 'Type',
  'admin.feedback.modal.action.label.name': 'Sender',
  'admin.feedback.modal.action.label.phone': 'Phone number',
  'admin.feedback.modal.action.label.email': 'Email address',
  'admin.feedback.modal.action.label.address': 'Address',
  'admin.feedback.modal.action.label.content': 'Content',
  'admin.feedback.modal.delete.title': 'Confirm the feedback!',
  'admin.feedback.modal.delete.description': 'Are you sure to delete this response?',
  'admin.feedback.error-msg.type': 'Maximum of 50 characters!',
  'admin.feedback.error-msg.name': 'Please enter your full name!',
  'admin.feedback.column.type': 'Type',
  'admin.feedback.column.name': 'Sender',
  'admin.feedback.column.phone': 'Phone number',
  'admin.feedback.column.created_at': 'Sent date',
};

const usAdminFeedbackLangLib = {
  ...feedback,
};

export default usAdminFeedbackLangLib;
