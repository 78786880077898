const user = {};

const member = {
  'admin.user.member.hetmet': 'Thành viên - Tất cả người dùng',
  'admin.user.member.breadcrumb.heading': 'Tất cả người dùng',
  'admin.user.member.breadcrumb.parent': 'Thành viên',
  'admin.user.member.btn.add-new': 'Thêm người dùng mới',
  'admin.user.member.noti.create-member.title.success': 'Thêm thành viên mới thành công!',
  'admin.user.member.noti.create-member.title.failure': 'Thêm thành viên mới không thành công! Vui lòng thử lại sau.',
  'admin.user.member.noti.update-member.title.success': 'Cập nhật thành viên thành công!',
  'admin.user.member.noti.update-member.title.failure': 'Cập nhật thành viên không thành công! Vui lòng thử lại sau.',
  'admin.user.member.noti.delete-member.title.success': 'Xóa thành viên thành công!',
  'admin.user.member.noti.delete-member.title.failure': 'Xóa thành viên không thành công! Vui lòng thử lại sau.',
  'admin.user.member.noti.reset-password-member.title.success': 'Đặt lại mật khẩu của thành viên thành công!',
  'admin.user.member.noti.reset-password-member.title.failure': 'Đặt lại mật khẩu của thành viên không thành công! Vui lòng thử lại sau.',
  'admin.user.member.modal.add.title': 'Thêm người dùng mới',
  'admin.user.member.modal.update.title': 'Cập nhật thông tin người dùng',
  'admin.user.member.modal.detail.title': 'Thông tin chi tiết người dùng',
  'admin.user.member.modal.delete.title': 'Xác nhận xóa thành viên!',
  'admin.user.member.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa thành viên này?',
  'admin.user.member.modal.reset-password.title': 'Xác nhận đặt lại mật khẩu cho thành viên này!',
  'admin.user.member.modal.reset-password.label': 'Chọn cách nhận mật khẩu mới:',
  'admin.user.member.modal.reset-password.input-1': 'Đặt lại mật khẩu mặc định',
  'admin.user.member.modal.reset-password.input-2': 'Gửi đến Gmail',
  'admin.user.member.modal.reset-password.noti-1': 'Mật khẩu mới:',
};

const clients = {
  'admin.user.clients.hetmet': 'Thành viên - Tất cả khách hàng',
  'admin.user.clients.breadcrumb.heading': 'Tất cả khách hàng',
  'admin.user.clients.breadcrumb.parent': 'Thành viên',
  'admin.user.clients.btn.add-new': 'Thêm khách hàng mới',
  'admin.user.clients.noti.create-clients.title.success': 'Thêm khách hàng mới thành công!',
  'admin.user.clients.noti.create-clients.title.failure': 'Thêm khách hàng mới không thành công! Vui lòng thử lại sau.',
  'admin.user.clients.noti.update-clients.title.success': 'Cập nhật khách hàng thành công!',
  'admin.user.clients.noti.update-clients.title.failure': 'Cập nhật khách hàng không thành công! Vui lòng thử lại sau.',
  'admin.user.clients.noti.delete-clients.title.success': 'Xóa khách hàng thành công!',
  'admin.user.clients.noti.delete-clients.title.failure': 'Xóa khách hàng không thành công! Vui lòng thử lại sau.',
  'admin.user.clients.noti.reset-password-clients.title.success': 'Đặt lại mật khẩu của khách hàng thành công!',
  'admin.user.clients.noti.reset-password-clients.title.failure': 'Đặt lại mật khẩu của khách hàng không thành công! Vui lòng thử lại sau.',
  'admin.user.clients.modal.add.title': 'Thêm khách hàng mới',
  'admin.user.clients.modal.update.title': 'Cập nhật thông tin khách hàng',
  'admin.user.clients.modal.detail.title': 'Thông tin chi tiết khách hàng',
  'admin.user.clients.modal.delete.title': 'Xác nhận xóa khách hàng!',
  'admin.user.clients.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa khách hàng này?',
  'admin.user.clients.modal.reset-password.title': 'Xác nhận đặt lại mật khẩu cho khách hàng này!',
  'admin.user.clients.modal.reset-password.label': 'Chọn cách nhận mật khẩu mới:',
  'admin.user.clients.modal.reset-password.input-1': 'Đặt lại mật khẩu mặc định',
  'admin.user.clients.modal.reset-password.input-2': 'Gửi đến Gmail',
  'admin.user.clients.modal.reset-password.noti-1': 'Mật khẩu mới:',
};

const role = {
  'admin.user.role.hetmet': 'Thành viên - Vai trò',
  'admin.user.role.breadcrumb.heading': 'Vai trò',
  'admin.user.role.breadcrumb.parent': 'Thành viên',
  'admin.user.role.btn.add-new': 'Thêm vai trò mới',
  'admin.user.role.noti.create-role.title.success': 'Thêm vai trò mới thành công!',
  'admin.user.role.noti.create-role.title.failure': 'Thêm vai trò mới không thành công! Vui lòng thử lại sau.',
  'admin.user.role.noti.update-role.title.success': 'Cập nhật vai trò thành công!',
  'admin.user.role.noti.update-role.title.failure': 'Cập nhật vai trò không thành công! Vui lòng thử lại sau.',
  'admin.user.role.noti.delete-role.title.success': 'Xóa vai trò thành công!',
  'admin.user.role.noti.delete-role.title.failure': 'Xóa vai trò không thành công! Vui lòng thử lại sau.',
  'admin.user.role.modal.add.title': 'Thêm vai trò mới',
  'admin.user.role.modal.update.title': 'Cập nhật quyền cho vai trò',
  'admin.user.role.modal.delete.title': 'Xác nhận xóa vai trò!',
  'admin.user.role.modal.delete.sub-title': 'Bạn chắc chắn muốn xóa vai trò này?',
};

const permissions = {
  'admin.user.permissions.hetmet': 'Thành viên - Phân quyền',
  'admin.user.permissions.breadcrumb.heading': 'Phân quyền',
  'admin.user.permissions.breadcrumb.parent': 'Thành viên',
  'admin.user.permissions.btn.add-new': 'Phân quyền cho người dùng',
  'admin.user.permissions.noti.attach-role.title.success': 'Phân quyền cho người dùng thành công!',
  'admin.user.permissions.noti.attach-role.title.failure': 'Phân quyền cho người dùng không thành công! Vui lòng thử lại sau.',
  'admin.user.permissions.noti.revoke-role.title.success': 'Xóa phân quyền của người dùng thành công!',
  'admin.user.permissions.noti.revoke-role.title.failure': 'Xóa phân quyền của người dùng không thành công! Vui lòng thử lại sau.',
  'admin.user.permissions.modal.attach.title': 'Phân quyền cho người dùng',
  'admin.user.permissions.modal.revoke.title': 'Xác nhận xóa phân quyền của người dùng!',
  'admin.user.permissions.modal.revoke.sub-title': 'Bạn chắc chắn muốn xóa phân quyền của người dùng này?',
};

const customer = {
  'customer.code': 'Mã khách hàng',
  'customer.name': 'Tên khách hàng',
  'customer.name-require': 'Vui lòng nhập tên khách hàng',
  'customer.name-min-length': 'Ít nhất 5 ký tự',
  'customer.name-max-length': 'Lên đến 300 ký tự',
  //
  'customer.phone': 'Điện thoại',
  'customer.phone-require': 'Vui lòng nhập số điện thoại của khách hàng',
  'customer.phone-valid': 'Số điện thoại không hợp lệ',
  'customer.phone-length': 'Số điện thoại phải có 10 ký tự',

  //
  'customer.email-valid': 'Email không được định dạng chính xác',
  'customer.tax': 'Thuế',
  'customer.tax.code': 'Mã số thuế',
  'customer.tax.name': 'Tên',
  'customer.tax.phone': 'SĐT',
  'customer.tax.address': 'Địa chỉ',
  'customer.tax.email': 'Email',
  'customer.isCompany': 'Khách là doanh nghiệp?',
  'customer.isZns': 'ZNS',
  //
  'customer.address': 'Địa chỉ',
  //
  'customer.note': 'Ghi chú',
  //
  'customer.type': 'Loại khách hàng',
  //
  'customer.bill': 'Xuất hóa đơn',
  'customer.bill.info': 'Thông tin xuất hóa đơn',
  //
  'customer.city': 'Thành phố',
  'customer.city-require': 'Vui lòng chọn thành phố',
  //
  'customer.district': 'Huyện',
  'customer.district-require': 'Vui lòng chọn huyện',
  //
  'customer.ward-require': 'Vui lòng chọn phường xã',
  'customer.ward': 'Phường/Xã',
  //
  'customer.excel': 'Nhập từ tệp excel',
  'customer.excel-require': 'Vui lòng tải lên tệp excel',
  //
  'customer.btn-add': 'Thêm khách hàng',
  'customer.btn-edit': 'Chỉnh sửa khách hàng',
  'customer.btn-update-info': 'Cập nhật thông tin người nhận',
  'customer.btn-save-info': 'Lưu thông tin khách hàng',
  'customer.btn-back': 'Trở lại',
  'customer.confirm': 'Xác nhận',
  'customer.cancel': 'Hủy bỏ',
  'customer.add.store': 'Thêm cửa hàng',
  'customer.phone.text': 'Số điện thoại bằng chữ (Shopee)',
  'customer.add.tax': 'Thêm thông tin thuế',
  //
  'customer.confirm-add': 'Xác nhận thêm khách hàng',
  'customer.confirm-sure-add': 'Bạn chắc chắn sẽ thêm khách hàng này?',
  'customer.confirm-edit': 'Xác nhận Chỉnh sửa khách hàng',
  'customer.confirm-sure-edit': 'Bạn chắc chắn sẽ chỉnh sửa khách hàng này?',
  'customer.confirm-delete': 'Xác nhận xóa khách hàng',
  'customer.confirm-sure-delete': 'Bạn chắc chắn sẽ xóa khách hàng này?',
  'customer.confirm-delete-tax': 'Xác nhận xóa thông tin thuế',
  'customer.confirm-sure-delete-tax': 'Bạn chắc chắn sẽ thông tin thuế này?',
  //
  'user.position.add-confirm': 'Xác nhận thêm chức vụ',
  'user.position.add-confirm-sure': 'Bạn chắc chắn sẽ thêm chức vụ này',
  'user.position.add-success': 'Thêm chức vụ thành công',
  'user.position.edit': 'Chỉnh sửa chức vụ',
  'user.position.edit-success': 'Chỉnh sửa chức vụ thành công',
  'user.position.edit-confirm': 'Xác nhận sửa chức vụ',
  'user.position.edit-confirm-sure': 'Bạn chắc chắn sẽ sửa chức vụ này',
  'user.position.delete': 'Xóa chức vụ',
  'user.position.delete-success': 'Xóa chức vụ thành công',
  'user.position.delete-confirm': 'Bạn có đồng ý xóa chức vụ này?',
  'user.position.delete-confirm-sure': 'Xác nhận xóa chức vụ?',
  'user.position.delete-confirm-sure-sub': 'Bạn chắc chăn sẽ xóa chức vụ này',
  //

  'customer.history.nameProduct': 'Tên sản phẩm',
  'customer.history.size': 'Kích cỡ',
  'customer.history.amount': 'Số lượng',
  'customer.history.price': 'Giá',
  'customer.history.status': 'Trạng thái',
  'customer.certificate.submitted': 'Đã nộp',
};

const vnAdminUserLangLib = {
  ...user,
  ...member,
  ...clients,
  ...role,
  ...permissions,
  ...customer,
};

export default vnAdminUserLangLib;
