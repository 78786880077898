// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllMenusRequest: false,
  isGetAllMenusSuccess: false,
  isGetAllMenusFailure: false,
  getAllMenusState: {},
  //
  isCreateMenuRequest: false,
  isCreateMenuSuccess: false,
  isCreateMenuFailure: false,
  //
  isUpdateMenuRequest: false,
  isUpdateMenuSuccess: false,
  isUpdateMenuFailure: false,
  //
  isDeleteMenuRequest: false,
  isDeleteMenuSuccess: false,
  isDeleteMenuFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all menus
    [Actions.getAllMenusRequest]: (state) => ({
      ...state,
      isGetAllMenusRequest: true,
      isGetAllMenusSuccess: false,
      isGetAllMenusFailure: false,
    }),
    [Actions.getAllMenusSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllMenusRequest: false,
      isGetAllMenusSuccess: true,
      isGetAllMenusFailure: false,
      getAllMenusState: payload,
    }),
    [Actions.getAllMenusFailure]: (state, { payload }) => ({
      ...state,
      isGetAllMenusRequest: false,
      isGetAllMenusSuccess: false,
      isGetAllMenusFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create menu
    [Actions.createMenuRequest]: (state) => ({
      ...state,
      isCreateMenuRequest: true,
      isCreateMenuSuccess: false,
      isCreateMenuFailure: false,
    }),
    [Actions.createMenuSuccess]: (state, { payload }) => ({
      ...state,
      isCreateMenuRequest: false,
      isCreateMenuSuccess: true,
      isCreateMenuFailure: false,
      getAllMenusState: {
        ...state.getAllMenusState,
        data: [payload.data, ...state.getAllMenusState.data],
      },
    }),
    [Actions.createMenuFailure]: (state, { payload }) => ({
      ...state,
      isCreateMenuRequest: false,
      isCreateMenuSuccess: false,
      isCreateMenuFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateMenuState]: (state) => ({
      ...state,
      isCreateMenuRequest: false,
      isCreateMenuSuccess: false,
      isCreateMenuFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update menu
    [Actions.updateMenuRequest]: (state) => ({
      ...state,
      isUpdateMenuRequest: true,
      isUpdateMenuSuccess: false,
      isUpdateMenuFailure: false,
    }),
    [Actions.updateMenuSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateMenuRequest: false,
      isUpdateMenuSuccess: true,
      isUpdateMenuFailure: false,
      getAllMenusState: {
        ...state.getAllMenusState,
        data: [
          payload.data,
          ...state.getAllMenusState.data.filter((item) => item.id !== payload.data.id),
        ],
      },
    }),
    [Actions.updateMenuFailure]: (state, { payload }) => ({
      ...state,
      isUpdateMenuRequest: false,
      isUpdateMenuSuccess: false,
      isUpdateMenuFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateMenuState]: (state) => ({
      ...state,
      isUpdateMenuRequest: false,
      isUpdateMenuSuccess: false,
      isUpdateMenuFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete menu
    [Actions.deleteMenuRequest]: (state) => ({
      ...state,
      isDeleteMenuRequest: true,
      isDeleteMenuSuccess: false,
      isDeleteMenuFailure: false,
    }),
    [Actions.deleteMenuSuccess]: (state) => ({
      ...state,
      isDeleteMenuRequest: false,
      isDeleteMenuSuccess: true,
      isDeleteMenuFailure: false,
    }),
    [Actions.deleteMenuFailure]: (state, { payload }) => ({
      ...state,
      isDeleteMenuRequest: false,
      isDeleteMenuSuccess: false,
      isDeleteMenuFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteMenuState]: (state) => ({
      ...state,
      isDeleteMenuRequest: false,
      isDeleteMenuSuccess: false,
      isDeleteMenuFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetMenuItemState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
