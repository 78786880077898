// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  // Get All Kiotviet Category
  isFirstGetAllKiotvietCategories: false,
  isGetAllKiotvietCategoriesRequest: false,
  isGetAllKiotvietCategoriesSuccess: false,
  isGetAllKiotvietCategoriesFailure: false,
  getAllKiotvietCategoriesState: {},
  // Create Kiotviet Category
  isCreateKiotvietCategoryRequest: false,
  isCreateKiotvietCategorySuccess: false,
  isCreateKiotvietCategoryFailure: false,
  // Update Kiotviet Category
  isUpdateKiotvietCategoryRequest: false,
  isUpdateKiotvietCategorySuccess: false,
  isUpdateKiotvietCategoryFailure: false,
  // Delete Kiotviet Category
  isDeleteKiotvietCategoryRequest: false,
  isDeleteKiotvietCategorySuccess: false,
  isDeleteKiotvietCategoryFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Kiotviet Category
    [Actions.getAllKiotvietCategoriesRequest]: (state) => ({
      ...state,
      isGetAllKiotvietCategoriesRequest: true,
      isGetAllKiotvietCategoriesSuccess: false,
      isGetAllKiotvietCategoriesFailure: false,
    }),
    [Actions.getAllKiotvietCategoriesSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllKiotvietCategories: true,
      isGetAllKiotvietCategoriesRequest: false,
      isGetAllKiotvietCategoriesSuccess: true,
      isGetAllKiotvietCategoriesFailure: false,
      getAllKiotvietCategoriesState: payload,
    }),
    [Actions.getAllKiotvietCategoriesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllKiotvietCategoriesRequest: false,
      isGetAllKiotvietCategoriesSuccess: false,
      isGetAllKiotvietCategoriesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Kiotviet Category
    [Actions.createKiotvietCategoryRequest]: (state) => ({
      ...state,
      isCreateKiotvietCategoryRequest: true,
      isCreateKiotvietCategorySuccess: false,
      isCreateKiotvietCategoryFailure: false,
    }),
    [Actions.createKiotvietCategorySuccess]: (state) => ({
      ...state,
      isCreateKiotvietCategoryRequest: false,
      isCreateKiotvietCategorySuccess: true,
      isCreateKiotvietCategoryFailure: false,
    }),
    [Actions.createKiotvietCategoryFailure]: (state, { payload }) => ({
      ...state,
      isCreateKiotvietCategoryRequest: false,
      isCreateKiotvietCategorySuccess: false,
      isCreateKiotvietCategoryFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateKiotvietCategory]: (state) => ({
      ...state,
      isCreateKiotvietCategoryRequest: false,
      isCreateKiotvietCategorySuccess: false,
      isCreateKiotvietCategoryFailure: false,
    }),
    // #endregion
    // #region : Update Kiotviet Category
    [Actions.updateKiotvietCategoryRequest]: (state) => ({
      ...state,
      isUpdateKiotvietCategoryRequest: true,
      isUpdateKiotvietCategorySuccess: false,
      isUpdateKiotvietCategoryFailure: false,
    }),
    [Actions.updateKiotvietCategorySuccess]: (state) => ({
      ...state,
      isUpdateKiotvietCategoryRequest: false,
      isUpdateKiotvietCategorySuccess: true,
      isUpdateKiotvietCategoryFailure: false,
    }),
    [Actions.updateKiotvietCategoryFailure]: (state, { payload }) => ({
      ...state,
      isUpdateKiotvietCategoryRequest: false,
      isUpdateKiotvietCategorySuccess: false,
      isUpdateKiotvietCategoryFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateKiotvietCategory]: (state) => ({
      ...state,
      isUpdateKiotvietCategoryRequest: false,
      isUpdateKiotvietCategorySuccess: false,
      isUpdateKiotvietCategoryFailure: false,
    }),
    // #endregion
    // #region : Delete Kiotviet Category
    [Actions.deleteKiotvietCategoryRequest]: (state) => ({
      ...state,
      isDeleteKiotvietCategoryRequest: true,
      isDeleteKiotvietCategorySuccess: false,
      isDeleteKiotvietCategoryFailure: false,
    }),
    [Actions.deleteKiotvietCategorySuccess]: (state) => ({
      ...state,
      isDeleteKiotvietCategoryRequest: false,
      isDeleteKiotvietCategorySuccess: true,
      isDeleteKiotvietCategoryFailure: false,
    }),
    [Actions.deleteKiotvietCategoryFailure]: (state, { payload }) => ({
      ...state,
      isDeleteKiotvietCategoryRequest: false,
      isDeleteKiotvietCategorySuccess: false,
      isDeleteKiotvietCategoryFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteKiotvietCategory]: (state) => ({
      ...state,
      isDeleteKiotvietCategoryRequest: false,
      isDeleteKiotvietCategorySuccess: false,
      isDeleteKiotvietCategoryFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetKiotvietCategoryState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
