/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllFeedbacks({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/feedback?${payload.params}` : '/feedback?limit=0',
    ));
    yield put(Actions.getAllFeedbacksSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllFeedbacksFailure(messages));
    }
  }
}

function* createFeedback({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/feedback', payload));
    yield put(Actions.createFeedbackSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createFeedbackFailure(messages));
    }
  }
}

function* updateFeedback({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/feedback/${payload.id}`, payload.body));
    yield put(Actions.updateFeedbackSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateFeedbackFailure(messages));
    }
  }
}

function* deleteFeedback({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/feedback/${payload}`));
    yield put(Actions.deleteFeedbackSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteFeedbackFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllFeedbacksRequest, getAllFeedbacks);
  yield takeLatest(Actions.createFeedbackRequest, createFeedback);
  yield takeLatest(Actions.updateFeedbackRequest, updateFeedback);
  yield takeLatest(Actions.deleteFeedbackRequest, deleteFeedback);
}
