// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllKiotvietPricesRequest = createAction('GET_ALL_KIOTVIET_PRICES_REQUEST');
export const getAllKiotvietPricesSuccess = createAction('GET_ALL_KIOTVIET_PRICES_SUCCESS');
export const getAllKiotvietPricesFailure = createAction('GET_ALL_KIOTVIET_PRICES_FAILURE');

export const createKiotvietPriceRequest = createAction('CREATE_KIOTVIET_PRICE_REQUEST');
export const createKiotvietPriceSuccess = createAction('CREATE_KIOTVIET_PRICE_SUCCESS');
export const createKiotvietPriceFailure = createAction('CREATE_KIOTVIET_PRICE_FAILURE');
export const resetCreateKiotvietPrice = createAction('RESET_CREATE_KIOTVIET_PRICE');

export const updateKiotvietPriceRequest = createAction('UPDATE_KIOTVIET_PRICE_REQUEST');
export const updateKiotvietPriceSuccess = createAction('UPDATE_KIOTVIET_PRICE_SUCCESS');
export const updateKiotvietPriceFailure = createAction('UPDATE_KIOTVIET_PRICE_FAILURE');
export const resetUpdateKiotvietPrice = createAction('RESET_UPDATE_KIOTVIET_PRICE');

export const deleteKiotvietPriceRequest = createAction('DELETE_KIOTVIET_PRICE_REQUEST');
export const deleteKiotvietPriceSuccess = createAction('DELETE_KIOTVIET_PRICE_SUCCESS');
export const deleteKiotvietPriceFailure = createAction('DELETE_KIOTVIET_PRICE_FAILURE');
export const resetDeleteKiotvietPrice = createAction('RESET_DELETE_KIOTVIET_PRICE');

export const resetKiotvietPriceState = createAction('RESET_KIOTVIET_PRICE_STATE');
