import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';

import { store } from '~/redux/storeConfig/store';
import { LoadingPage } from './pages/Other';
import reportWebVitals from './reportWebVitals';

const App = React.lazy(() => import('./App'));

ReactDOM.render(
  <Provider store={store}>
    <HttpsRedirect>
      <Suspense fallback={<LoadingPage />}>
        <App />
      </Suspense>
    </HttpsRedirect>
  </Provider>,
  document.getElementById('root'),
);

reportWebVitals();
