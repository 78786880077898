import { ReactComponent as NoData } from '~/assets/common/no_data.svg';
import { translate } from '~/helpers/utils';

export const NodataSection = () => (
  <div
    className="no-data-section d-flex flex-column align-items-center w-100 mt-4"
    style={{ overflow: 'hidden' }}
  >
    <NoData style={{ height: '200px', width: '100%' }} />
    <h2 className="mt-4" style={{ fontWeight: ' 600' }}>
      {translate('common.content-empty')}
    </h2>
  </div>
);
