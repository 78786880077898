import usAuthLoginLangLib from './auth/login';
import usAdminLangLib from './admin';
import usOtherLangLib from './other';

const usPageLangLib = {
  ...usAuthLoginLangLib,
  ...usAdminLangLib,
  ...usOtherLangLib,
};

export default usPageLangLib;
