// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllProductsKiotvietRequest = createAction('GET_ALL_PRODUCTS_KIOTVIET_REQUEST');
export const getAllProductsKiotvietSuccess = createAction('GET_ALL_PRODUCTS_KIOTVIET_SUCCESS');
export const getAllProductsKiotvietFailure = createAction('GET_ALL_PRODUCTS_KIOTVIET_FAILURE');

export const updateProductKiotvietRequest = createAction('UPDATE_PRODUCT_KIOTVIET_REQUEST');
export const updateProductKiotvietSuccess = createAction('UPDATE_PRODUCT_KIOTVIET_SUCCESS');
export const updateProductKiotvietFailure = createAction('UPDATE_PRODUCT_KIOTVIET_FAILURE');
export const resetUpdateProductKiotviet = createAction('RESET_UPDATE_PRODUCT_KIOTVIET');

export const resetProductKiotvietState = createAction('RESET_PRODUCT_KIOTVIET_STATE');
