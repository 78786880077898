const appearance = {};

const menus = {
  'admin.appearance.menus.hetmet': 'Menus - Appearance',
  'admin.appearance.menus.breadcrumb.heading': 'Menus',
  'admin.appearance.menus.breadcrumb.parent': 'Appearance',
  //
  'admin.appearance.loading-title': 'Starting the menu',
  'admin.appearance.title-create-new-menu': 'Create your first menu below',
  'admin.appearance.title-select-menu': 'Select a menu to edit',
  'admin.appearance.title-or': 'or',
  'admin.appearance.link-create-new-menu': 'create a new menu',
  'admin.appearance.dont-forget': 'Do not forget to save your changes!',
  'admin.appearance.section-failure.error-loading-menu':
    'Error occurs when creating the menu, please try again later!',
  'admin.appearance.section-failure.error-create-menu':
    'Error occurs when creating the menu, please try again later!',
  'admin.appearance.section-failure.error-save-menu':
    'Error occurs when save change menu, please try again later!',
  'admin.appearance.menu-frame-left.title': 'Add menu items',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.title':
    'Custom Links',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.link':
    'URL',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.title':
    'Link Text',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.target':
    'Target',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.description':
    'Description',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.button.add-menu':
    'Add to Menu',
  'admin.appearance.menu-frame-left.menu-settings-column.pages.title': 'Post Type',
  'admin.appearance.menu-frame-left.menu-settings-column.category-post.title': 'Post catalog',
  'admin.appearance.menu-frame-left.menu-settings-column.category-product.title': 'Product portfolio',
  'admin.appearance.menu-frame-left.menu-settings-column.group-post.title': 'Article group',
  'admin.appearance.menu-frame-left.menu-settings-column.group-product.title': 'Product Groups',
  'admin.appearance.menu-frame-left.menu-settings-column.trademark.title': 'Trademark',
  'admin.appearance.menu-frame-left.menu-settings-column.post.title': 'Posts',
  'admin.appearance.menu-frame-left.menu-settings-column.product.title': 'Product',
  'admin.appearance.menu-frame-right.title': 'Menu structure',
  'admin.appearance.menu-frame-right.menu-management-column.label.key': 'Key',
  'admin.appearance.menu-frame-right.menu-management-column.label.title':
    'Menu Name',
  'admin.appearance.menu-frame-right.menu-management-column.subtitle-first-create-menu':
    'Give your menu a name, then click Create Menu.',
  'admin.appearance.menu-frame-right.menu-management-column.btn.create-menu':
    'Create Menu',
  'admin.appearance.menu-frame-right.menu-management-column.btn.save-menu':
    'Save Menu',
  'admin.appearance.menu-frame-right.menu-management-column.btn.remove-menu':
    'Remove Menu',
  'admin.appearance.menu-frame-right.tree-menu.empty-title':
    'Add menu items from the column on the left.',
  'admin.appearance.menu-frame-right.tree-menu.btn.remove-menu-item': 'Remove',
  'admin.appearance.menu-frame-right.tree-menu.btn.save-changes-menu-item':
    'Save changes',
  'admin.appearance.modal.confirm-remove-menu.title':
    'Confirm the deletion of the Menu!',
  'admin.appearance.modal.confirm-remove-menu.sub-title':
    'You definitely want to delete this Menu?',
  'admin.appearance.noti.create-menu.title.success': 'Add a successful menu!',
  'admin.appearance.noti.save-menu.title.success': 'Save the successful menu!',
  'admin.appearance.noti.remove-menu.title.success':
    'Delete the Menu successfully!',
  'admin.appearance.noti.remove-menu.title.failure':
    'Delete a failure Menu, please try again later!',
  'admin.appearance.customLinkTargetSelectList.self':
    'Open the page on the current tab',
  'admin.appearance.customLinkTargetSelectList.blank': 'Move to the new tab',
};

const slider = {
  'admin.appearance.slider.hetmet': 'Slider - Appearance',
  'admin.appearance.slider.breadcrumb.heading': 'Slider',
  'admin.appearance.slider.breadcrumb.parent': 'Appearance',
  'admin.appearance.slider.btn.add-new': 'Add slider',
  'admin.appearance.slider.noti.create-slider.title.success':
    'Create successful slider!',
  'admin.appearance.slider.noti.create-slider.title.failure':
    'Create unsuccessful slider! Please try again later.',
  'admin.appearance.slider.noti.update-slider.title.success':
    'Update Slider successfully!',
  'admin.appearance.slider.noti.update-slider.title.failure':
    'Slider update is unsuccessful! Please try again later.',
  'admin.appearance.slider.noti.delete-slider.title.success':
    'Delete Slider successfully!',
  'admin.appearance.slider.noti.delete-slider.title.failure':
    'Delete Slider failed! Please try again later.',
  //
  'admin.appearance.slider.modal.add.title': 'Add slider',
  'admin.appearance.slider.modal.add.label.key': 'Key',
  'admin.appearance.slider.modal.add.label.title': 'Title',
  'admin.appearance.slider.modal.add.label.items': 'Photo list',
  'admin.appearance.slider.modal.add.label.image': 'Image',
  'admin.appearance.slider.modal.add.label.item-title': 'Title',
  'admin.appearance.slider.modal.add.label.url': 'URL',
  'admin.appearance.slider.modal.add.label.description': 'Describe',
  'admin.appearance.slider.modal.add.btn.upload-image': 'Upload new photos',
  'admin.appearance.slider.modal.add.btn.add-slider': 'Add Slider photo',
  //
  'admin.appearance.slider.modal.details.title': 'Slider details',
  //
  'admin.appearance.slider.modal.update.title': 'Slider update',
  //
  'admin.appearance.slider.modal.delete.title': 'Confirm Slider deletion!',
  'admin.appearance.slider.modal.delete.description':
    'Are you sure you want to delete this slider?',
};

const usAdminAppearanceLangLib = {
  ...appearance,
  ...menus,
  ...slider,
};

export default usAdminAppearanceLangLib;
