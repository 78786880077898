// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const handleOpenModalMediaLibrary = createAction(
  'HANDLE_OPEN_MODAL_MEDIA_LIBRARY',
);
export const handleOpenModalMediaLibraryMultiple = createAction(
  'HANDLE_OPEN_MODAL_MEDIA_LIBRARY_MULTIPLE',
);
export const handleOpenModalMediaLibraryWithSelectedItem = createAction(
  'HANDLE_OPEN_MODAL_MEDIA_LIBRARY_WITH_SELECTED_ITEM',
);
export const handleCloseModalMediaLibrary = createAction(
  'HANDLE_CLOSE_MODAL_MEDIA_LIBRARY',
);
export const handleSelectedMedia = createAction('HANDLE_SELECTED_MEDIA');
export const resetSelectedMedia = createAction('RESET_SELECTED_MEDIA');
export const handleOpenModalCropImage = createAction('HANDLE_OPEN_MODAL_CROP_IMAGE');
export const handleCloseModalCropImage = createAction('HANDLE_CLOSE_MODAL_CROP_IMAGE');
