const posts = {
};

const allPosts = {
  'admin.posts.all-posts.hetmet.product-1': 'All products',
  'admin.posts.all-posts.hetmet.product-2': 'Products',
  'admin.posts.all-posts.hetmet.article-1': 'All posts',
  'admin.posts.all-posts.hetmet.article-2': 'Posts',
  'admin.posts.all-posts.hetmet.course-1': 'All courses',
  'admin.posts.all-posts.hetmet.course-2': 'Courses',
  'admin.posts.all-posts.breadcrumb.heading.product': 'Product',
  'admin.posts.all-posts.breadcrumb.heading.course': 'Course',
  'admin.posts.all-posts.breadcrumb.heading.article': 'Posts',
  'admin.posts.all-posts.breadcrumb.parent': 'All articles - Posts',
  'admin.posts.all-posts.btn.add-new.product': 'New product',
  'admin.posts.all-posts.btn.add-new.course': 'New course',
  'admin.posts.all-posts.btn.add-new.article': 'Write a new article',
  'admin.posts.all-posts.column.title': 'Title',
  'admin.posts.all-posts.column.image': 'Image',
  'admin.posts.all-posts.column.author': 'Author',
  'admin.posts.all-posts.column.cat-primary': 'Main category',
  'admin.posts.all-posts.column.cat-primary.empty': '(Drum)',
  'admin.posts.all-posts.column.time': 'Time',
  'admin.posts.all-posts.column.time.created-title': 'The nearest update',
  'admin.posts.all-posts.column.time.updated-title': 'Published',
  'admin.posts.all-posts.column.slug': 'Path',
  'admin.posts.all-posts.modal.delete.title': 'Confirm the deletion of $x!',
  'admin.posts.all-posts.modal.delete.title-product': 'Confirm the deletion of the product!',
  'admin.posts.all-posts.modal.delete.title-article': 'Confirm the deletion of the article!',
  'admin.posts.all-posts.modal.delete.title-course': 'Confirm the deletion of the course!',
  'admin.posts.all-posts.modal.delete.title-order': 'Confirm the deletion of the order!',
  'admin.posts.all-posts.modal.delete.description': 'The article certainly wants to delete this $x? Your actions will not be completed.',
  'admin.posts.all-posts.modal.delete.description-product': 'The article certainly wants to delete this product? Your actions will not be completed.',
  'admin.posts.all-posts.modal.delete.description-article': 'The article certainly wants to delete this article? Your actions will not be completed.',
  'admin.posts.all-posts.modal.delete.description-course': 'The article certainly wants to delete this course? Your actions will not be completed.',
  'admin.posts.all-posts.modal.delete.description-order': 'The article certainly wants to delete this order? Your actions will not be completed.',
  'admin.posts.all-posts.noti.delete.success': 'Delete $x successfully!',
  'admin.posts.all-posts.noti.delete.success-product': 'Delete the product successfully!',
  'admin.posts.all-posts.noti.delete.success-article': 'Delete the article successfully!',
  'admin.posts.all-posts.noti.delete.success-course': 'Delete the course successfully!',
  'admin.posts.all-posts.noti.delete.failure': 'Delete $x that fails! Please try again later.',
  'admin.posts.all-posts.noti.delete.failure-product': 'Delete the product that fails! Please try again later.',
  'admin.posts.all-posts.noti.delete.failure-article': 'Delete the article that fails! Please try again later.',
  'admin.posts.all-posts.noti.delete.failure-course': 'Delete the course that fails! Please try again later.',
};

const editPost = {
  'admin.posts.edit-new.hetmet.product': 'Product editing - Products',
  'admin.posts.edit-new.hetmet.course': 'Course editing - Courses',
  'admin.posts.edit-new.hetmet.article': 'Post editing - Posts',
  'admin.posts.edit-new.breadcrumb.heading.product': 'Edit',
  'admin.posts.edit-new.breadcrumb.heading.article': 'Edit',
  'admin.posts.edit-new.breadcrumb.heading.course': 'Edit',
  'admin.posts.edit-new.postbox.submitdiv.btn-update': 'Update',
  'admin.posts.edit-new.postbox.submitdiv.link-delete': 'Delete the article',
  'admin.posts.edit-new.postbox.submitdiv.value.post-status.publish': 'Published',
  'admin.posts.edit-new.postbox.submitdiv.label.post-curtime-create': 'Posted at:',
  'admin.posts.edit-new.postbox.submitdiv.label.post-curtime-update': 'The nearest update:',
  'admin.posts.edit-new.noti.update-post.success': 'Update the article successfully!',
  'admin.posts.edit-new.noti.update-post.failure': 'Update the article failure!Please try again later.',
};

const addNewPost = {
  'admin.posts.add-new.hetmet.product': 'New products - Products',
  'admin.posts.add-new.hetmet.article': 'Write new articles - Posts',
  'admin.posts.add-new.hetmet.course': 'New course - Courses',
  'admin.posts.add-new.breadcrumb.heading.product': 'Add new',
  'admin.posts.add-new.breadcrumb.heading.article': 'Add new',
  'admin.posts.add-new.breadcrumb.heading.course': 'Course',
  'admin.posts.add-new.breadcrumb.parent': 'Posts',
  'admin.posts.add-new.rendering-title': 'Initializing more articles!',
  'admin.posts.add-new.render-failure-title': 'Initialize more failure articles!Please try again later.',
  'admin.posts.add-new.section-settings.toggle-title': 'Display options',
  'admin.posts.add-new.section-settings.view-mode.label': 'View mode',
  'admin.posts.add-new.section-settings.view-mode.description-1': 'The components of the article have symbols',
  'admin.posts.add-new.section-settings.view-mode.description-2': 'Can move by holding the mouse and dragging.',
  'admin.posts.add-new.section-settings.view-mode.radio.static-title': 'Static view',
  'admin.posts.add-new.section-settings.view-mode.radio.dynamic-title': 'Dynamic view',
  'admin.posts.add-new.section-settings.column.label': 'Ingredients of the article',
  'admin.posts.add-new.section-settings.column.description-1': 'Some components of the article can be displayed or hidden by pressing or unchecking.Expand or shrink the components by clicking the icon',
  'admin.posts.add-new.section-settings.column.description-2': 'On the title bar.',
  //
  'admin.posts.add-new.postbox.link-edit': 'Edit',
  'admin.posts.add-new.postbox.link-cancel': 'Cancel',
  'admin.posts.add-new.postbox.link-add-category': 'Add category',
  //
  'admin.posts.add-new.postbox.submitdiv.title': 'Post',
  'admin.posts.add-new.postbox.submitdiv.btn-post': 'Post',
  'admin.posts.add-new.postbox.submitdiv.label.post-status': 'Status',
  'admin.posts.add-new.postbox.submitdiv.label.post-visibility': 'Display',
  'admin.posts.add-new.postbox.submitdiv.label.post-curtime-create': 'Post',
  'admin.posts.add-new.postbox.submitdiv.label.post-curtime-update': 'Posted',
  'admin.posts.add-new.postbox.submitdiv.value.post-status.draft': 'Draft',
  'admin.posts.add-new.postbox.submitdiv.value.post-visibility.visible': 'Public',
  'admin.posts.add-new.postbox.submitdiv.value.post-visibility.hidden': 'Private',
  'admin.posts.add-new.postbox.submitdiv.value.post-curtime-create': 'right away',
  //
  'admin.posts.add-new.postbox.descriptiondiv.title': 'Describe',
  'admin.posts.add-new.postbox.descriptiondiv.label.description': 'Short description',
  //
  'admin.posts.add-new.postbox.categorydiv.title': 'Category',
  'admin.posts.add-new.postbox.categorydiv.label.category-all': 'All of the files',
  'admin.posts.add-new.postbox.categorydiv.label.popular-category': 'Most',
  'admin.posts.add-new.postbox.categorydiv.dropdown.label': '—— Category Father ——',
  'admin.posts.add-new.postbox.categorydiv.input.title.placeholder': 'Name list',
  'admin.posts.add-new.postbox.categorydiv.input.title.error': 'Please fill in the Category Name!',
  'admin.posts.add-new.postbox.categorydiv.error-msg.create-category-failure': 'Create a new category unsuccessful!Please try again later.',
  //
  'admin.posts.add-new.postbox.titlediv.label.title': 'Add title',
  //
  'admin.posts.add-new.postbox.slugdiv.label.slug': 'Static link',
  //
  'admin.posts.add-new.postbox.featureimagediv.title': 'Avatar',
  'admin.posts.add-new.postbox.featureimagediv.link-add': 'Place avatar',
  'admin.posts.add-new.postbox.featureimagediv.link-remove': 'Delete avatar',
  'admin.posts.add-new.postbox.featureimagediv.link-description': 'Click the image to fix or update',
  //
  'admin.posts.add-new.postbox.mediadiv.title': 'Photo list',
  'admin.posts.add-new.postbox.mediadiv.link-add': 'More photos',
  //
  'admin.posts.add-new.postbox.contentdiv.title.product': 'Product content',
  'admin.posts.add-new.postbox.contentdiv.title.course': 'Course content',
  'admin.posts.add-new.postbox.contentdiv.title.article': 'content',
  'admin.posts.add-new.postbox.contentdiv.label.title.product': 'Product title',
  'admin.posts.add-new.postbox.contentdiv.label.title.course': 'Course title',
  'admin.posts.add-new.postbox.contentdiv.label.title.chapter.course': 'Chapter title',
  'admin.posts.add-new.postbox.coursediv.chapter': 'Chapter',
  'admin.posts.add-new.postbox.coursediv.label.chapter.image': 'Chapter image',
  'admin.posts.add-new.postbox.coursediv.label.chapter.description': 'Chapter description',
  'admin.posts.add-new.postbox.contentdiv.label.title.article': 'Post title',
  'admin.posts.add-new.postbox.contentdiv.label.slug': 'Static link',
  'admin.posts.add-new.postbox.contentdiv.label.content.product': 'Content of the product',
  'admin.posts.add-new.postbox.contentdiv.label.content.course': 'Content of the course',
  'admin.posts.add-new.postbox.contentdiv.label.content.article': 'Content of the article',
  'admin.posts.add-new.postbox.contentdiv.label.ingredient': 'Drug ingredients',
  'admin.posts.add-new.postbox.contentdiv.label.info': 'Information',
  'admin.posts.add-new.postbox.contentdiv.label.info.form': 'Form',
  'admin.posts.add-new.postbox.contentdiv.label.info.bag': 'Bag',
  'admin.posts.add-new.postbox.contentdiv.label.info.ingredient': 'Ingredient',
  'admin.posts.add-new.postbox.contentdiv.label.info.origin': 'Origin',
  'admin.posts.add-new.postbox.contentdiv.label.info.producer': 'Producer',
  'admin.posts.add-new.postbox.contentdiv.label.info.serial': 'Serial',
  'admin.posts.add-new.postbox.contentdiv.label.uses': 'Uses',
  'admin.posts.add-new.postbox.contentdiv.label.usage': 'User manual',
  'admin.posts.add-new.postbox.contentdiv.label.description': 'Describe',
  'admin.posts.add-new.postbox.contentdiv.label.side_effect': 'Side Effect',
  'admin.posts.add-new.postbox.contentdiv.label.note': 'Note',
  'admin.posts.add-new.postbox.contentdiv.label.preservat': 'Preservat',
  //
  'admin.posts.add-new.postbox.metadiv.title': 'Data fields',
  //
  'admin.posts.add-new.postbox.groupdiv.title': 'Group',
  //
  'admin.posts.add-new.postbox.relatediv.title': 'Post relate',
  //
  'admin.posts.add-new.postbox.trademarkdiv.title': 'Trademark',
  //
  'admin.posts.add-new.postbox.trademark.title': 'Trademark',
  //
  'admin.posts.add-new.postbox.seodiv.title': 'seo',
  'admin.posts.add-new.postbox.seodiv.label.title': 'SEO title',
  'admin.posts.add-new.postbox.seodiv.label.image': 'SEO image',
  'admin.posts.add-new.postbox.seodiv.btn.upload-image': 'Download Image',
  'admin.posts.add-new.postbox.seodiv.btn.remove-image': 'Delete photos',
  'admin.posts.add-new.postbox.seodiv.label.description': 'SEO description',
  'admin.posts.add-new.postbox.seodiv.label.keyword': 'SEO keywords',
  'admin.posts.add-new.postbox.seodiv.keyword.description': 'Press the "Enter" key to add keywords.',
  //
  'admin.posts.add-new.postbox.productdiv.title': 'Product information',
  'admin.posts.add-new.postbox.coursechapterdiv.title': 'Chapter information',
  'admin.posts.add-new.postbox.coursediv.title': 'Course information',
  'admin.posts.add-new.postbox.coursechapterlistdiv.title': 'List chapters',
  'admin.posts.add-new.postbox.coursediv.chapter.title': 'Chapter information',
  'admin.posts.add-new.postbox.productdiv.label.code': 'Product code',
  'admin.posts.add-new.postbox.productdiv.error-msg.code': 'Maximum 30 characters!',
  'admin.posts.add-new.postbox.productdiv.label.price': 'Product price',
  'admin.posts.add-new.postbox.productdiv.error-msg.price': 'Product price must be equal or greater than 0!',
  'admin.posts.add-new.postbox.productdiv.label.discount_id': 'Discount code',
  'admin.posts.add-new.postbox.productdiv.label.prices': 'Product price by Agency',
  'admin.posts.add-new.postbox.productdiv.dropdown.discount.empty': '—— Drum ——',
  //
  'admin.posts.add-new.postbox.coursediv.label.lesson': 'Lesson',
  'admin.posts.add-new.postbox.coursediv.label.duration': 'Duration course(minutes)',
  'admin.posts.add-new.postbox.coursediv.label.student': 'Number student of course',
  'admin.posts.add-new.postbox.coursediv.label.price': 'Price course',
  'admin.posts.add-new.postbox.coursediv.error-msg.price': 'Course price must be equal or greater than 0!',
  'admin.posts.add-new.postbox.coursediv.label.discount_id': 'Discount course',
  //
  'admin.posts.add-new.noti.create-post.success': 'Add successful posts!',
  'admin.posts.add-new.noti.create-post.failure': 'Add unsuccessful articles!Please try again later.',
  'admin.posts.add-new.noti.update-post.success': 'Update the article successfully!',
  'admin.posts.add-new.noti.update-post.failure': 'Update the article unsuccessful!Please try again later.',
  'admin.posts.add-new.noti.delete-post.success': 'Delete the article successfully!',
  'admin.posts.add-new.noti.delete-post.failure': 'Delete the article unsuccessful!Please try again later.',
  // lesson
  'admin.posts.add-new.postbox.coursediv.input.duplicate.error': 'This lesson is already exist',
  'admin.posts.add-new.postbox.coursediv.label.lesson.list': 'List lesson',
  'admin.posts.add-new.postbox.coursediv.label.chapter.list': 'List chapter',
  'admin.posts.add-new.postbox.coursediv.label.chapter.add': 'Add lesson',
  'admin.posts.add-new.postbox.coursediv.label.chapter.lesson.title': 'Lesson title',
  'admin.posts.add-new.postbox.coursediv.label.chapter.lesson.link': 'Lesson link',
  'admin.posts.add-new.postbox.coursediv.label.chapter.lesson.duration': 'Lesson duration',
  'admin.posts.add-new.postbox.coursediv.label.chapter.lesson.highlight': 'Highlight',
  'admin.posts.add-new.postbox.coursediv.label.chapter.lesson.description': 'Description',
};

const postCategory = {
  'admin.posts.post-category.hetmet-1': 'Post Category',
  'admin.posts.post-category.hetmet-2': 'Post',
  'admin.posts.post-category.hetmet-product-1': 'Product Category',
  'admin.posts.post-category.hetmet-product-2': 'Product',
  'admin.posts.post-category.col-left.title': 'Add a new list of articles',
  'admin.posts.post-category.col-left.title-product': 'Add a new product category',
  'admin.posts.post-category.col-left.title-1': 'Add a new $x',
  'admin.posts.post-category.noti.create-post-category.title.success': 'Add a list of successful articles!',
  'admin.posts.post-category.noti.create-post-category.title.success-product': 'Add a successful product category!',
  'admin.posts.post-category.noti.create-post-category.title.success-1': 'Add a $x successfully!',
  'admin.posts.post-category.noti.create-post-category.title.failure': 'Add a list of unsuccessful articles! Please try again later.',
  'admin.posts.post-category.noti.create-post-category.title.failure-product': 'Add the product category unsuccessful! Please try again later.',
  'admin.posts.post-category.noti.create-post-category.title.failure-1': 'Add a $x unsuccessfully! Please try again later.',
  'admin.posts.post-category.noti.update-post-category.title.success': 'Update the list of successful articles!',
  'admin.posts.post-category.noti.update-post-category.title.success-product': 'Update successful product portfolio!',
  'admin.posts.post-category.noti.update-post-category.title.success-1': 'Update $x successfully!',
  'admin.posts.post-category.noti.update-post-category.title.failure': 'Update the list of unsuccessful articles! Please try again later.',
  'admin.posts.post-category.noti.update-post-category.title.failure-product': 'Update product category unsuccessful! Please try again later.',
  'admin.posts.post-category.noti.update-post-category.title.failure-1': 'Update $x unsuccessfully! Please try again later.',
  'admin.posts.post-category.noti.delete-post-category.title.success': 'Delete the list of successful articles!',
  'admin.posts.post-category.noti.delete-post-category.title.success-product': 'Delete a successful product category!',
  'admin.posts.post-category.noti.delete-post-category.title.success-1': 'Delete $x successfully!',
  'admin.posts.post-category.noti.delete-post-category.title.failure': 'Delete the list of articles unsuccessful! Please try again later.',
  'admin.posts.post-category.noti.delete-post-category.title.failure-product': 'Delete the product category failed! Please try again later.',
  'admin.posts.post-category.noti.delete-post-category.title.failure-1': 'Delete $x unsuccessfully! Please try again later.',
  'admin.posts.post-category.col-left.label.title': 'Title',
  'admin.posts.post-category.col-left.label.slug': 'Slug',
  'admin.posts.post-category.col-left.label.image': 'Image',
  'admin.posts.post-category.col-left.label.description': 'Describe',
  'admin.posts.post-category.col-left.label.parent_id': 'Category Father',
  'admin.posts.post-category.col-left.label.layout': 'Show by',
  'admin.posts.post-category.col-left.label.number': 'The article displays',
  'admin.posts.post-category.col-left.label.number-product': 'Product display',
  'admin.posts.post-category.col-left.label.group': 'Applicable to data groups',
  'admin.posts.post-category.col-left.label.group-1': 'Applicable to $x',
  'admin.posts.post-category.col-left.label.sort': 'Order',
  'admin.posts.post-category.col-left.label.show-by-cat_id': 'Show by Category',
  'admin.posts.post-category.col-left.label.show-by-cat_id-1': 'Show by $x',
  'admin.posts.post-category.col-left.description.slug': '"Slug" is the friendly path of the name.It usually only includes normal writing characters, numbers and dashes, not using Vietnamese.',
  'admin.posts.post-category.col-left.description.parent_id': 'Category is different from the card, you can use many categories.For example: In the music category, you have a sub -category of pop music, jazz.This is completely up to you.',
  'admin.posts.post-category.col-left.btn.upload-image': 'Upload Image',
  'admin.posts.post-category.col-left.btn.remove-image': 'Remove Image',
  'admin.posts.post-category.modal.update.title': 'Update the list of articles',
  'admin.posts.post-category.modal.update.title-product': 'Update product category',
  'admin.posts.post-category.modal.update.title-1': 'Update $x',
  'admin.posts.post-category.modal.details.title': 'Details of the list of articles',
  'admin.posts.post-category.modal.details.title-product': 'Detailed product category',
  'admin.posts.post-category.modal.details.title-1': 'Details of $x',
  'admin.posts.post-category.category-parent-empty.label': 'Drum',
  'admin.posts.post-category.dropdown.article': 'Posts',
  'admin.posts.post-category.dropdown.category': 'Category',
  'admin.posts.post-category.modal.delete.title': 'Confirm the delete of article categories!',
  'admin.posts.post-category.modal.delete.title-product': 'Confirm the delete product category!',
  'admin.posts.post-category.modal.delete.title-1': 'Confirm the delete of $x!',
  'admin.posts.post-category.modal.delete.description': 'You definitely want to delete this post category?',
  'admin.posts.post-category.modal.delete.description-product': 'You definitely want to delete this product category?',
  'admin.posts.post-category.modal.delete.description-1': 'You definitely want to delete this $x?',
};

const dataGroup = {
  'admin.posts.data-group.hetmet': 'Data Group - Posts',
  'admin.posts.data-group.breadcrumb.heading': 'Data Group',
  'admin.posts.data-group.breadcrumb.parent': 'Posts',
  'admin.posts.data-group.col-left.title': 'Add new data groups',
  'admin.posts.data-group.col-left.title-1': 'Add new $x',
  'admin.posts.data-group.noti.create-data-group.title.success': 'Add successful data group!',
  'admin.posts.data-group.noti.create-data-group.title.success-1': 'Add $x successfully!',
  'admin.posts.data-group.noti.create-data-group.title.failure': 'Add unsuccessful data group!Please try again later.',
  'admin.posts.data-group.noti.create-data-group.title.failure-1': 'Add $x unsuccessfully! Please try again later.',
  'admin.posts.data-group.noti.save-data-group.title.success': 'Update the data group successfully!',
  'admin.posts.data-group.noti.save-data-group.title.success-1': 'Update $x successfully!',
  'admin.posts.data-group.noti.save-data-group.title.failure': 'Update the data group unsuccessful!Please try again later.',
  'admin.posts.data-group.noti.save-data-group.title.failure-1': 'Update $x unsuccessfully! Please try again later.',
  'admin.posts.data-group.noti.delete-data-group.title.success': 'Delete the data group successfully!',
  'admin.posts.data-group.noti.delete-data-group.title.success-1': 'Delete $x successfully!',
  'admin.posts.data-group.noti.delete-data-group.title.failure': 'Delete the data group failed!Please try again later.',
  'admin.posts.data-group.noti.delete-data-group.title.failure-1': 'Delete $x unsuccessfully! Please try again later.',
  'admin.posts.data-group.error-msg-input.type-id-require': 'Please choose the type of article!',
  'admin.posts.data-group.column.type-id': 'Type of article',
  'admin.posts.data-group.col-left.label.type_id': 'Type of article',
  'admin.posts.data-group.col-left.label.title': 'Name',
  'admin.posts.data-group.col-left.label.slug': 'Slug',
  'admin.posts.data-group.col-left.label.image': 'Image',
  'admin.posts.data-group.col-left.label.description': 'Describe',
  'admin.posts.data-group.col-left.btn.upload-image': 'Upload Image',
  'admin.posts.data-group.col-left.btn.remove-image': 'Delete photos',
  'admin.posts.data-group.modal.details.title': 'Data group details',
  'admin.posts.data-group.modal.details.title-1': '$x details',
  'admin.posts.data-group.modal.update.title': 'Update data group',
  'admin.posts.data-group.modal.update.title-1': 'Update $x',
  'admin.posts.data-group.modal.delete.title': 'Confirm the data group deletion!',
  'admin.posts.data-group.modal.delete.title-1': 'Confirm $x deletion!',
  'admin.posts.data-group.modal.delete.description': 'You definitely want to delete this data group?',
  'admin.posts.data-group.modal.delete.description-1': 'You definitely want to delete this $x?',
};

const dataField = {
  'admin.posts.data-field.hetmet': 'Data field - article',
  'admin.posts.data-field.breadcrumb.heading': 'Data fields',
  'admin.posts.data-field.breadcrumb.parent': 'Posts',
  'admin.posts.data-field.col-left.title': 'Add new data fields',
  'admin.posts.data-field.noti.create-data-field.title.success': 'Add successful data field!',
  'admin.posts.data-field.noti.create-data-field.title.failure': 'Add unsuccessful data fields!Please try again later.',
  'admin.posts.data-field.noti.save-data-field.title.success': 'Update the field of data successfully!',
  'admin.posts.data-field.noti.save-data-field.title.failure': 'Data field update failed!Please try again later.',
  'admin.posts.data-field.noti.delete-data-field.title.success': 'Delete the field successfully!',
  'admin.posts.data-field.noti.delete-data-field.title.failure': 'Delete the data field failed!Please try again later.',
  'admin.posts.data-field.error-msg-input.data-style-require': 'Please add at least 1 data!',
  'admin.posts.data-field.column.type-id': 'Type of article',
  'admin.posts.data-field.col-left.label.key': 'Key',
  'admin.posts.data-field.col-left.label.title': 'Title',
  'admin.posts.path-field.col-left.label.title': 'Path',
  'admin.posts.data-field.col-left.label.style': 'Datatypes',
  'admin.posts.data-field.col-left.label.data': 'Data list',
  'admin.posts.data-field.col-left.btn.upload-image': 'Upload Image',
  'admin.posts.data-field.col-left.btn.remove-image': 'Delete photos',
  'admin.posts.data-field.modal.details.title': 'Data field details',
  'admin.posts.data-field.modal.update.title': 'Data field update',
  'admin.posts.data-field.modal.delete.title': 'Confirmation of data deletion!',
  'admin.posts.data-field.modal.delete.description': 'You definitely want to delete this data field?',
  'admin.posts.data-field.style-list.text': '1 line chain (text)',
  'admin.posts.data-field.style-list.number': 'Integer (number)',
  'admin.posts.data-field.style-list.date': 'Datepicker (date)',
  'admin.posts.data-field.style-list.textarea': 'Chain (textarea)',
  'admin.posts.data-field.style-list.editor': 'Editor (editor)',
  'admin.posts.data-field.style-list.selectbox': 'A drop-down option (select)',
  'admin.posts.data-field.style-list.radiobox': 'An option (radio)',
  'admin.posts.data-field.style-list.checkbox': 'Many choices (checkbox)',
  'admin.posts.data-field.style-list.media': 'Select media (media)',
  'admin.posts.data-field.style-list.api': 'A api (api)',
  'admin.posts.data-field.col-left.label.script': 'Script',
};

const productDiscount = {
  'admin.posts.product-discount.hetmet': 'Product - Discount',
  'admin.posts.product-discount.breadcrumb.heading': 'Discount',
  'admin.posts.product-discount.breadcrumb.parent': 'Product',
  'admin.posts.product-discount.col-left.title': 'Add new discount',
  'admin.posts.product-discount.col-left.title-1': 'Add new $x',
  'admin.posts.product-discount.col-left.label.title': 'Title',
  'admin.posts.product-discount.col-left.label.start': 'Start time',
  'admin.posts.product-discount.col-left.error-msg.start': 'Please choose the starting time after the present time!',
  'admin.posts.product-discount.col-left.label.end': 'End time',
  'admin.posts.product-discount.col-left.error-msg.end': 'Please choose the end time after the beginning!',
  'admin.posts.product-discount.col-left.label.config': 'Detail',
  'admin.posts.product-discount.col-left.error-msg.config': 'Please adjust the number of different numbers!',
  'admin.posts.product-discount.col-left.label.config.number': 'Quantity',
  'admin.posts.product-discount.col-left.label.config.discount': 'Discount',
  'admin.posts.product-discount.col-left.label.config.style': 'Type',
  'admin.posts.product-discount.dropdown.percent': 'Percent (%)',
  'admin.posts.product-discount.dropdown.money': 'Currency',
  'admin.posts.product-discount.col.status.disable': 'Disabled',
  'admin.posts.product-discount.col.status.enable': 'Enable',
  'admin.posts.product-discount.noti.create-product-discount.title.success': 'Add a new discount successfully!',
  'admin.posts.product-discount.noti.create-product-discount.title.success-1': 'Add a new $x successfully!',
  'admin.posts.product-discount.noti.create-product-discount.title.failure': 'More new discounts are unsuccessful! Please try again later.',
  'admin.posts.product-discount.noti.create-product-discount.title.failure-1': 'More new $x are unsuccessful! Please try again later.',
  'admin.posts.product-discount.noti.update-product-discount.title.success': 'Update successful discount!',
  'admin.posts.product-discount.noti.update-product-discount.title.success-1': 'Update successful $x!',
  'admin.posts.product-discount.noti.update-product-discount.title.failure': 'Discount update unsuccessful! Please try again later.',
  'admin.posts.product-discount.noti.update-product-discount.title.failure-1': '$x update unsuccessful! Please try again later.',
  'admin.posts.product-discount.noti.delete-product-discount.title.success': 'Delete success price!',
  'admin.posts.product-discount.noti.delete-product-discount.title.success-1': 'Delete $x!',
  'admin.posts.product-discount.noti.delete-product-discount.title.failure': 'Delete unsuccessful price! Please try again later.',
  'admin.posts.product-discount.noti.delete-product-discount.title.failure-1': 'Delete unsuccessful $x! Please try again later.',
  'admin.posts.product-discount.modal.delete.title': 'Confirmation of discounting!',
  'admin.posts.product-discount.modal.delete.title-1': 'Confirmation of $x!',
  'admin.posts.product-discount.modal.delete.description': 'Are you sure to delete this price?',
  'admin.posts.product-discount.modal.delete.description-1': 'Are you sure to delete this $x?',
  'admin.posts.product-discount.modal.details.title': 'Discount details!',
  'admin.posts.product-discount.modal.details.title-1': '$x details!',
  'admin.posts.product-discount.modal.update.title': 'Update discount!',
  'admin.posts.product-discount.modal.update.title-1': 'Update $x!',
};

const articleGroup = {
  'admin.posts.article-group.hetmet-1': 'Post Group',
  'admin.posts.article-group.hetmet-2': 'Article',
  'admin.posts.article-group.hetmet-product-1': 'Product Group',
  'admin.posts.article-group.hetmet-product-2': 'Product',
  'admin.posts.article-group.breadcrumb.heading': 'Post Group',
  'admin.posts.article-group.breadcrumb.heading-product': 'Product Group',
  'admin.posts.article-group.breadcrumb.parent': 'Article',
  'admin.posts.article-group.breadcrumb.parent-product': 'Product',
  'admin.posts.article-group.col-left.title': 'Add new groups of articles',
  'admin.posts.article-group.col-left.title-product': 'Add new product groups',
  'admin.posts.article-group.col-left.title-1': 'Add new $x',
  'admin.posts.article-group.noti.create-article-group.title.success': 'Add a group of successful articles!',
  'admin.posts.article-group.noti.create-article-group.title.success-product': 'Add successful product group!',
  'admin.posts.article-group.noti.create-article-group.title.success-1': 'Add $x successfully!',
  'admin.posts.article-group.noti.create-article-group.title.failure': 'Add a group of unsuccessful articles! Please try again later.',
  'admin.posts.article-group.noti.create-article-group.title.failure-product': 'Adding unsuccessful product groups! Please try again later.',
  'admin.posts.article-group.noti.create-article-group.title.failure-1': 'Add $x unsuccessfully! Please try again later.',
  'admin.posts.article-group.noti.save-article-group.title.success': 'Update the group of successful posts!',
  'admin.posts.article-group.noti.save-article-group.title.success-product': 'Update successful product group!',
  'admin.posts.article-group.noti.save-article-group.title.success-1': 'Update $x successfully!',
  'admin.posts.article-group.noti.save-article-group.title.failure': 'Update the group of articles unsuccessful! Please try again later.',
  'admin.posts.article-group.noti.save-article-group.title.failure-product': 'Update the product group unsuccessful! Please try again later.',
  'admin.posts.article-group.noti.save-article-group.title.failure-1': 'Update $x unsuccessfully! Please try again later.',
  'admin.posts.article-group.noti.delete-article-group.title.success': 'Delete the group of successful articles!',
  'admin.posts.article-group.noti.delete-article-group.title.success-product': 'Delete a successful product group!',
  'admin.posts.article-group.noti.delete-article-group.title.success-1': 'Delete $x successfully!',
  'admin.posts.article-group.noti.delete-article-group.title.failure': 'Delete the group of articles failed! Please try again later.',
  'admin.posts.article-group.noti.delete-article-group.title.failure-product': 'Deleting the product group failed! Please try again later.',
  'admin.posts.article-group.noti.delete-article-group.title.failure-1': 'Delete $x unsuccessfully! Please try again later.',
  'admin.posts.article-group.col-left.label.title': 'Name',
  'admin.posts.article-group.col-left.label.slug': 'Slug',
  'admin.posts.article-group.col-left.label.image': 'Image',
  'admin.posts.article-group.col-left.label.description': 'Describe',
  'admin.posts.article-group.col-left.btn.upload-image': 'Upload Image',
  'admin.posts.article-group.col-left.btn.remove-image': 'Remove Image',
  'admin.posts.article-group.modal.details.title': 'Post group details',
  'admin.posts.article-group.modal.details.title-product': 'Product group details',
  'admin.posts.article-group.modal.details.title-1': '$x details',
  'admin.posts.article-group.modal.update.title': 'Update the article group',
  'admin.posts.article-group.modal.update.title-product': 'Update product group',
  'admin.posts.article-group.modal.update.title-1': 'Update $x',
  'admin.posts.article-group.modal.delete.title': 'Confirm the deletion of the article!',
  'admin.posts.article-group.modal.delete.title-product': 'Confirm the product group deletion!',
  'admin.posts.article-group.modal.delete.title-1': 'Confirm the deletion of $x!',
  'admin.posts.article-group.modal.delete.description': 'You definitely want to delete this group of this article?',
  'admin.posts.article-group.modal.delete.description-product': 'You definitely want to delete this product group?',
  'admin.posts.article-group.modal.delete.description-1': 'You definitely want to delete this $x?',
};

const productOrder = {
  'admin.posts.product-order.hetmet': 'Orders - Products',
  'admin.posts.product-order.breadcrumb.heading': 'Order',
  'admin.posts.product-order.breadcrumb.parent': 'Product',
  'admin.posts.product-order.btn.add': 'Add new orders',
  'admin.posts.product-order.yup.error-msg.name': 'Please enter the name!',
  'admin.posts.product-order.yup.error-msg.phone': 'Please enter the phone number!',
  'admin.posts.product-order.yup.error-msg.address': 'Please enter your address!',
  'admin.posts.product-order.yup.error-msg.products': 'Please choose at least 1 product!',
  'admin.posts.product-order.modal.title.create': 'Add new orders',
  'admin.posts.product-order.modal.title.create-1': 'Add new $x',
  'admin.posts.product-order.modal.title.update': 'Update order',
  'admin.posts.product-order.modal.title.update-1': 'Update $x',
  'admin.posts.product-order.modal.title.details': 'Order details',
  'admin.posts.product-order.modal.title.details-1': '$x details',
  'admin.posts.product-order.modal.title.update-status-order': 'Order coordination',
  'admin.posts.product-order.modal.title.update-status-order-1': '$x coordination',
  'admin.posts.product-order.modal.title.cancel-order': 'Cancel order',
  'admin.posts.product-order.modal.title.cancel-order-1': 'Cancel $x',
  'admin.posts.product-order.modal.label.name': 'Full name',
  'admin.posts.product-order.modal.label.phone': 'Phone number receiving goods',
  'admin.posts.product-order.modal.label.email': 'Email address',
  'admin.posts.product-order.modal.label.status': 'Order status',
  'admin.posts.product-order.modal.label.status-1': '$x status',
  'admin.posts.product-order.modal.label.address': 'Address to receive goods',
  'admin.posts.product-order.modal.label.note': 'Note',
  'admin.posts.product-order.modal.label.products': 'List of products',
  'admin.posts.product-order.modal.btn.create-order': 'Add orders',
  'admin.posts.product-order.modal.btn.update-order': 'Update order',
  'admin.posts.product-order.modal.column.code': 'Product code',
  'admin.posts.product-order.modal.column.image': 'Image',
  'admin.posts.product-order.modal.column.title': 'Product name',
  'admin.posts.product-order.modal.column.number': 'Quantity',
  'admin.posts.product-order.modal.error-msg.number': 'Please enter the number of products greater than 0!',
  'admin.posts.product-order.modal.update-status-order.btn-next': 'Continue',
  'admin.posts.product-order.modal.update-status-order.step1.title': 'Order status:',
  'admin.posts.product-order.modal.update-status-order.step1.title-1': '$x status:',
  'admin.posts.product-order.modal.update-status-order.loading-title': 'Performing orders!',
  'admin.posts.product-order.modal.update-status-order.loading-title-1': 'Performing $x!',
  'admin.posts.product-order.modal.cancel-order.loading-title': 'Canceling orders!',
  'admin.posts.product-order.modal.cancel-order.loading-title-1': 'Canceling $x!',
  'admin.posts.product-order.modal.actions.btn-update-status-order': 'Order coordination',
  'admin.posts.product-order.modal.actions.btn-update-status-order-1': '$x coordination',
  'admin.posts.product-order.modal.actions.btn-cancel-order': 'Cancel order',
  'admin.posts.product-order.modal.actions.btn-cancel-order-1': 'Cancel $x',
  'admin.posts.product-order.column.name': 'Name',
  'admin.posts.product-order.column.phone': 'Phone number',
  'admin.posts.product-order.column.address': 'Address',
  'admin.posts.product-order.column.note': 'Note',
  'admin.posts.product-order.column.fee': 'Total',
  'admin.posts.product-order.column.status': 'Status',
  'admin.posts.product-order.order.status.new': 'New',
  'admin.posts.product-order.order.status.processing': 'Processing',
  'admin.posts.product-order.order.status.delivery': 'Being transported',
  'admin.posts.product-order.order.status.delivered': 'Successful shipping',
  'admin.posts.product-order.order.status.canceled': 'Cancel',
  'admin.posts.product-order.product-list-empty': 'Drum list',
  'admin.posts.product-order.noti.create-order.success': 'Add successful orders!',
  'admin.posts.product-order.noti.create-order.success-1': 'Add successful $x!',
  'admin.posts.product-order.noti.create-order.failure': 'More failed orders! Please try again later.',
  'admin.posts.product-order.noti.create-order.failure-1': 'More failed $x! Please try again later.',
  'admin.posts.product-order.noti.update-order.success': 'Update order successfully!',
  'admin.posts.product-order.noti.update-order.success-1': 'Update $x successfully!',
  'admin.posts.product-order.noti.update-order.failure': 'Update failed order! Please try again later.',
  'admin.posts.product-order.noti.update-order.failure-1': 'Update failed $x! Please try again later.',
  'admin.posts.product-order.noti.delete-order.success': 'Delete orders successfully!',
  'admin.posts.product-order.noti.delete-order.success-1': 'Delete $x successfully!',
  'admin.posts.product-order.noti.delete-order.failure': 'Delete orders failed! Please try again later.',
  'admin.posts.product-order.noti.delete-order.failure-1': 'Delete $x failed! Please try again later.',
  'admin.posts.product-order.noti.update-status-order.success': 'Successful order coordination!',
  'admin.posts.product-order.noti.update-status-order.success-1': 'Successful $x coordination!',
  'admin.posts.product-order.noti.update-status-order.failure': 'Missing orders failed! Please try again later.',
  'admin.posts.product-order.noti.update-status-order.failure-1': 'Missing $ failed! Please try again later.',
  'admin.posts.product-order.noti.cancel-order.success': 'Cancel the order successfully!',
  'admin.posts.product-order.noti.cancel-order.success-1': 'Cancel the $x successfully!',
  'admin.posts.product-order.noti.cancel-order.failure': 'Cancel failure! Please try again later.',
  'admin.posts.product-order.noti.cancel-order.failure-1': 'Cancel failure! Please try again later.',
};

const productTrademark = {
  'admin.product.trademark.hetmet': 'Product brands',
  'admin.product.trademark.col-left.title': 'Add brand',
  'admin.product.trademark.col-left.title-1': 'Add $x',
  'admin.product.trademark.noti.create-product-trademark.title.success': 'Add a new brand successful!',
  'admin.product.trademark.noti.create-product-trademark.title.success-1': 'Add $x successful!',
  'admin.product.trademark.noti.create-product-trademark.title.failure': 'Adding new brands failed! Please try again later.',
  'admin.product.trademark.noti.create-product-trademark.title.failure-1': 'Adding $x failed! Please try again later.',
  'admin.product.trademark.noti.update-product-trademark.title.success': 'Successful brand update!',
  'admin.product.trademark.noti.update-product-trademark.title.success-1': 'Successful $x update!',
  'admin.product.trademark.noti.update-product-trademark.title.failure': 'Brand update failed! Please try again later.',
  'admin.product.trademark.noti.update-product-trademark.title.failure-1': '$x update failed! Please try again later.',
  'admin.product.trademark.noti.delete-product-trademark.title.success': 'Delete the brand successfully!',
  'admin.product.trademark.noti.delete-product-trademark.title.success-1': 'Delete the $x successfully!',
  'admin.product.trademark.noti.delete-product-trademark.title.failure': 'Delete brand failure! Please try again later.',
  'admin.product.trademark.noti.delete-product-trademark.title.failure-1': 'Delete $x failure! Please try again later.',
  'admin.product.trademark.col-left.label.title': 'Name',
  'admin.product.trademark.col-left.label.slug': 'Slug',
  'admin.product.trademark.col-left.label.image': 'Image',
  'admin.product.trademark.col-left.btn.upload-image': 'Add Image',
  'admin.product.trademark.col-left.btn.remove-image': 'Remove Image',
  'admin.product.trademark.col-left.label.description': 'Describe',
  'admin.product.trademark.column.title': 'Brand name',
  'admin.product.trademark.column.title-1': "$x's name",
  'admin.product.trademark.column.slug': 'Slug',
  'admin.product.trademark.column.image': 'Image',
  'admin.product.trademark.modal.details.title': 'Brand information details',
  'admin.product.trademark.modal.update.title': 'Brand update',
  'admin.product.trademark.modal.delete.title': 'Confirm brand deletion!',
  'admin.product.trademark.modal.delete.description': 'Are you sure you want to delete this brand?',
};

const usAdminPostsLangLib = {
  ...posts,
  ...allPosts,
  ...editPost,
  ...addNewPost,
  ...postCategory,
  ...dataGroup,
  ...dataField,
  ...productDiscount,
  ...articleGroup,
  ...productOrder,
  ...productTrademark,
};

export default usAdminPostsLangLib;
