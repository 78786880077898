/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllRoles({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/roles?${payload.params}` : '/roles?limit=0',
    ));
    yield put(Actions.getAllRolesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllRolesFailure(messages));
    }
  }
}

function* createRole({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/roles', payload));
    yield put(Actions.createRoleSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createRoleFailure(messages));
    }
  }
}

function* deleteRole({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/roles/${payload}`));
    yield put(Actions.deleteRoleSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteRoleFailure(messages));
    }
  }
}

function* getAllPermissions({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/permissions?${payload.params}` : '/permissions?limit=0',
    ));
    yield put(Actions.getAllPermissionsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllPermissionsFailure(messages));
    }
  }
}

function* attachPermissionsToRole({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/permissions/sync', payload));
    yield put(Actions.attachPermissionsToRoleSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.attachPermissionsToRoleFailure(messages));
    }
  }
}

function* editPermissionsToRole({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/permissions/sync', payload));
    yield put(Actions.editPermissionsToRoleSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.editPermissionsToRoleFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllRolesRequest, getAllRoles);
  yield takeLatest(Actions.createRoleRequest, createRole);
  yield takeLatest(Actions.deleteRoleRequest, deleteRole);
  yield takeLatest(Actions.getAllPermissionsRequest, getAllPermissions);
  yield takeLatest(Actions.attachPermissionsToRoleRequest, attachPermissionsToRole);
  yield takeLatest(Actions.editPermissionsToRoleRequest, editPermissionsToRole);
}
