const posts = {
};

const allPosts = {
  'admin.posts.all-posts.hetmet.product-1': 'Tất cả sản phẩm',
  'admin.posts.all-posts.hetmet.product-2': 'Sản phẩm',
  'admin.posts.all-posts.hetmet.article-1': 'Tất cả bài viết',
  'admin.posts.all-posts.hetmet.article-2': 'Bài viết',
  'admin.posts.all-posts.hetmet.course-1': 'Tất cả khóa học',
  'admin.posts.all-posts.hetmet.course-2': 'Khóa học',
  'admin.posts.all-posts.breadcrumb.heading.product': 'Sản phẩm',
  'admin.posts.all-posts.breadcrumb.heading.course': 'Khóa học',
  'admin.posts.all-posts.breadcrumb.heading.article': 'Bài viết',
  'admin.posts.all-posts.breadcrumb.parent': 'Tất cả bài viết - Bài viết',
  'admin.posts.all-posts.btn.add-new.product': 'Sản phẩm mới',
  'admin.posts.all-posts.btn.add-new.course': 'Khóa học mới',
  'admin.posts.all-posts.btn.add-new.article': 'Viết bài mới',
  'admin.posts.all-posts.column.title': 'Tiêu đề',
  'admin.posts.all-posts.column.author': 'Tác giả',
  'admin.posts.all-posts.column.image': 'Hình ảnh',
  'admin.posts.all-posts.column.cat-primary': 'Danh mục chính',
  'admin.posts.all-posts.column.cat-primary.empty': '(Trống)',
  'admin.posts.all-posts.column.time': 'Thời gian',
  'admin.posts.all-posts.column.time.created-title': 'Cập nhật gần nhất',
  'admin.posts.all-posts.column.time.updated-title': 'Đã xuất bản',
  'admin.posts.all-posts.column.slug': 'Đường dẫn',
  'admin.posts.all-posts.modal.delete.title': 'Xác nhận xóa $x!',
  'admin.posts.all-posts.modal.delete.title-product': 'Xác nhận xóa sản phẩm!',
  'admin.posts.all-posts.modal.delete.title-article': 'Xác nhận xóa bài viết!',
  'admin.posts.all-posts.modal.delete.title-course': 'Xác nhận xóa khoá học!',
  'admin.posts.all-posts.modal.delete.title-order': 'Xác nhận xóa đơn hàng!',
  'admin.posts.all-posts.modal.delete.description': 'Bài chắc chắn muốn xóa $x này? Thao tác của bạn sẽ không hoàn tác lại được.',
  'admin.posts.all-posts.modal.delete.description-product': 'Bài chắc chắn muốn xóa sản phẩm này? Thao tác của bạn sẽ không hoàn tác lại được.',
  'admin.posts.all-posts.modal.delete.description-article': 'Bài chắc chắn muốn xóa bài viết này? Thao tác của bạn sẽ không hoàn tác lại được.',
  'admin.posts.all-posts.modal.delete.description-course': 'Bài chắc chắn muốn xóa khoá học này? Thao tác của bạn sẽ không hoàn tác lại được.',
  'admin.posts.all-posts.modal.delete.description-order': 'Bài chắc chắn muốn xóa đơn hàng này? Thao tác của bạn sẽ không hoàn tác lại được.',
  'admin.posts.all-posts.noti.delete.success': 'Xóa $x thành công!',
  'admin.posts.all-posts.noti.delete.success-product': 'Xóa sản phẩm thành công!',
  'admin.posts.all-posts.noti.delete.success-article': 'Xóa bài viết thành công!',
  'admin.posts.all-posts.noti.delete.success-course': 'Xóa khoá học thành công!',
  'admin.posts.all-posts.noti.delete.failure': 'Xóa $x thất bại! Vui lòng thử lại sau.',
  'admin.posts.all-posts.noti.delete.failure-product': 'Xóa sản phẩm thất bại! Vui lòng thử lại sau.',
  'admin.posts.all-posts.noti.delete.failure-article': 'Xóa bài biết thất bại! Vui lòng thử lại sau.',
  'admin.posts.all-posts.noti.delete.failure-course': 'Xóa khoá học thất bại! Vui lòng thử lại sau.',
};

const editPost = {
  'admin.posts.edit-new.hetmet.product': 'Chỉnh sửa sản phẩm - Sản phẩm',
  'admin.posts.edit-new.hetmet.course': 'Chỉnh sửa khóa học - Khóa học',
  'admin.posts.edit-new.hetmet.article': 'Chỉnh sửa bài viết - Bài viết',
  'admin.posts.edit-new.breadcrumb.heading.product': 'Chỉnh sửa',
  'admin.posts.edit-new.breadcrumb.heading.course': 'Chỉnh sửa',
  'admin.posts.edit-new.breadcrumb.heading.article': 'Chỉnh sửa',
  'admin.posts.edit-new.postbox.submitdiv.btn-update': 'Cập nhật',
  'admin.posts.edit-new.postbox.submitdiv.link-delete': 'Xóa bài viết',
  'admin.posts.edit-new.postbox.submitdiv.value.post-status.publish': 'Đã xuất bản',
  'admin.posts.edit-new.postbox.submitdiv.label.post-curtime-create': 'Đã đăng lúc:',
  'admin.posts.edit-new.postbox.submitdiv.label.post-curtime-update': 'Cập nhật gần nhất:',
  'admin.posts.edit-new.noti.update-post.success': 'Cập nhật bài viết thành công!',
  'admin.posts.edit-new.noti.update-post.failure': 'Cập nhật bài viết thất bại! Vui lòng thử lại sau.',
};

const addNewPost = {
  'admin.posts.add-new.hetmet.product': 'Sản phẩm mới - Sản phẩm',
  'admin.posts.add-new.hetmet.course': 'Khóa học mới - Khóa học',
  'admin.posts.add-new.hetmet.article': 'Viết bài mới - Bài viết',
  'admin.posts.add-new.breadcrumb.heading.product': 'Thêm mới',
  'admin.posts.add-new.breadcrumb.heading.course': 'Thêm mới',
  'admin.posts.add-new.breadcrumb.heading.article': 'Thêm mới',
  'admin.posts.add-new.breadcrumb.parent': 'Bài viết',
  'admin.posts.add-new.rendering-title': 'Đang khởi tạo trình thêm bài viết!',
  'admin.posts.add-new.render-failure-title': 'Khởi tạo trình thêm bài viết thất bại! Vui lòng thử lại sau.',
  'admin.posts.add-new.section-settings.toggle-title': 'Tùy chọn hiển thị',
  'admin.posts.add-new.section-settings.view-mode.label': 'Chế độ xem',
  'admin.posts.add-new.section-settings.view-mode.description-1': 'Các thành phần của bài viết có ký hiệu',
  'admin.posts.add-new.section-settings.view-mode.description-2': 'có thể di chuyển bằng cách giữ chuột và kéo thả.',
  'admin.posts.add-new.section-settings.view-mode.radio.static-title': 'Chế độ xem tĩnh',
  'admin.posts.add-new.section-settings.view-mode.radio.dynamic-title': 'Chế độ xem động',
  'admin.posts.add-new.section-settings.column.label': 'Thành phần của bài viết',
  'admin.posts.add-new.section-settings.column.description-1': 'Một số thành phần của bài viết có thể hiển thị hoặc ẩn bằng cách nhấn hoặc bỏ chọn. Mở rộng hoặc thu gọn các thành phần bằng cách bấm vào biểu tượng',
  'admin.posts.add-new.section-settings.column.description-2': 'trên thanh tiêu đề.',
  //
  'admin.posts.add-new.postbox.link-edit': 'Chỉnh sửa',
  'admin.posts.add-new.postbox.link-cancel': 'Hủy',
  'admin.posts.add-new.postbox.link-add-category': 'Thêm danh mục',
  //
  'admin.posts.add-new.postbox.submitdiv.title': 'Đăng',
  'admin.posts.add-new.postbox.submitdiv.btn-post': 'Đăng',
  'admin.posts.add-new.postbox.submitdiv.label.post-status': 'Trạng thái',
  'admin.posts.add-new.postbox.submitdiv.label.post-visibility': 'Hiển thị',
  'admin.posts.add-new.postbox.submitdiv.label.post-curtime-create': 'Đăng',
  'admin.posts.add-new.postbox.submitdiv.label.post-curtime-update': 'Đã đăng lúc',
  'admin.posts.add-new.postbox.submitdiv.value.post-status.draft': 'Bản nháp',
  'admin.posts.add-new.postbox.submitdiv.value.post-visibility.visible': 'Công khai',
  'admin.posts.add-new.postbox.submitdiv.value.post-visibility.hidden': 'Riêng tư',
  'admin.posts.add-new.postbox.submitdiv.value.post-curtime-create': 'ngay lập tức',
  //
  'admin.posts.add-new.postbox.descriptiondiv.title': 'Mô tả',
  'admin.posts.add-new.postbox.descriptiondiv.label.description': 'Mô tả ngắn',
  //
  'admin.posts.add-new.postbox.categorydiv.title': 'Danh mục',
  'admin.posts.add-new.postbox.categorydiv.label.category-all': 'Tất cả danh mục',
  'admin.posts.add-new.postbox.categorydiv.label.popular-category': 'Dùng nhiều nhất',
  'admin.posts.add-new.postbox.categorydiv.dropdown.label': '—— Danh mục cha ——',
  'admin.posts.add-new.postbox.categorydiv.input.title.placeholder': 'Tên danh mục',
  'admin.posts.add-new.postbox.categorydiv.input.title.error': 'Vui lòng điền tên danh mục!',
  'admin.posts.add-new.postbox.categorydiv.error-msg.create-category-failure': 'Tạo danh mục mới không thành công! Vui lòng thử lại sau.',
  //
  'admin.posts.add-new.postbox.titlediv.label.title': 'Thêm tiêu đề',
  //
  'admin.posts.add-new.postbox.slugdiv.label.slug': 'Liên kết tĩnh',
  //
  'admin.posts.add-new.postbox.featureimagediv.title': 'Ảnh đại diện',
  'admin.posts.add-new.postbox.featureimagediv.link-add': 'Đặt ảnh đại diện',
  'admin.posts.add-new.postbox.featureimagediv.link-remove': 'Xóa ảnh đại diện',
  'admin.posts.add-new.postbox.featureimagediv.link-description': 'Nhấn vào ảnh để sửa hoặc cập nhật',
  //
  'admin.posts.add-new.postbox.mediadiv.title': 'Danh sách ảnh',
  'admin.posts.add-new.postbox.mediadiv.link-add': 'Thêm hình ảnh',
  //
  'admin.posts.add-new.postbox.contentdiv.title.product': 'Nội dung sản phẩm',
  'admin.posts.add-new.postbox.contentdiv.title.course': 'Nội dung khóa học',
  'admin.posts.add-new.postbox.contentdiv.title.article': 'Nội dung bài viết',
  'admin.posts.add-new.postbox.contentdiv.label.title.product': 'Tiêu đề sản phẩm',
  'admin.posts.add-new.postbox.contentdiv.label.title.course': 'Tiêu đề khóa học',
  'admin.posts.add-new.postbox.contentdiv.label.title.chapter.course': 'Tiêu đề chương',
  'admin.posts.add-new.postbox.coursediv.label.chapter.image': 'Hình ảnh',
  'admin.posts.add-new.postbox.coursediv.label.chapter.description': 'Mô tả chương',
  'admin.posts.add-new.postbox.contentdiv.label.title.article': 'Tiêu đề bài viết',
  'admin.posts.add-new.postbox.contentdiv.label.slug': 'Liên kết tĩnh',
  'admin.posts.add-new.postbox.contentdiv.label.content.product': 'Nội dung của sản phẩm',
  'admin.posts.add-new.postbox.contentdiv.label.content.course': 'Nội dung của khóa học',
  'admin.posts.add-new.postbox.contentdiv.label.content.article': 'Nội dung của bài viết',
  'admin.posts.add-new.postbox.contentdiv.label.ingredient': 'Thành phần thuốc',
  'admin.posts.add-new.postbox.contentdiv.label.info': 'Thông tin',
  'admin.posts.add-new.postbox.contentdiv.label.info.form': 'Dạng bào chế',
  'admin.posts.add-new.postbox.contentdiv.label.info.bag': 'Quy cách',
  'admin.posts.add-new.postbox.contentdiv.label.info.ingredient': 'Thành phần',
  'admin.posts.add-new.postbox.contentdiv.label.info.origin': 'Xuất xứ',
  'admin.posts.add-new.postbox.contentdiv.label.info.producer': 'Nhà sản xuất',
  'admin.posts.add-new.postbox.contentdiv.label.info.serial': 'Số đăng ký',
  'admin.posts.add-new.postbox.contentdiv.label.uses': 'Công dụng',
  'admin.posts.add-new.postbox.contentdiv.label.usage': 'Hướng dẫn sử dụng',
  'admin.posts.add-new.postbox.contentdiv.label.description': 'Mô tả',
  'admin.posts.add-new.postbox.contentdiv.label.side_effect': 'Tác dụng phụ',
  'admin.posts.add-new.postbox.contentdiv.label.note': 'Lưu ý',
  'admin.posts.add-new.postbox.contentdiv.label.preservat': 'Bảo quản',

  //
  'admin.posts.add-new.postbox.coursediv.title': 'Thông tin khóa học',
  'admin.posts.add-new.postbox.coursechapterdiv.title': 'Thông tin chương',
  'admin.posts.add-new.postbox.coursechapterlistdiv.title': 'Danh sách chương',
  'admin.posts.add-new.postbox.coursediv.chapter.title': 'Thông tin chương',
  'admin.posts.add-new.postbox.coursediv.chapter': 'Chương',
  'admin.posts.add-new.postbox.coursediv.label.lesson': 'Số lượng bài học',
  'admin.posts.add-new.postbox.coursediv.label.duration': 'Thời lượng khóa học',
  'admin.posts.add-new.postbox.coursediv.label.student': 'Số lượng học sinh của khóa học',
  'admin.posts.add-new.postbox.coursediv.label.price': 'Giá khóa học',
  'admin.posts.add-new.postbox.coursediv.error-msg.price': 'Giá khóa học phải bằng hoặc lớn hơn 0!',
  'admin.posts.add-new.postbox.coursediv.label.discount_id': 'Mã giảm giá',
  //
  'admin.posts.add-new.postbox.metadiv.title': 'Trường dữ liệu',
  //
  'admin.posts.add-new.postbox.groupdiv.title': 'Nhóm bài viết',
  //
  'admin.posts.add-new.postbox.relatediv.title': 'Bài viết liên quan',
  //
  'admin.posts.add-new.postbox.trademarkdiv.title': 'Thương hiệu',
  //
  'admin.posts.add-new.postbox.trademark.title': 'Thương hiệu',
  //
  'admin.posts.add-new.postbox.seodiv.title': 'SEO',
  'admin.posts.add-new.postbox.seodiv.label.title': 'Tiêu đề SEO',
  'admin.posts.add-new.postbox.seodiv.label.image': 'Hình ảnh SEO',
  'admin.posts.add-new.postbox.seodiv.btn.upload-image': 'Tải ảnh',
  'admin.posts.add-new.postbox.seodiv.btn.remove-image': 'Xóa ảnh',
  'admin.posts.add-new.postbox.seodiv.label.description': 'Mô tả SEO',
  'admin.posts.add-new.postbox.seodiv.label.keyword': 'Từ khóa SEO',
  'admin.posts.add-new.postbox.seodiv.keyword.description': 'Nhấn phím "Enter" để thêm từ khóa.',
  //
  'admin.posts.add-new.postbox.productdiv.title': 'Thông tin sản phẩm',
  'admin.posts.add-new.postbox.productdiv.label.code': 'Mã sản phẩm',
  'admin.posts.add-new.postbox.productdiv.error-msg.code': 'Tối đa 30 ký tự!',
  'admin.posts.add-new.postbox.productdiv.label.price': 'Giá sản phẩm',
  'admin.posts.add-new.postbox.productdiv.error-msg.price': 'Giá sản phẩm phải bằng hoặc lớn hơn 0!',
  'admin.posts.add-new.postbox.productdiv.label.discount_id': 'Mã giảm giá',
  'admin.posts.add-new.postbox.productdiv.label.prices': 'Giá sản phẩm theo Đại lý',
  'admin.posts.add-new.postbox.productdiv.dropdown.discount.empty': '—— Trống ——',
  //
  'admin.posts.add-new.noti.create-post.success': 'Thêm bài viết thành công!',
  'admin.posts.add-new.noti.create-post.failure': 'Thêm bài viết không thành công! Vui lòng thử lại sau.',
  'admin.posts.add-new.noti.update-post.success': 'Cập nhật bài viết thành công!',
  'admin.posts.add-new.noti.update-post.failure': 'Cập nhật bài viết không thành công! Vui lòng thử lại sau.',
  'admin.posts.add-new.noti.delete-post.success': 'Xóa bài viết thành công!',
  'admin.posts.add-new.noti.delete-post.failure': 'Xóa bài viết không thành công! Vui lòng thử lại sau.',

  // lesson
  'admin.posts.add-new.postbox.coursediv.input.duplicate.error': 'Bài học này đã tồn tại',
  'admin.posts.add-new.postbox.coursediv.label.lesson.list': 'Danh sách bài học',
  'admin.posts.add-new.postbox.coursediv.label.chapter.list': 'Danh sách chương',
  'admin.posts.add-new.postbox.coursediv.label.chapter.add': 'Thêm bài học',
  'admin.posts.add-new.postbox.coursediv.label.chapter.lesson.title': 'Tiêu đề bài học',
  'admin.posts.add-new.postbox.coursediv.label.chapter.lesson.link': 'Liên kết',
  'admin.posts.add-new.postbox.coursediv.label.chapter.lesson.duration': 'Thời lượng(phút)',
  'admin.posts.add-new.postbox.coursediv.label.chapter.lesson.highlight': 'Nổi bật',
  'admin.posts.add-new.postbox.coursediv.label.chapter.lesson.description': 'Mô tả',
};

const postCategory = {
  'admin.posts.post-category.hetmet-1': 'Danh mục bài viết',
  'admin.posts.post-category.hetmet-2': 'Bài viết',
  'admin.posts.post-category.hetmet-product-1': 'Danh mục sản phẩm',
  'admin.posts.post-category.hetmet-product-2': 'Sản phẩm',
  'admin.posts.post-category.col-left.title': 'Thêm danh mục bài viết mới',
  'admin.posts.post-category.col-left.title-product': 'Thêm danh mục sản phẩm mới',
  'admin.posts.post-category.col-left.title-1': 'Thêm $x mới',
  'admin.posts.post-category.noti.create-post-category.title.success': 'Thêm danh mục bài viết thành công!',
  'admin.posts.post-category.noti.create-post-category.title.success-product': 'Thêm danh mục sản phẩm thành công!',
  'admin.posts.post-category.noti.create-post-category.title.success-1': 'Thêm $x thành công!',
  'admin.posts.post-category.noti.create-post-category.title.failure': 'Thêm danh mục bài viết không thành công! Vui lòng thử lại sau.',
  'admin.posts.post-category.noti.create-post-category.title.failure-product': 'Thêm danh mục sản phẩm không thành công! Vui lòng thử lại sau.',
  'admin.posts.post-category.noti.create-post-category.title.failure-1': 'Thêm $x không thành công! Vui lòng thử lại sau.',
  'admin.posts.post-category.noti.update-post-category.title.success': 'Cập nhật danh mục bài viết thành công!',
  'admin.posts.post-category.noti.update-post-category.title.success-product': 'Cập nhật danh mục sản phẩm thành công!',
  'admin.posts.post-category.noti.update-post-category.title.success-1': 'Cập nhật $x thành công!',
  'admin.posts.post-category.noti.update-post-category.title.failure': 'Cập nhật danh mục bài viết không thành công! Vui lòng thử lại sau.',
  'admin.posts.post-category.noti.update-post-category.title.failure-product': 'Cập nhật danh mục sản phẩm không thành công! Vui lòng thử lại sau.',
  'admin.posts.post-category.noti.update-post-category.title.failure-1': 'Cập nhật $x không thành công! Vui lòng thử lại sau.',
  'admin.posts.post-category.noti.delete-post-category.title.success': 'Xóa danh mục bài viết thành công!',
  'admin.posts.post-category.noti.delete-post-category.title.success-product': 'Xóa danh mục sản phẩm thành công!',
  'admin.posts.post-category.noti.delete-post-category.title.success-1': 'Xóa $x thành công!',
  'admin.posts.post-category.noti.delete-post-category.title.failure': 'Xóa danh mục bài viết không thành công! Vui lòng thử lại sau.',
  'admin.posts.post-category.noti.delete-post-category.title.failure-product': 'Xóa danh mục sản phẩm không thành công! Vui lòng thử lại sau.',
  'admin.posts.post-category.noti.delete-post-category.title.failure-1': 'Xóa $x không thành công! Vui lòng thử lại sau.',
  'admin.posts.post-category.col-left.label.title': 'Tiêu đề',
  'admin.posts.post-category.col-left.label.slug': 'Đường dẫn',
  'admin.posts.post-category.col-left.label.image': 'Hình ảnh',
  'admin.posts.post-category.col-left.label.description': 'Mô tả',
  'admin.posts.post-category.col-left.label.parent_id': 'Danh mục cha',
  'admin.posts.post-category.col-left.label.layout': 'Hiển thị theo',
  'admin.posts.post-category.col-left.label.number': 'Bài viết hiển thị',
  'admin.posts.post-category.col-left.label.number-product': 'Sản phẩm hiển thị',
  'admin.posts.post-category.col-left.label.group': 'Áp dụng cho các nhóm dữ liệu',
  'admin.posts.post-category.col-left.label.group-1': 'Áp dụng cho các $x',
  'admin.posts.post-category.col-left.label.sort': 'Thứ tự',
  'admin.posts.post-category.col-left.label.show-by-cat_id': 'Hiển thị theo Danh mục',
  'admin.posts.post-category.col-left.label.show-by-cat_id-1': 'Hiển thị theo $x',
  'admin.posts.post-category.col-left.description.slug': '“slug” là đường dẫn thân thiện của tên. Nó thường chỉ bao gồm kí tự viết thường, số và dấu gạch ngang, không dùng tiếng Việt.',
  'admin.posts.post-category.col-left.description.parent_id': 'Danh mục khác với thẻ, bạn có thể sử dụng nhiều cấp danh mục. Ví dụ: Trong danh mục nhạc, bạn có danh mục con là nhạc Pop, nhạc Jazz. Việc này hoàn toàn là tùy theo ý bạn.',
  'admin.posts.post-category.col-left.btn.upload-image': 'Tải ảnh',
  'admin.posts.post-category.col-left.btn.remove-image': 'Xóa ảnh',
  'admin.posts.post-category.modal.update.title': 'Cập nhật danh mục bài viết',
  'admin.posts.post-category.modal.update.title-product': 'Cập nhật danh mục sản phẩm',
  'admin.posts.post-category.modal.update.title-1': 'Cập nhật $x',
  'admin.posts.post-category.modal.details.title': 'Chi tiết danh mục bài viết',
  'admin.posts.post-category.modal.details.title-product': 'Chi tiết danh mục sản phẩm',
  'admin.posts.post-category.modal.details.title-1': 'Chi tiết $x',
  'admin.posts.post-category.category-parent-empty.label': 'Trống',
  'admin.posts.post-category.dropdown.article': 'Bài viết',
  'admin.posts.post-category.dropdown.category': 'Danh mục',
  'admin.posts.post-category.modal.delete.title': 'Xác nhận xóa danh mục bài viết!',
  'admin.posts.post-category.modal.delete.title-product': 'Xác nhận xóa danh mục sản phẩm!',
  'admin.posts.post-category.modal.delete.title-1': 'Xác nhận xóa $x!',
  'admin.posts.post-category.modal.delete.description': 'Bạn chắc chắn muốn xóa danh mục bài viết này?',
  'admin.posts.post-category.modal.delete.description-product': 'Bạn chắc chắn muốn xóa danh mục sản phẩm này?',
  'admin.posts.post-category.modal.delete.description-1': 'Bạn chắc chắn muốn xóa $x này?',
};

const dataGroup = {
  'admin.posts.data-group.hetmet': 'Nhóm dữ liệu - Bài viết',
  'admin.posts.data-group.breadcrumb.heading': 'Nhóm dữ liệu',
  'admin.posts.data-group.breadcrumb.parent': 'Bài viết',
  'admin.posts.data-group.col-left.title': 'Thêm nhóm dữ liệu mới',
  'admin.posts.data-group.col-left.title-1': 'Thêm $x mới',
  'admin.posts.data-group.noti.create-data-group.title.success': 'Thêm nhóm dữ liệu thành công!',
  'admin.posts.data-group.noti.create-data-group.title.success-1': 'Thêm $x thành công!',
  'admin.posts.data-group.noti.create-data-group.title.failure': 'Thêm nhóm dữ liệu không thành công! Vui lòng thử lại sau.',
  'admin.posts.data-group.noti.create-data-group.title.failure-1': 'Thêm $x không thành công! Vui lòng thử lại sau.',
  'admin.posts.data-group.noti.save-data-group.title.success': 'Cập nhật nhóm dữ liệu thành công!',
  'admin.posts.data-group.noti.save-data-group.title.success-1': 'Cập nhật $x thành công!',
  'admin.posts.data-group.noti.save-data-group.title.failure': 'Cập nhật nhóm dữ liệu không thành công! Vui lòng thử lại sau.',
  'admin.posts.data-group.noti.save-data-group.title.failure-1': 'Cập nhật $x không thành công! Vui lòng thử lại sau.',
  'admin.posts.data-group.noti.delete-data-group.title.success': 'Xóa nhóm dữ liệu thành công!',
  'admin.posts.data-group.noti.delete-data-group.title.success-1': 'Xóa $x thành công!',
  'admin.posts.data-group.noti.delete-data-group.title.failure': 'Xóa nhóm dữ liệu không thành công! Vui lòng thử lại sau.',
  'admin.posts.data-group.noti.delete-data-group.title.failure-1': 'Xóa $x không thành công! Vui lòng thử lại sau.',
  'admin.posts.data-group.error-msg-input.type-id-require': 'Vui lòng chọn loại bài viết!',
  'admin.posts.data-group.column.type-id': 'Loại bài viết',
  'admin.posts.data-group.col-left.label.type_id': 'Loại bài viết',
  'admin.posts.data-group.col-left.label.title': 'Tên',
  'admin.posts.data-group.col-left.label.slug': 'Đường dẫn',
  'admin.posts.data-group.col-left.label.image': 'Hình ảnh',
  'admin.posts.data-group.col-left.label.description': 'Mô tả',
  'admin.posts.data-group.col-left.btn.upload-image': 'Tải ảnh',
  'admin.posts.data-group.col-left.btn.remove-image': 'Xóa ảnh',
  'admin.posts.data-group.modal.details.title': 'Chi tiết nhóm dữ liệu',
  'admin.posts.data-group.modal.details.title-1': 'Chi tiết $x',
  'admin.posts.data-group.modal.update.title': 'Cập nhật nhóm dữ liệu',
  'admin.posts.data-group.modal.update.title-1': 'Cập nhật $x',
  'admin.posts.data-group.modal.delete.title': 'Xác nhận xóa nhóm dữ liệu!',
  'admin.posts.data-group.modal.delete.title-1': 'Xác nhận xóa $x!',
  'admin.posts.data-group.modal.delete.description': 'Bạn chắc chắn muốn xóa nhóm dữ liệu này?',
  'admin.posts.data-group.modal.delete.description-1': 'Bạn chắc chắn muốn xóa $x này?',
};

const dataField = {
  'admin.posts.data-field.hetmet': 'Trường dữ liệu - Bài viết',
  'admin.posts.data-field.breadcrumb.heading': 'Trường dữ liệu',
  'admin.posts.data-field.breadcrumb.parent': 'Bài viết',
  'admin.posts.data-field.col-left.title': 'Thêm trường dữ liệu mới',
  'admin.posts.data-field.noti.create-data-field.title.success': 'Thêm trường dữ liệu thành công!',
  'admin.posts.data-field.noti.create-data-field.title.failure': 'Thêm trường dữ liệu không thành công! Vui lòng thử lại sau.',
  'admin.posts.data-field.noti.save-data-field.title.success': 'Cập nhật trường dữ liệu thành công!',
  'admin.posts.data-field.noti.save-data-field.title.failure': 'Cập nhật trường dữ liệu không thành công! Vui lòng thử lại sau.',
  'admin.posts.data-field.noti.delete-data-field.title.success': 'Xóa trường dữ liệu thành công!',
  'admin.posts.data-field.noti.delete-data-field.title.failure': 'Xóa trường dữ liệu không thành công! Vui lòng thử lại sau.',
  'admin.posts.data-field.error-msg-input.data-style-require': 'Vui lòng thêm ít nhất 1 dữ liệu!',
  'admin.posts.data-field.column.type-id': 'Loại bài viết',
  'admin.posts.data-field.col-left.label.key': 'Khóa',
  'admin.posts.data-field.col-left.label.title': 'Tiêu đề',
  'admin.posts.path-field.col-left.label.title': 'Đường dẫn',
  'admin.posts.data-field.col-left.label.style': 'Kiểu dữ liệu',
  'admin.posts.data-field.col-left.label.data': 'Danh sách dữ liệu',
  'admin.posts.data-field.col-left.btn.upload-image': 'Tải ảnh',
  'admin.posts.data-field.col-left.btn.remove-image': 'Xóa ảnh',
  'admin.posts.data-field.modal.details.title': 'Chi tiết trường dữ liệu',
  'admin.posts.data-field.modal.update.title': 'Cập nhật trường dữ liệu',
  'admin.posts.data-field.modal.delete.title': 'Xác nhận xóa trường dữ liệu!',
  'admin.posts.data-field.modal.delete.description': 'Bạn chắc chắn muốn xóa trường dữ liệu này?',
  'admin.posts.data-field.style-list.text': 'Chuỗi 1 dòng (text)',
  'admin.posts.data-field.style-list.number': 'Số nguyên (number)',
  'admin.posts.data-field.style-list.date': 'Ngày (date)',
  'admin.posts.data-field.style-list.textarea': 'Chuỗi nhiều dòng (textarea)',
  'admin.posts.data-field.style-list.editor': 'Trình soạn thảo (editor)',
  'admin.posts.data-field.style-list.selectbox': 'Một lựa chọn thả xuống (select)',
  'admin.posts.data-field.style-list.radiobox': 'Một lựa chọn (radio)',
  'admin.posts.data-field.style-list.checkbox': 'Nhiều lựa chọn (checkbox)',
  'admin.posts.data-field.style-list.media': 'Chọn media (media)',
  'admin.posts.data-field.style-list.api': 'Api',
  'admin.posts.data-field.col-left.label.script': 'Script',
};

const productDiscount = {
  'admin.posts.product-discount.hetmet': 'Giảm giá - Sản phẩm',
  'admin.posts.product-discount.breadcrumb.heading': 'Giảm giá',
  'admin.posts.product-discount.breadcrumb.parent': 'Sản phẩm',
  'admin.posts.product-discount.col-left.title': 'Thêm giảm giá mới',
  'admin.posts.product-discount.col-left.title-1': 'Thêm $x',
  'admin.posts.product-discount.col-left.label.title': 'Tiêu đề',
  'admin.posts.product-discount.col-left.label.start': 'Thời gian bắt đầu',
  'admin.posts.product-discount.col-left.error-msg.start': 'Vui lòng chọn thời gian bắt đầu sau thời gian hiện tại!',
  'admin.posts.product-discount.col-left.label.end': 'Thời gian kết thúc',
  'admin.posts.product-discount.col-left.error-msg.end': 'Vui lòng chọn thời gian kết thúc sau thời gian bắt đầu!',
  'admin.posts.product-discount.col-left.label.config': 'Chi tiết',
  'admin.posts.product-discount.col-left.error-msg.config': 'Vui lòng chỉnh số lượng khác nhau!',
  'admin.posts.product-discount.col-left.label.config.number': 'Số lượng',
  'admin.posts.product-discount.col-left.label.config.discount': 'Giảm giá',
  'admin.posts.product-discount.col-left.label.config.style': 'Kiểu',
  'admin.posts.product-discount.dropdown.percent': 'Phần trăm (%)',
  'admin.posts.product-discount.dropdown.money': 'Tiền tệ',
  'admin.posts.product-discount.col.status.disable': 'Chưa kích hoạt',
  'admin.posts.product-discount.col.status.enable': 'Kích hoạt',
  'admin.posts.product-discount.noti.create-product-discount.title.success': 'Thêm giảm giá mới thành công!',
  'admin.posts.product-discount.noti.create-product-discount.title.success-1': 'Thêm $x thành công!',
  'admin.posts.product-discount.noti.create-product-discount.title.failure': 'Thêm giảm giá mới không thành công! Vui lòng thử lại sau.',
  'admin.posts.product-discount.noti.create-product-discount.title.failure-1': 'Thêm $x không thành công! Vui lòng thử lại sau.',
  'admin.posts.product-discount.noti.update-product-discount.title.success': 'Cập nhật giảm giá thành công!',
  'admin.posts.product-discount.noti.update-product-discount.title.success-1': 'Cập nhật $x thành công!',
  'admin.posts.product-discount.noti.update-product-discount.title.failure': 'Cập nhật giảm giá không thành công! Vui lòng thử lại sau.',
  'admin.posts.product-discount.noti.update-product-discount.title.failure-1': 'Cập nhật $x không thành công! Vui lòng thử lại sau.',
  'admin.posts.product-discount.noti.delete-product-discount.title.success': 'Xóa giảm giá thành công!',
  'admin.posts.product-discount.noti.delete-product-discount.title.success-1': 'Xóa $x thành công!',
  'admin.posts.product-discount.noti.delete-product-discount.title.failure': 'Xóa giảm giá không thành công! Vui lòng thử lại sau.',
  'admin.posts.product-discount.noti.delete-product-discount.title.failure-1': 'Xóa $x không thành công! Vui lòng thử lại sau.',
  'admin.posts.product-discount.modal.delete.title': 'Xác nhận xóa giảm giá!',
  'admin.posts.product-discount.modal.delete.title-1': 'Xác nhận xóa $x!',
  'admin.posts.product-discount.modal.delete.description': 'Bạn có chắc chắn muốn xóa giảm giá này?',
  'admin.posts.product-discount.modal.delete.description-1': 'Bạn có chắc chắn muốn xóa $x này?',
  'admin.posts.product-discount.modal.details.title': 'Chi tiết giảm giá!',
  'admin.posts.product-discount.modal.details.title-1': 'Chi tiết $x!',
  'admin.posts.product-discount.modal.update.title': 'Cập nhật giảm giá!',
  'admin.posts.product-discount.modal.update.title-1': 'Cập nhật $x!',
};

const articleGroup = {
  'admin.posts.article-group.hetmet-1': 'Nhóm bài viết',
  'admin.posts.article-group.hetmet-2': 'Bài viết',
  'admin.posts.article-group.hetmet-product-1': 'Nhóm sản phẩm',
  'admin.posts.article-group.hetmet-product-2': 'Sản phẩm',
  'admin.posts.article-group.breadcrumb.heading': 'Nhóm bài viết',
  'admin.posts.article-group.breadcrumb.heading-product': 'Nhóm sản phẩm',
  'admin.posts.article-group.breadcrumb.parent': 'Bài viết',
  'admin.posts.article-group.breadcrumb.parent-product': 'Sản phẩm',
  'admin.posts.article-group.col-left.title': 'Thêm nhóm bài viết mới',
  'admin.posts.article-group.col-left.title-product': 'Thêm nhóm sản phẩm mới',
  'admin.posts.article-group.col-left.title-1': 'Thêm $x mới',
  'admin.posts.article-group.noti.create-article-group.title.success': 'Thêm nhóm bài viết thành công!',
  'admin.posts.article-group.noti.create-article-group.title.success-product': 'Thêm nhóm sản phẩm thành công!',
  'admin.posts.article-group.noti.create-article-group.title.success-1': 'Thêm $x thành công!',
  'admin.posts.article-group.noti.create-article-group.title.failure': 'Thêm nhóm bài viết không thành công! Vui lòng thử lại sau.',
  'admin.posts.article-group.noti.create-article-group.title.failure-product': 'Thêm nhóm sản phẩm không thành công! Vui lòng thử lại sau.',
  'admin.posts.article-group.noti.create-article-group.title.failure-1': 'Thêm $x không thành công! Vui lòng thử lại sau.',
  'admin.posts.article-group.noti.save-article-group.title.success': 'Cập nhật nhóm bài viết thành công!',
  'admin.posts.article-group.noti.save-article-group.title.success-product': 'Cập nhật nhóm sản phẩm thành công!',
  'admin.posts.article-group.noti.save-article-group.title.success-1': 'Cập nhật $x thành công!',
  'admin.posts.article-group.noti.save-article-group.title.failure': 'Cập nhật nhóm bài viết không thành công! Vui lòng thử lại sau.',
  'admin.posts.article-group.noti.save-article-group.title.failure-product': 'Cập nhật nhóm sản phẩm không thành công! Vui lòng thử lại sau.',
  'admin.posts.article-group.noti.save-article-group.title.failure-1': 'Cập nhật $x không thành công! Vui lòng thử lại sau.',
  'admin.posts.article-group.noti.delete-article-group.title.success': 'Xóa nhóm bài viết thành công!',
  'admin.posts.article-group.noti.delete-article-group.title.success-product': 'Xóa nhóm sản phẩm thành công!',
  'admin.posts.article-group.noti.delete-article-group.title.success-1': 'Xóa $x thành công!',
  'admin.posts.article-group.noti.delete-article-group.title.failure': 'Xóa nhóm bài viết không thành công! Vui lòng thử lại sau.',
  'admin.posts.article-group.noti.delete-article-group.title.failure-product': 'Xóa nhóm sản phẩm không thành công! Vui lòng thử lại sau.',
  'admin.posts.article-group.noti.delete-article-group.title.failure-1': 'Xóa $x không thành công! Vui lòng thử lại sau.',
  'admin.posts.article-group.col-left.label.title': 'Tên',
  'admin.posts.article-group.col-left.label.slug': 'Đường dẫn',
  'admin.posts.article-group.col-left.label.image': 'Hình ảnh',
  'admin.posts.article-group.col-left.label.description': 'Mô tả',
  'admin.posts.article-group.col-left.btn.upload-image': 'Tải ảnh',
  'admin.posts.article-group.col-left.btn.remove-image': 'Xóa ảnh',
  'admin.posts.article-group.modal.details.title': 'Chi tiết nhóm bài viết',
  'admin.posts.article-group.modal.details.title-product': 'Chi tiết nhóm sản phẩm',
  'admin.posts.article-group.modal.details.title-1': 'Chi tiết $x',
  'admin.posts.article-group.modal.update.title': 'Cập nhật nhóm bài viết',
  'admin.posts.article-group.modal.update.title-product': 'Cập nhật nhóm sản phẩm',
  'admin.posts.article-group.modal.update.title-1': 'Cập nhật $x',
  'admin.posts.article-group.modal.delete.title': 'Xác nhận xóa nhóm bài viết!',
  'admin.posts.article-group.modal.delete.title-product': 'Xác nhận xóa nhóm sản phẩm!',
  'admin.posts.article-group.modal.delete.title-1': 'Xác nhận xóa $x!',
  'admin.posts.article-group.modal.delete.description': 'Bạn chắc chắn muốn xóa nhóm bài viết này?',
  'admin.posts.article-group.modal.delete.description-product': 'Bạn chắc chắn muốn xóa nhóm sản phẩm này?',
  'admin.posts.article-group.modal.delete.description-1': 'Bạn chắc chắn muốn xóa $x này?',
};

const productOrder = {
  'admin.posts.product-order.hetmet': 'Đơn hàng - Sản phẩm',
  'admin.posts.product-order.breadcrumb.heading': 'Đơn hàng',
  'admin.posts.product-order.breadcrumb.parent': 'Sản phẩm',
  'admin.posts.product-order.btn.add': 'Thêm đơn hàng mới',
  'admin.posts.product-order.yup.error-msg.name': 'Vui lòng nhập tên!',
  'admin.posts.product-order.yup.error-msg.phone': 'Vui lòng nhập số điện thoại!',
  'admin.posts.product-order.yup.error-msg.address': 'Vui lòng nhập địa chỉ!',
  'admin.posts.product-order.yup.error-msg.products': 'Vui lòng chọn ít nhất 1 sản phẩm!',
  'admin.posts.product-order.modal.title.create': 'Thêm đơn hàng mới',
  'admin.posts.product-order.modal.title.create-1': 'Thêm $x mới',
  'admin.posts.product-order.modal.title.update': 'Cập nhật đơn hàng',
  'admin.posts.product-order.modal.title.update-1': 'Cập nhật $x',
  'admin.posts.product-order.modal.title.details': 'Chi tiết đơn hàng',
  'admin.posts.product-order.modal.title.details-1': 'Chi tiết $x',
  'admin.posts.product-order.modal.title.update-status-order': 'Điều phối đơn hàng',
  'admin.posts.product-order.modal.title.update-status-order-1': 'Điều phối $x',
  'admin.posts.product-order.modal.title.cancel-order': 'Hủy đơn hàng',
  'admin.posts.product-order.modal.title.cancel-order-1': 'Hủy $x',
  'admin.posts.product-order.modal.label.name': 'Họ tên khách hàng',
  'admin.posts.product-order.modal.label.phone': 'Số điện thoại nhận hàng',
  'admin.posts.product-order.modal.label.email': 'Địa chỉ E-mail',
  'admin.posts.product-order.modal.label.status': 'Trạng thái đơn hàng',
  'admin.posts.product-order.modal.label.status-1': 'Trạng thái $x',
  'admin.posts.product-order.modal.label.address': 'Địa chỉ nhận hàng',
  'admin.posts.product-order.modal.label.note': 'Ghi chú',
  'admin.posts.product-order.modal.label.products': 'Danh sách sản phẩm hiện có',
  'admin.posts.product-order.modal.btn.create-order': 'Thêm đơn hàng',
  'admin.posts.product-order.modal.btn.update-order': 'Cập nhật đơn hàng',
  'admin.posts.product-order.modal.column.code': 'Mã sản phẩm',
  'admin.posts.product-order.modal.column.image': 'Hình ảnh',
  'admin.posts.product-order.modal.column.title': 'Tên sản phẩm',
  'admin.posts.product-order.modal.column.number': 'Số lượng',
  'admin.posts.product-order.modal.error-msg.number': 'Vui lòng nhập số lượng sản phẩm lớn hơn 0!',
  'admin.posts.product-order.modal.update-status-order.btn-next': 'Tiếp tục',
  'admin.posts.product-order.modal.update-status-order.step1.title': 'Tình trạng đơn hàng:',
  'admin.posts.product-order.modal.update-status-order.step1.title-1': 'Tình trạng $x:',
  'admin.posts.product-order.modal.update-status-order.loading-title': 'Đang thực hiện điều phối đơn hàng!',
  'admin.posts.product-order.modal.update-status-order.loading-title-1': 'Đang thực hiện điều phối $x!',
  'admin.posts.product-order.modal.cancel-order.loading-title': 'Đang thực hiện hủy đơn hàng!',
  'admin.posts.product-order.modal.cancel-order.loading-title-1': 'Đang thực hiện hủy $x!',
  'admin.posts.product-order.modal.actions.btn-update-status-order': 'Điều phối đơn hàng',
  'admin.posts.product-order.modal.actions.btn-update-status-order-1': 'Điều phối $x',
  'admin.posts.product-order.modal.actions.btn-cancel-order': 'Hủy đơn hàng',
  'admin.posts.product-order.modal.actions.btn-cancel-order-1': 'Hủy $x',
  'admin.posts.product-order.column.name': 'Tên KH',
  'admin.posts.product-order.column.phone': 'Số điện thoại',
  'admin.posts.product-order.column.address': 'Địa chỉ',
  'admin.posts.product-order.column.note': 'Ghi chú',
  'admin.posts.product-order.column.fee': 'Tổng tiền',
  'admin.posts.product-order.column.status': 'Trạng thái',
  'admin.posts.product-order.order.status.new': 'Mới',
  'admin.posts.product-order.order.status.processing': 'Đang xử lý',
  'admin.posts.product-order.order.status.delivery': 'Đang vận chuyển',
  'admin.posts.product-order.order.status.delivered': 'Vận chuyển thành công',
  'admin.posts.product-order.order.status.canceled': 'Hủy',
  'admin.posts.product-order.product-list-empty': 'Danh sách trống',
  'admin.posts.product-order.noti.create-order.success': 'Thêm đơn hàng thành công!',
  'admin.posts.product-order.noti.create-order.success-1': 'Thêm $x thành công!',
  'admin.posts.product-order.noti.create-order.failure': 'Thêm đơn hàng thất bại! Vui lòng thử lại sau.',
  'admin.posts.product-order.noti.create-order.failure-1': 'Thêm $x thất bại! Vui lòng thử lại sau.',
  'admin.posts.product-order.noti.update-order.success': 'Cập nhật đơn hàng thành công!',
  'admin.posts.product-order.noti.update-order.success-1': 'Cập nhật $x thành công!',
  'admin.posts.product-order.noti.update-order.failure': 'Cập nhật đơn hàng thất bại! Vui lòng thử lại sau.',
  'admin.posts.product-order.noti.update-order.failure-1': 'Cập nhật $x thất bại! Vui lòng thử lại sau.',
  'admin.posts.product-order.noti.delete-order.success': 'Xóa đơn hàng thành công!',
  'admin.posts.product-order.noti.delete-order.success-1': 'Xóa $x thành công!',
  'admin.posts.product-order.noti.delete-order.failure': 'Xóa đơn hàng thất bại! Vui lòng thử lại sau.',
  'admin.posts.product-order.noti.delete-order.failure-1': 'Xóa $x thất bại! Vui lòng thử lại sau.',
  'admin.posts.product-order.noti.update-status-order.success': 'Điều phối đơn hàng thành công!',
  'admin.posts.product-order.noti.update-status-order.success-1': 'Điều phối $x thành công!',
  'admin.posts.product-order.noti.update-status-order.failure': 'Điều phối đơn hàng thất bại! Vui lòng thử lại sau.',
  'admin.posts.product-order.noti.update-status-order.failure-1': 'Điều phối $x thất bại! Vui lòng thử lại sau.',
  'admin.posts.product-order.noti.cancel-order.success': 'Hủy đơn hàng thành công!',
  'admin.posts.product-order.noti.cancel-order.success-1': 'Hủy $x thành công!',
  'admin.posts.product-order.noti.cancel-order.failure': 'Hủy đơn hàng thất bại! Vui lòng thử lại sau.',
  'admin.posts.product-order.noti.cancel-order.failure-1': 'Hủy $x thất bại! Vui lòng thử lại sau.',
};

const productTrademark = {
  'admin.product.trademark.hetmet': 'Thương hiệu - Sản phẩm',
  'admin.product.trademark.hetmet-1': 'Thương hiệu',
  'admin.product.trademark.col-left.title': 'Thêm thương hiệu',
  'admin.product.trademark.col-left.title-1': 'Thêm $x',
  'admin.product.trademark.noti.create-product-trademark.title.success': 'Thêm thương hiệu mới thành công!',
  'admin.product.trademark.noti.create-product-trademark.title.success-1': 'Thêm $x thành công!',
  'admin.product.trademark.noti.create-product-trademark.title.failure': 'Thêm thương hiệu mới thất bại! Vui lòng thử lại sau.',
  'admin.product.trademark.noti.create-product-trademark.title.failure-1': 'Thêm $x thất bại! Vui lòng thử lại sau.',
  'admin.product.trademark.noti.update-product-trademark.title.success': 'Cập nhật thương hiệu thành công!',
  'admin.product.trademark.noti.update-product-trademark.title.success-1': 'Cập nhật $x thành công!',
  'admin.product.trademark.noti.update-product-trademark.title.failure': 'Cập nhật thương hiệu thất bại! Vui lòng thử lại sau.',
  'admin.product.trademark.noti.update-product-trademark.title.failure-1': 'Cập nhật $x thất bại! Vui lòng thử lại sau.',
  'admin.product.trademark.noti.delete-product-trademark.title.success': 'Xóa thương hiệu thành công!',
  'admin.product.trademark.noti.delete-product-trademark.title.success-1': 'Xóa $x thành công!',
  'admin.product.trademark.noti.delete-product-trademark.title.failure': 'Xóa thương hiệu thất bại! Vui lòng thử lại sau.',
  'admin.product.trademark.noti.delete-product-trademark.title.failure-1': 'Xóa $x thất bại! Vui lòng thử lại sau.',
  'admin.product.trademark.col-left.label.title': 'Tên',
  'admin.product.trademark.col-left.label.slug': 'Đường dẫn',
  'admin.product.trademark.col-left.label.image': 'Hình ảnh',
  'admin.product.trademark.col-left.btn.upload-image': 'Tải ảnh',
  'admin.product.trademark.col-left.btn.remove-image': 'Xóa ảnh',
  'admin.product.trademark.col-left.label.description': 'Mô tả',
  'admin.product.trademark.column.title': 'Tên thương hiệu',
  'admin.product.trademark.column.title-1': 'Tên $x',
  'admin.product.trademark.column.slug': 'Đường dẫn',
  'admin.product.trademark.column.image': 'Hình ảnh',
  'admin.product.trademark.modal.details.title': 'Chi tiết thông tin thương hiệu',
  'admin.product.trademark.modal.details.title-1': 'Chi tiết thông tin $x',
  'admin.product.trademark.modal.update.title': 'Cập nhật thương hiệu',
  'admin.product.trademark.modal.update.title-1': 'Cập nhật $x',
  'admin.product.trademark.modal.delete.title': 'Xác nhận xóa thương hiệu!',
  'admin.product.trademark.modal.delete.title-1': 'Xác nhận xóa $x!',
  'admin.product.trademark.modal.delete.description': 'Bạn có chắc chắn muốn xóa thương hiệu này?',
  'admin.product.trademark.modal.delete.description-1': 'Bạn có chắc chắn muốn xóa $x này?',
};

const vnAdminPostsLangLib = {
  ...posts,
  ...allPosts,
  ...editPost,
  ...addNewPost,
  ...postCategory,
  ...dataGroup,
  ...dataField,
  ...productDiscount,
  ...articleGroup,
  ...productOrder,
  ...productTrademark,
};

export default vnAdminPostsLangLib;
