const widget = {
  'admin.widgets.hetmet': 'Danh sách widget',
  'admin.widgets.breadcrumb.heading': 'Quản lý widget',
  'admin.widgets.breadcrumb.parent': 'Widgets',
  'admin.widgets.btn.add': 'Thêm widget',
  'admin.widget.modal.action.label.key': 'Khóa',
  'admin.widget.error-msg.key': 'Vui lòng nhập khóa',
  'admin.widget.error-msg.title': 'Vui lòng nhập tiêu đề',
  'admin.widget.modal.action.label.title': 'Tiêu đề',
  'admin.widget.modal.action.label.icon': 'Icon',
  'admin.widget.modal.action.label.group': 'Nhóm',
  'admin.widget.noti.create-widget.failure': 'Tạo widget thất bại',
  'admin.widget.noti.update-widget.failure': 'Cập nhật widget thất bại',
  'admin.widget.noti.delete-widget.failure': 'Xóa widget thất bại',
  'admin.widget.modal.action.label.description': 'Mô tả',
  'admin.widget.modal.action.label.type': 'Loại',
  'admin.widget.modal.action.title.create': 'Thêm widget',
  'admin.widget.modal.action.title.update': 'Cập nhật widget',
  'admin.widget.modal.action.title.details': 'Chi tiết widget',
  'admin.widget.modal.delete.title': 'Xác nhận xóa widget',
  'admin.widget.modal.delete.description': 'Bạn chắc chắn xóa widget này?',
  'admin.widget.noti.delete-widget.success': 'Xóa widget thành công',
  'admin.widget.noti.create-widget.success': 'Thêm widget thành công',
  'admin.widget.noti.update-widget.success': 'Cập nhật widget thành công',
  'admin.widget.noti.duplicate': 'Key bị trùng lặp',
};

const vnAdminWidgetLangLib = {
  ...widget,
};

export default vnAdminWidgetLangLib;
