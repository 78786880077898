// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllMenusRequest = createAction('GET_ALL_MENUS_REQUEST');
export const getAllMenusSuccess = createAction('GET_ALL_MENUS_SUCCESS');
export const getAllMenusFailure = createAction('GET_ALL_MENUS_FAILURE');

export const createMenuRequest = createAction('CREATE_MENU_REQUEST');
export const createMenuSuccess = createAction('CREATE_MENU_SUCCESS');
export const createMenuFailure = createAction('CREATE_MENU_FAILURE');
export const resetCreateMenuState = createAction('RESET_CREATE_MENU_STATE');

export const updateMenuRequest = createAction('UPDATE_MENU_REQUEST');
export const updateMenuSuccess = createAction('UPDATE_MENU_SUCCESS');
export const updateMenuFailure = createAction('UPDATE_MENU_FAILURE');
export const resetUpdateMenuState = createAction('RESET_UPDATE_MENU_STATE');

export const deleteMenuRequest = createAction('DELETE_MENU_REQUEST');
export const deleteMenuSuccess = createAction('DELETE_MENU_SUCCESS');
export const deleteMenuFailure = createAction('DELETE_MENU_FAILURE');
export const resetDeleteMenuState = createAction('RESET_DELETE_MENU_STATE');

export const resetMenuItemState = createAction('RESET_MENU_ITEM_STATE');
