const login = {
  'auth.login.hetmet': 'Login page',
  'auth.login.title': 'Login page',
  'auth.login.sub-title-1': 'Login with your credential to',
  'auth.login.sub-title-2': 'access your account!',
  'auth.login.btn-login': 'Log in',
  'auth.login.err-msg': 'Your email or password is wrong. Please check again.',
};

const usAuthLoginLangLib = {
  ...login,
};

export default usAuthLoginLangLib;
