export const calcMinutes = (n) => {
  const num = n;
  const hours = (num / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  if (rhours === 0) {
    return `${rminutes} minutes`;
  }
  if (rhours !== 0 && rminutes !== 0) {
    return `${rhours} hours ${rminutes} minute`;
  }
  return `${rhours} hours`;
};
