// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllPostsRequest: false,
  isGetAllPostsSuccess: false,
  isGetAllPostsFailure: false,
  getAllPostsState: {},
  //
  isCreatePostRequest: false,
  isCreatePostSuccess: false,
  isCreatePostFailure: false,
  createPostPayloadState: {},
  //
  isUpdatePostRequest: false,
  isUpdatePostSuccess: false,
  isUpdatePostFailure: false,
  //
  isDeletePostRequest: false,
  isDeletePostSuccess: false,
  isDeletePostFailure: false,
  //
  isGetGroupsMetaDivRequest: false,
  isGetGroupsMetaDivSuccess: false,
  isGetGroupsMetaDivFailure: false,
  getGroupsMetaDivState: {},
  //
  isGetTrademarksDivRequest: false,
  isGetTrademarksDivSuccess: false,
  isGetTrademarksDivFailure: false,
  getTrademarksDivState: {},
  //
  isGetRelateIdsDivRequest: false,
  isGetRelateIdsDivSuccess: false,
  isGetRelateIdsDivFailure: false,
  getRelateIdsDivState: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all posts
    [Actions.getAllPostsRequest]: (state) => ({
      ...state,
      isGetAllPostsRequest: true,
      isGetAllPostsSuccess: false,
      isGetAllPostsFailure: false,
    }),
    [Actions.getAllPostsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllPostsRequest: false,
      isGetAllPostsSuccess: true,
      isGetAllPostsFailure: false,
      getAllPostsState: payload,
    }),
    [Actions.getAllPostsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllPostsRequest: false,
      isGetAllPostsSuccess: false,
      isGetAllPostsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create post
    [Actions.createPostRequest]: (state) => ({
      ...state,
      isCreatePostRequest: true,
      isCreatePostSuccess: false,
      isCreatePostFailure: false,
    }),
    [Actions.createPostSuccess]: (state, { payload }) => ({
      ...state,
      isCreatePostRequest: false,
      isCreatePostSuccess: true,
      isCreatePostFailure: false,
      createPostPayloadState: payload,
    }),
    [Actions.createPostFailure]: (state, { payload }) => ({
      ...state,
      isCreatePostRequest: false,
      isCreatePostSuccess: false,
      isCreatePostFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreatePostState]: (state) => ({
      ...state,
      isCreatePostRequest: false,
      isCreatePostSuccess: false,
      isCreatePostFailure: false,
      createPostPayloadState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Update post
    [Actions.updatePostRequest]: (state) => ({
      ...state,
      isUpdatePostRequest: true,
      isUpdatePostSuccess: false,
      isUpdatePostFailure: false,
    }),
    [Actions.updatePostSuccess]: (state) => ({
      ...state,
      isUpdatePostRequest: false,
      isUpdatePostSuccess: true,
      isUpdatePostFailure: false,
    }),
    [Actions.updatePostFailure]: (state, { payload }) => ({
      ...state,
      isUpdatePostRequest: false,
      isUpdatePostSuccess: false,
      isUpdatePostFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdatePostState]: (state) => ({
      ...state,
      isUpdatePostRequest: false,
      isUpdatePostSuccess: false,
      isUpdatePostFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete post
    [Actions.deletePostRequest]: (state) => ({
      ...state,
      isDeletePostRequest: true,
      isDeletePostSuccess: false,
      isDeletePostFailure: false,
    }),
    [Actions.deletePostSuccess]: (state) => ({
      ...state,
      isDeletePostRequest: false,
      isDeletePostSuccess: true,
      isDeletePostFailure: false,
    }),
    [Actions.deletePostFailure]: (state, { payload }) => ({
      ...state,
      isDeletePostRequest: false,
      isDeletePostSuccess: false,
      isDeletePostFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeletePostState]: (state) => ({
      ...state,
      isDeletePostRequest: false,
      isDeletePostSuccess: false,
      isDeletePostFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Get groups meta div
    [Actions.getGroupsMetaDivRequest]: (state) => ({
      ...state,
      isGetGroupsMetaDivRequest: true,
      isGetGroupsMetaDivSuccess: false,
      isGetGroupsMetaDivFailure: false,
    }),
    [Actions.getGroupsMetaDivSuccess]: (state, { payload }) => ({
      ...state,
      isGetGroupsMetaDivRequest: false,
      isGetGroupsMetaDivSuccess: true,
      isGetGroupsMetaDivFailure: false,
      getGroupsMetaDivState: payload,
    }),
    [Actions.getGroupsMetaDivFailure]: (state, { payload }) => ({
      ...state,
      isGetGroupsMetaDivRequest: false,
      isGetGroupsMetaDivSuccess: false,
      isGetGroupsMetaDivFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get trademarks div
    [Actions.getTrademarksDivRequest]: (state) => ({
      ...state,
      isGetTrademarksDivRequest: true,
      isGetTrademarksDivSuccess: false,
      isGetTrademarksDivFailure: false,
    }),
    [Actions.getTrademarksDivSuccess]: (state, { payload }) => ({
      ...state,
      isGetTrademarksDivRequest: false,
      isGetTrademarksDivSuccess: true,
      isGetTrademarksDivFailure: false,
      getTrademarksDivState: payload,
    }),
    [Actions.getTrademarksDivFailure]: (state, { payload }) => ({
      ...state,
      isGetTrademarksDivRequest: false,
      isGetTrademarksDivSuccess: false,
      isGetTrademarksDivFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get all posts
    [Actions.getRelateIdsDivRequest]: (state) => ({
      ...state,
      isGetRelateIdsDivRequest: true,
      isGetRelateIdsDivSuccess: false,
      isGetRelateIdsDivFailure: false,
    }),
    [Actions.getRelateIdsDivSuccess]: (state, { payload }) => ({
      ...state,
      isGetRelateIdsDivRequest: false,
      isGetRelateIdsDivSuccess: true,
      isGetRelateIdsDivFailure: false,
      getRelateIdsDivState: payload,
    }),
    [Actions.getRelateIdsDivFailure]: (state, { payload }) => ({
      ...state,
      isGetRelateIdsDivRequest: false,
      isGetRelateIdsDivSuccess: false,
      isGetRelateIdsDivFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetRelateIdsDivState]: (state) => ({
      ...state,
      isGetRelateIdsDivRequest: false,
      isGetRelateIdsDivSuccess: false,
      isGetRelateIdsDivFailure: false,
      getRelateIdsDivState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetPostState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
