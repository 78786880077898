/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllKiotvietPrices({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/kiotviet/prices?${payload}`)
        : () => axiosMicro.get('/kiotviet/prices', { params: payload }),
    );
    yield put(Actions.getAllKiotvietPricesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllKiotvietPricesFailure(messages));
    }
  }
}

function* createKiotvietPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/kiotviet/prices', payload));
    yield put(Actions.createKiotvietPriceSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createKiotvietPriceFailure(messages));
    }
  }
}

function* updateKiotvietPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/kiotviet/prices/${payload.id}`, payload.body));
    yield put(Actions.updateKiotvietPriceSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateKiotvietPriceFailure(messages));
    }
  }
}

function* deleteKiotvietPrice({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/kiotviet/prices/${payload}`));
    yield put(Actions.deleteKiotvietPriceSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteKiotvietPriceFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllKiotvietPricesRequest, getAllKiotvietPrices);
  yield takeLatest(Actions.createKiotvietPriceRequest, createKiotvietPrice);
  yield takeLatest(Actions.updateKiotvietPriceRequest, updateKiotvietPrice);
  yield takeLatest(Actions.deleteKiotvietPriceRequest, deleteKiotvietPrice);
}
