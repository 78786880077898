// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllRatingsRequest = createAction('GET_ALL_RATINGS_REQUEST');
export const getAllRatingsSuccess = createAction('GET_ALL_RATINGS_SUCCESS');
export const getAllRatingsFailure = createAction('GET_ALL_RATINGS_FAILURE');

export const createRatingRequest = createAction('CREATE_RATING_REQUEST');
export const createRatingSuccess = createAction('CREATE_RATING_SUCCESS');
export const createRatingFailure = createAction('CREATE_RATING_FAILURE');
export const resetCreateRatingState = createAction('RESET_CREATE_RATING_STATE');

export const deleteRatingRequest = createAction('DELETE_RATING_REQUEST');
export const deleteRatingSuccess = createAction('DELETE_RATING_SUCCESS');
export const deleteRatingFailure = createAction('DELETE_RATING_FAILURE');
export const resetDeleteRatingState = createAction('RESET_DELETE_RATING_STATE');

export const resetRatingState = createAction('RESET_RATING_STATE');
