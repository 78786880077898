const notFound = {
  'other.not-found.hetmet': 'Page Not Found',
  'other.not-found.title': 'Sorry ... it seems that the error has occurred!',
  'other.not-found.code-title': 'Error code',
  'other.not-found.btn-back': 'Back to home page',
};

const usOtherNotFoundLangLib = {
  ...notFound,
};

export default usOtherNotFoundLangLib;
