const settings = {};

const general = {
  'admin.settings.general.hetmet': 'Config Settings - Settings',
  'admin.settings.general.breadcrumb.heading': 'Config Settings',
  'admin.settings.general.breadcrumb.parent': 'Settings',
  'admin.settings.general.title.email_receive_notification': 'Email receive notification',
  'admin.settings.general.title.smtp-option': 'Smtp option',
  'admin.settings.general.title.bank-account': 'Bank Account',
  'admin.settings.general.title.bank-account.code': 'Code',
  'admin.settings.general.title.bank-account.account': 'Account',
  'admin.settings.general.title.bank-account.discount': 'Discount(%)',
  'admin.settings.general.title.bank-account.name': 'Bank',
  'admin.settings.general.title.bank-account.branch': 'Branch',
  'admin.settings.general.title.bank-account.owner': 'Owner',
  'admin.settings.general.title.dynamic-option': 'Dynamic option',
  'admin.settings.general.title.dynamic-option.add': 'Add dynamic option',
  'admin.settings.general.title.smtp_email': 'Email',
  'admin.settings.general.title.smtp_password': 'Password',
  'admin.settings.general.title.default-option': 'The default options',
  'admin.settings.general.title.custom-option': 'The personal options',
  'admin.settings.general.title.footer_script_code': 'Code tracker',
  'admin.settings.general.title.header_script_code': 'Header include',
  'admin.settings.general.title.social-option': 'The social options',
  'admin.settings.general.title.script-option': 'The head & tracker options',
  'admin.settings.general.title.email-option': 'The email receive notification option',
  'admin.settings.general.title.email-option.role': 'Emails are separated by markers',
  'admin.settings.general.title.company-option': 'The company options',
  'admin.settings.general.title.copyright-option': 'The copyright options',
  'admin.settings.general.title.custom-option.btn-add': 'Add personal option',
  'admin.settings.general.title.custom-option.placeholder.key': 'Key',
  'admin.settings.general.title.custom-option.placeholder.title': 'Title',
  'admin.settings.general.title.custom-option.placeholder.value': 'Value',
  'admin.settings.general.title.custom-option.footer.value': 'Footer code',
  'admin.settings.general.title.custom-option.header_script_code.value': 'Header code',
  'admin.settings.general.default-option.label.logo-light': 'Logo Light',
  'admin.settings.general.default-option.label.logo-dark': 'Logo Dark',
  'admin.settings.general.default-option.label.site_logo': 'Site Logo',
  'admin.settings.general.default-option.label.site_favicon': 'Site Favicon',
  'admin.settings.general.default-option.label.site_description': 'Site Description',
  'admin.settings.general.default-option.label.site_email': 'Site Email',
  'admin.settings.general.default-option.label.site_hotline': 'Site Hotline',
  'admin.settings.general.default-option.label.site_keyword': 'Site Keyword',
  'admin.settings.general.default-option.placeholder.site_keyword': 'Add keyword',
  'admin.settings.general.default-option.label.site_name': 'Site Name',
  'admin.settings.general.default-option.label.footer_script_code': 'Script code',
  'admin.settings.general.default-option.btn.upload-image': 'Upload new images',
  'admin.settings.general.title.product-option': 'Product options',
  'admin.settings.general.product-option.label.code-product': 'Syntax Code Product',
  'admin.settings.general.product-option.label.code-order': 'Syntax Code Order',
  'admin.settings.general.product-option.label.unit-money': 'Unit Money',
  'admin.settings.general.product-option.error-msg.syntax_code_product': 'Do not leave this field blank!',
  'admin.settings.general.product-option.error-msg.syntax_code_order_main': 'Do not leave this field blank!',
  'admin.settings.general.product-option.error-msg.syntax_code_order_footer': 'Do not leave this field blank!',
  'admin.settings.general.product-option.label.available-tags': 'Available tags',
  'admin.settings.general.noti.create-success.title': 'Add successful personal options',
  'admin.settings.general.noti.create-failure.title': 'Add personal failure options',
  'admin.settings.general.noti.create-failure.title-2': 'Your key has existed!Please enter another lock.',
  'admin.settings.general.noti.edit-success.title': 'Modify config options successfully',
  'admin.settings.general.noti.edit-failure.title': 'Modify config defeat options',
  'admin.settings.general.noti.delete-success.title': 'Delete successful personal options',
  'admin.settings.general.noti.delete-failure.title': 'Delete personal failure options',
  'admin.settings.general.modal.confirm-save-change.title': 'Confirmation of change',
  'admin.settings.general.modal.confirm-save-change.sub-title': 'Are you sure to change this information?',
  'admin.settings.custom.modal.delete.title': 'Confirm delete this personal option!',
  'admin.settings.custom.modal.delete.description': 'You definitely want to delete this personal option?',
  'admin.settings.custom.modal.create.title': 'Add personal options',
  'admin.settings.custom.modal.create.label.key': 'Lock',
  'admin.settings.custom.modal.create.label.key.description': 'The key only includes ordinary and numerical characters, not using Vietnamese.',
  'admin.settings.custom.modal.create.label.title': 'Title',
  'admin.settings.custom.modal.create.label.value': 'Content',
  'admin.settings.custom.modal.create.error-msg.key.required': 'Please enter the lock!',
  'admin.settings.custom.modal.create.error-msg.key.regex': 'The lock is invalid!',
  'admin.settings.custom.modal.create.error-msg.title.required': 'Please enter the title!',
  'admin.settings.custom.modal.create.error-msg.title.min-2': 'At least 2 characters!',
  'admin.settings.custom.modal.create.error-msg.title.max-100': 'Maximum of 100 characters!',
  'admin.settings.custom.modal.create.error-msg.value.required': 'Please enter the content!',
  'admin.settings.redirect.col-left.label.destination': 'End point',
  'admin.settings.redirect.col-left.label.source': 'Source',
  'admin.settings.redirect.col-left.label.code': 'Code redirect',
  'admin.settings.error-msg-input.source-require': 'Please enter the first point',
  'admin.settings.error-msg-input.destination-require': 'Please enter the destination',
  'admin.settings.redirect.code': 'The default redirect code will be 301 if you do not fill in',
  'admin.settings.redirect.noti.create-setting-redirect.title.success': 'Create redirect successfully',
  'admin.settings.redirect.noti.update-setting-redirect.title.success': 'Update redirect successfully',
  'admin.settings.redirect.modal.details.title': 'Detail redirect',
  'admin.settings.redirect.modal.update.title': 'Update redirect',
  'admin.settings.redirect.modal.delete.title': 'Confirmation of delete',
  'admin.settings.redirect.modal.delete.description': 'Are you sure to delete this redirect',
  'admin.settings.redirect.hetmet': 'List setting redirect',
  'admin.settings.redirect.noti.delete-setting-redirect.title.success': 'Delete successful this redirect',
  'admin.settings.redirect.noti.delete-setting-redirect.title.failure': 'Delete redirect failure ',
};

const custom = {
  'admin.settings.custom.hetmet': 'Personal options - Installation',
  'admin.settings.custom.breadcrumb.heading': 'Personal options',
  'admin.settings.custom.breadcrumb.parent': 'Setting',
};

const usAdminSettingsLangLib = {
  ...settings,
  ...general,
  ...custom,
};

export default usAdminSettingsLangLib;
