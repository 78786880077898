export const routesAdmin = {
  //
  dashboard1: '/',
  dashboard2: '/dashboard',
  home: '/dashboard/home',
  //
  postsConst: '/posts',
  allPostsConst: '/posts/all-posts',
  addNewPostConst: '/posts/add-new-post',
  editPostConst: '/posts/add-new-post/typeID?post=postID&action=edit',
  postCategoryConst: '/posts/post-category',
  postDataGroupConst: '/posts/data-group',
  postDataFieldConst: '/posts/data-group/typeID/data-field/groupID',
  postArticleGroupConst: '/posts/article-group',
  productTrademarkConst: '/posts/trademark-group',
  productDiscountConst: '/posts/product/discount',
  productOrderConst: '/posts/product/order',
  posts: '/posts/:typeID',
  allPosts: '/posts/all-posts/:typeID',
  addNewPost: '/posts/add-new-post/:typeID',
  postCategory: '/posts/post-category/:typeID',
  postDataGroup: '/posts/data-group/:typeID',
  postDataField: '/posts/data-group/:typeID/data-field/:groupID',
  postArticleGroup: '/posts/article-group/:typeID',
  productTrademark: '/posts/trademark-group/:typeID',
  productDiscount: '/posts/product/discount/:typeID',
  productOrder: '/posts/product/order/:typeID',
  //
  postType: '/post-type',
  postTypeManager: '/post-type/manager',
  //
  comment: '/comment',
  commentManager: '/comment/manager',
  //
  feedback: '/feedback',
  feedbackManager: '/feedback/manager',
  //
  rating: '/rating',
  ratingManager: '/rating/manager',
  //
  seo: '/seo',
  seoManager: '/seo/manager',
  //
  agencies: '/agencies',
  agenciesManager: '/agencies/manager',
  //
  warehouse: '/warehouse',
  warehouseManager: '/warehouse/manager',
  warehouseChange: '/warehouse/change',
  supplier: '/warehouse/supplier',
  //
  media: '/media',
  mediaLibrary: '/media/media-library',
  //
  users: '/users',
  usersList: '/users/list',
  userClients: '/users/client',
  usersRole: '/users/role',
  usersPermissions: '/users/permissions',
  //
  settings: '/settings',
  settingGeneral: '/settings/general',
  settingScript: '/settings/script',
  settingCompany: '/settings/company',
  settingCopyright: '/settings/copyright',
  settingEmail: '/settings/email',
  settingCustom: '/settings/custom',
  settingRedirect: '/settings/redirect',
  settingSMTP: '/settings/SMTP',
  settingDynamic: '/settings/dynamic',
  settingMenu: '/settings/menu',
  settingSlider: '/settings/slider',
  settingBankAccount: '/settings/bank-account',
  //
  appearance: '/appearance',
  appearanceMenu: '/appearance/menu',
  appearanceSlider: '/appearance/slider',
  //
  widgets: '/widgets',
  widgetsManage: '/widgets/manager',
  widgetsConst: '/widgets',
  widget: '/widgets/:type',
  //
  kiotviet: '/kiotviet',
  kiotvietCustomPrice: '/kiotviet/custom-price',
  kiotvietProduct: '/kiotviet/product',
  kiotvietCategory: '/kiotviet/category',
  kiotvietCustomCategoryHot: '/kiotviet/custom-category-hot',
};
