const price = {
  'admin.kiotviet.price.noti.update.success': 'Cập nhật thông tin thành công!',
  'admin.kiotviet.price.noti.update.failure': 'Cập nhật thông tin thất bại! Vui lòng thử lại sau.',
  'admin.kiotviet.price.hetmet': 'Điều chỉnh thông tin sản phẩm',
  'admin.kiotviet.price.breadcrumb.heading': 'Điều chỉnh thông tin sản phẩm',
  'admin.kiotviet.price.breadcrumb.parent': 'KiotViet',
  'admin.kiotviet.price.btn.add': 'Điều chỉnh thông tin sản phẩm mới',
  'admin.kiotviet.price.col.code': 'Mã sản phẩm',
  'admin.kiotviet.price.col.name': 'Tên sản phẩm',
  'admin.kiotviet.price.col.price': 'Giá điều chỉnh',
  'admin.kiotviet.price.col.type': 'Loại',
  'admin.kiotviet.price.col.category': 'Danh mục',
  'admin.kiotviet.price.noti.create-price.success': 'Thêm điều chỉnh thông tin sản phẩm mới thành công!',
  'admin.kiotviet.price.noti.create-price.failure': 'Thêm điều chỉnh thông tin sản phẩm mới thất bại! Vui lòng thử lại sau.',
  'admin.kiotviet.price.noti.update-price.success': 'Cập nhật điều chỉnh thông tin sản phẩm mới thành công!',
  'admin.kiotviet.price.noti.update-price.failure': 'Cập nhật điều chỉnh thông tin sản phẩm mới thất bại! Vui lòng thử lại sau.',
  'admin.kiotviet.price.noti.delete-price.success': 'Xóa điều chỉnh thông tin sản phẩm mới thành công',
  'admin.kiotviet.price.noti.delete-price.failure': 'Xóa điều chỉnh thông tin sản phẩm mới thất bại! Vui lòng thử lại sau.',
  'admin.kiotviet.price.modal.action.title.create': 'Thêm mới',
  'admin.kiotviet.price.modal.action.title.update': 'Cập nhật',
  'admin.kiotviet.price.modal.action.title.details': 'Chi tiết',
  'admin.kiotviet.price.modal.action.btn.create': 'Thêm',
  'admin.kiotviet.price.modal.action.btn.update': 'Cập nhật',
  'admin.kiotviet.price.modal.action.type.label': 'Điều chỉnh thông tin sản phẩm cho',
  'admin.kiotviet.price.modal.action.type.category.label': 'Danh mục',
  'admin.kiotviet.price.modal.action.type.product.label': 'Sản phẩm',
  'admin.kiotviet.price.modal.action.category.label': 'Chọn danh mục',
  'admin.kiotviet.price.modal.action.product.label': 'Chọn sản phẩm',
  'admin.kiotviet.price.modal.action.number.label': 'Nhập giá',
  'admin.kiotviet.price.modal.action.unit.label': 'Đơn vị',
  'admin.kiotviet.price.modal.action.unit.percent.label': 'Phần trăm',
  'admin.kiotviet.price.modal.action.unit.money.label': 'Tiền tệ',
  'admin.kiotviet.price.modal.delete.title': 'Xác nhận xóa giá điều chỉnh!',
  'admin.kiotviet.price.modal.delete.description': 'Bạn có chắc chắn muốn xóa giá điều chỉnh này?',
  'admin.kiotviet.price.error-msg.required': 'Vui lòng điền trường này!',
};

const product = {
  'admin.kiotviet.product.hetmet': 'Điều chỉnh thông tin sản phẩm',
  'admin.kiotviet.product.breadcrumb.heading': 'Điều chỉnh thông tin sản phẩm',
  'admin.kiotviet.product.breadcrumb.parent': 'KiotViet',
  'admin.kiotviet.product.btn.add': 'Đồng bộ sản phẩm từ Kiotviet',
  'admin.kiotviet.product.col.code': 'Mã sản phẩm',
  'admin.kiotviet.product.col.name': 'Tên sản phẩm',
  'admin.kiotviet.product.col.product': 'Giá điều chỉnh',
  'admin.kiotviet.product.col.type': 'Loại',
  'admin.kiotviet.product.col.image': 'Hình ảnh',
  'admin.kiotviet.product.col.price': 'Giá',
  'admin.kiotviet.product.col.unit': 'Đơn vị',
  'admin.kiotviet.product.col.category': 'Danh mục',
  'admin.kiotviet.product.noti.update-product.success': 'Cập nhật điều chỉnh thông tin sản phẩm mới thành công!',
  'admin.kiotviet.product.noti.update-product.failure': 'Cập nhật điều chỉnh thông tin sản phẩm mới thất bại! Vui lòng thử lại sau.',
  'admin.kiotviet.product.noti.sync.success': 'Đồng bộ thành công',
  'admin.kiotviet.product.noti.sync.fail': 'Không thể thực hiện đồng bộ',
  'admin.kiotviet.product.modal.action.title.create': 'Thêm mới',
  'admin.kiotviet.product.modal.action.title.update': 'Cập nhật',
  'admin.kiotviet.product.modal.action.title.details': 'Chi tiết',
  'admin.kiotviet.product.modal.action.btn.create': 'Thêm',
  'admin.kiotviet.product.modal.action.btn.update': 'Cập nhật',
  'admin.kiotviet.product.modal.action.type.label': 'Điều chỉnh thông tin sản phẩm cho',
  'admin.kiotviet.product.modal.action.type.category.label': 'Danh mục',
  'admin.kiotviet.product.modal.action.type.product.label': 'Sản phẩm',
  'admin.kiotviet.product.modal.action.category.label': 'Chọn danh mục',
  'admin.kiotviet.product.modal.action.product.label': 'Chọn sản phẩm',
  'admin.kiotviet.product.modal.action.number.label': 'Nhập giá',
  'admin.kiotviet.product.modal.action.unit.label': 'Đơn vị',
  'admin.kiotviet.product.modal.action.unit.percent.label': 'Phần trăm',
  'admin.kiotviet.product.modal.action.unit.money.label': 'Tiền tệ',
  'admin.kiotviet.product.modal.sync.warning': 'Để tránh xảy ra lỗi, vui lòng không đóng trang trong quá trình đồng bộ',
  'admin.kiotviet.product.modal.sync.btn-start': 'Bắt đầu đồng bộ',
  'admin.kiotviet.product.modal.sync.btn-starting': 'Đang bắt đầu',
  'admin.kiotviet.product.modal.sync.btn-close': 'Đóng',
  'admin.kiotviet.product.modal.sync.product': 'sản phẩm',
  'admin.kiotviet.product.modal.sync.running': 'Đang đồng bộ',
  'admin.kiotviet.product.search.branch': 'Tất cả chi nhánh',
  'admin.kiotviet.product.search.active.all': 'Tất cả sản phẩm',
  'admin.kiotviet.product.search.active.true': 'Đang kinh doanh',
  'admin.kiotviet.product.search.active.false': 'Ngừng kinh doanh',
  'admin.kiotviet.product.error-msg.required': 'Vui lòng điền trường này!',
};

const col = {
  'admin.kiotviet.col.category-name': 'Tên danh mục',
  'admin.kiotviet.col.category-id': 'Id danh mục',
  'admin.kiotviet.col.url': 'URL',
  'admin.kiotviet.col.children': 'Danh mục con',
  'admin.kiotviet.col.parent': 'Danh mục cha',
};

const vnAdminKiotvietLangLib = {
  ...price,
  ...product,
  ...col,
};

export default vnAdminKiotvietLangLib;
