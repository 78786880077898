const agency = {
  'admin.agency.menus.hetmet': 'Đại lý - Quản lý đại lý',
  'admin.agency.menus.breadcrumb.heading': 'Danh sách đại lý',
  'admin.agency.menus.breadcrumb.parent': 'Quản lý đại lý',
  'admin.agency.btn.add': 'Thêm đại lý',
  'admin.agency.noti.create-agency.success': 'Thêm đại lý mới thành công!',
  'admin.agency.noti.create-agency.failure': 'Thêm đại lý mới không thành công! Vui lòng thử lại sau',
  'admin.agency.noti.update-agency.success': 'Cập nhật đại lý thành công!',
  'admin.agency.noti.update-agency.failure': 'Thêm đại lý mới không thành công! Vui lòng thử lại sau',
  'admin.agency.noti.delete-agency.success': 'Xoá đại lý thành công!',
  'admin.agency.noti.delete-agency.failure': 'Thêm đại lý mới không thành công! Vui lòng thử lại sau',
  'admin.agency.noti.attach-users-to-agency.success': 'Cập nhật người dùng trong Đại lý thành công!',
  'admin.agency.noti.attach-users-to-agency.failure': 'Cập nhật người dùng trong Đại lý không thành công! Vui lòng thử lại sau.',
  'admin.agency.noti.detach-users-to-agency.success': 'Cập nhật người dùng trong Đại lý thành công!',
  'admin.agency.noti.detach-users-to-agency.failure': 'Cập nhật người dùng trong Đại lý không thành công! Vui lòng thử lại sau.',
  'admin.agency.column.soft': 'Thứ tự',
  'admin.agency.column.name': 'Tên đại lý',
  'admin.agency.column.description': 'Mô tả',
  'admin.agency.modal.delete.title': 'Xác nhận xoá đại lý',
  'admin.agency.modal.delete.description': 'Bạn chắc chắn muốn xoá đại lý này?',
  'admin.agency.error-msg.name': 'Vui lòng nhập tên đại lý!',
  'admin.agency.modal.action.title.create': 'Thêm đại lý mới',
  'admin.agency.modal.action.title.update': 'Cập nhật đại lý',
  'admin.agency.modal.action.title.details': 'Thông tin đại lý',
  'admin.agency.modal.action.btn.create': 'Thêm mới',
  'admin.agency.modal.action.btn.update': 'Cập nhật',
  'admin.agency.modal.action.label.name': 'Tên đại lý',
  'admin.agency.modal.action.label.description': 'Mô tả',
  'admin.agency.modal.action.label.users': 'Chọn người dùng cho Đại lý',
  'admin.agency.modal.action.placeholder.users': 'Chọn người dùng',
};

const vnAdminAgencyLangLib = {
  ...agency,
};

export default vnAdminAgencyLangLib;
