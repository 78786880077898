const user = {};

const member = {
  'admin.user.member.hetmet': 'Members - All users',
  'admin.user.member.breadcrumb.heading': 'All users',
  'admin.user.member.breadcrumb.parent': 'Members',
  'admin.user.member.btn.add-new': 'Add new users',
  'admin.user.member.noti.create-member.title.success': 'Add new members successful!',
  'admin.user.member.noti.create-member.title.failure': 'Adding new members failed!Please try again later.',
  'admin.user.member.noti.update-member.title.success': 'Successful members update!',
  'admin.user.member.noti.update-member.title.failure': 'Membership updates are unsuccessful!Please try again later.',
  'admin.user.member.noti.delete-member.title.success': 'Delete members successfully!',
  'admin.user.member.noti.delete-member.title.failure': 'Delete members unsuccessful!Please try again later.',
  'admin.user.member.noti.reset-password-member.title.success': 'Retite the password of the member successfully!',
  'admin.user.member.noti.reset-password-member.title.failure': "Returning the member's password failed!Please try again later.",
  'admin.user.member.modal.add.title': 'Add new users',
  'admin.user.member.modal.update.title': 'Update user information',
  'admin.user.member.modal.detail.title': 'User details',
  'admin.user.member.modal.delete.title': 'Confirm to delete members!',
  'admin.user.member.modal.delete.sub-title': 'You definitely want to delete this member?',
  'admin.user.member.modal.reset-password.title': 'Confirm reset password for this member!',
  'admin.user.member.modal.reset-password.label': 'Choose a new password:',
  'admin.user.member.modal.reset-password.input-1': 'Reset the default password',
  'admin.user.member.modal.reset-password.input-2': 'Send to Gmail',
  'admin.user.member.modal.reset-password.noti-1': 'A new password:',
};

const clients = {
  'admin.user.clients.hetmet': 'Members - All clients',
  'admin.user.clients.breadcrumb.heading': 'All clients',
  'admin.user.clients.breadcrumb.parent': 'Members',
  'admin.user.clients.btn.add-new': 'Add new client',
  'admin.user.clients.noti.create-clients.title.success': 'Add new client successful!',
  'admin.user.clients.noti.create-clients.title.failure': 'Adding new client failed! Please try again later.',
  'admin.user.clients.noti.update-clients.title.success': 'Successful client update!',
  'admin.user.clients.noti.update-clients.title.failure': 'Updates client is unsuccessful! Please try again later.',
  'admin.user.clients.noti.delete-clients.title.success': 'Delete client successfully!',
  'admin.user.clients.noti.delete-clients.title.failure': 'Delete client unsuccessful!Please try again later.',
  'admin.user.clients.noti.reset-password-clients.title.success': 'Retite the password of the client successfully!',
  'admin.user.clients.noti.reset-password-clients.title.failure': "Returning the client's password failed!Please try again later.",
  'admin.user.clients.modal.add.title': 'Add new client',
  'admin.user.clients.modal.update.title': 'Update client information',
  'admin.user.clients.modal.detail.title': 'Client details',
  'admin.user.clients.modal.delete.title': 'Confirm to delete client!',
  'admin.user.clients.modal.delete.sub-title': 'You definitely want to delete this client?',
  'admin.user.clients.modal.reset-password.title': 'Confirm reset password for this client!',
  'admin.user.clients.modal.reset-password.label': 'Choose a new password:',
  'admin.user.clients.modal.reset-password.input-1': 'Reset the default password',
  'admin.user.clients.modal.reset-password.input-2': 'Send to Gmail',
  'admin.user.clients.modal.reset-password.noti-1': 'A new password:',
};

const role = {
  'admin.user.role.hetmet': 'Members - Role',
  'admin.user.role.breadcrumb.heading': 'Role',
  'admin.user.role.breadcrumb.parent': 'Members',
  'admin.user.role.btn.add-new': 'Add a new role',
  'admin.user.role.noti.create-role.title.success': 'Add a new role successfully!',
  'admin.user.role.noti.create-role.title.failure': 'Adding a new failure!Please try again later.',
  'admin.user.role.noti.update-role.title.success': 'Update the role of success!',
  'admin.user.role.noti.update-role.title.failure': 'Update the role of unsuccessful!Please try again later.',
  'admin.user.role.noti.delete-role.title.success': 'Delete the role of success!',
  'admin.user.role.noti.delete-role.title.failure': 'Delete the role of unsuccessful!Please try again later.',
  'admin.user.role.modal.add.title': 'Add a new role',
  'admin.user.role.modal.update.title': 'Update the right for the role',
  'admin.user.role.modal.delete.title': 'Confirm the role of role!',
  'admin.user.role.modal.delete.sub-title': 'You definitely want to erase this role?',
};

const permissions = {
  'admin.user.permissions.hetmet': 'Members - Permissions',
  'admin.user.permissions.breadcrumb.heading': 'Permissions',
  'admin.user.permissions.breadcrumb.parent': 'Members',
  'admin.user.permissions.btn.add-new': 'Decentralization for users',
  'admin.user.permissions.noti.attach-role.title.success': 'Decentralize the user successfully!',
  'admin.user.permissions.noti.attach-role.title.failure': 'Decentralization for users failed!Please try again later.',
  'admin.user.permissions.noti.revoke-role.title.success': 'Delete the decentralization of users successfully!',
  'admin.user.permissions.noti.revoke-role.title.failure': 'Delete the decentralization of users failed!Please try again later.',
  'admin.user.permissions.modal.attach.title': 'Decentralization for users',
  'admin.user.permissions.modal.revoke.title': 'Confirm the deletion of user decentralization!',
  'admin.user.permissions.modal.revoke.sub-title': "You definitely want to erase this user's decentralization?",
};

const customer = {
  'customer.code': 'Customer code',
  'customer.name': 'Customer name',
  'customer.name-require': 'Please enter the customer name',
  'customer.name-min-length': 'At least 5 characters',
  'customer.name-max-length': 'Up to 300 characters',
  //
  'customer.phone': 'Phone',
  'customer.phone-require': 'Please enter the customer phone number',
  'customer.phone-valid': 'Phone number not valid',
  'customer.phone-length': 'Phone number must have 10 characters',

  //
  'customer.email-valid': 'Email is not correctly formatted',

  'customer.tax': 'Tax',
  'customer.tax.code': 'Tax code',
  'customer.tax.name': 'Name',
  'customer.tax.phone': 'Phone',
  'customer.tax.address': 'Address',
  'customer.tax.email': 'Email',
  'customer.isCompany': 'Customers are businesses?',
  'customer.isZns': 'ZNS',

  //
  'customer.address': 'Address',
  //
  'customer.note': 'Note',
  //
  'customer.type': 'Type customer',
  //
  'customer.bill': 'Invoice',
  'customer.bill.info': 'Billing information',
  //
  'customer.city': 'City',
  'customer.city-require': 'Please select the city',
  //
  'customer.district': 'District',
  'customer.district-require': 'Please select the district',
  //
  'customer.excel': 'Import from excel file',
  'customer.excel-require': 'Please upload file excel',
  //
  'customer.btn-add': 'Add customer',
  'customer.btn-edit': 'Edit customer',
  'customer.btn-update-info': 'Update recipient information',
  'customer.btn-save-info': 'Save customer information',
  'customer.btn-back': 'Back',
  'customer.confirm': 'Yes',
  'customer.cancel': 'Cancel',
  'customer.phone.text': 'Phone is text(Shopee)',
  'customer.add.store': 'Add store',
  'customer.add.tax': 'Add tax info',
  //
  'customer.confirm-add': 'Confirm add customer',
  'customer.confirm-sure-add': 'You will definitely add this customer?',
  'customer.confirm-edit': 'Confirm edit customer',
  'customer.confirm-sure-edit': 'You will definitely edit this customer?',
  'customer.confirm-delete': 'Confirm delete customer',
  'customer.confirm-sure-delete': 'You will definitely delete this customer?',
  'customer.confirm-delete-tax': 'Confirm delete customer tax',
  'customer.confirm-sure-delete-tax': 'You will definitely delete this customer tax?',
  //
  'user.position.add-confirm': 'Confirm additional positions',
  'user.position.add-confirm-sure': 'You will definitely add this position',
  'user.position.add-success': 'Added position successfully',
  'user.position.edit': 'Edit position',
  'user.position.edit-confirm': 'Confirm position correction',
  'user.position.edit-confirm-sure': 'You will definitely fix this position',
  'user.position.edit-success': 'Edited position successfully',
  'user.position.delete': 'Delete position',
  'user.position.delete-success': 'Deleted position successfully',
  'user.position.delete-confirm': 'Do you agree to delete this position?',
  'user.position.delete-confirm-sure': 'Confirm position deletion',
  'user.position.delete-confirm-sure-sub': 'You will definitely delete this position',

  'customer.history.nameProduct': 'Product name',
  'customer.history.size': 'Size',
  'customer.history.amount': 'Amount',
  'customer.history.price': 'Price',
  'customer.history.status': 'Status',
  'customer.ward-require': 'Please choose ward',
  'customer.ward': 'Ward',
  'customer.certificate.submitted': 'Submitted',
};

const usAdminUserLangLib = {
  ...user,
  ...member,
  ...clients,
  ...role,
  ...permissions,
  ...customer,
};

export default usAdminUserLangLib;
