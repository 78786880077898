import vnAdminDashboardLangLib from './dashboard';
import vnAdminPostsLangLib from './posts';
import vnAdminMediaLangLib from './media';
import vnAdminSettingsLangLib from './settings';
import vnAdminAppearanceLangLib from './appearance';
import vnAdminPostTypeLangLib from './postType';
import vnAdminFeedbackLangLib from './feedback';
import vnAdminSEOLangLib from './seo';
import vnAdminUserLangLib from './user';
import vnAdminWarehouseLangLib from './warehouse';
import vnAdminWidgetLangLib from './widget';
import vnAdminCommentLangLib from './comment';
import vnAdminRatingLangLib from './rating';
import vnAdminAgencyLangLib from './agency';
import usAdminKiotvietLangLib from './kiotviet';

const vnAdminLangLib = {
  ...vnAdminDashboardLangLib,
  ...vnAdminPostsLangLib,
  ...vnAdminMediaLangLib,
  ...vnAdminSettingsLangLib,
  ...vnAdminAppearanceLangLib,
  ...vnAdminPostTypeLangLib,
  ...vnAdminFeedbackLangLib,
  ...vnAdminSEOLangLib,
  ...vnAdminUserLangLib,
  ...vnAdminWarehouseLangLib,
  ...vnAdminWidgetLangLib,
  ...vnAdminCommentLangLib,
  ...vnAdminRatingLangLib,
  ...vnAdminAgencyLangLib,
  ...usAdminKiotvietLangLib,
};

export default vnAdminLangLib;
