/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllMembers({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/users?${payload.params}` : '/users'));
    yield put(Actions.getAllMembersSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllMembersFailure(messages));
    }
  }
}

function* addMember({ payload }) {
  try {
    if (payload.get('gdp')) {
      const payloadImage = new FormData();
      payloadImage.append('type', 'file');
      payloadImage.append('files[]', payload.get('gdp'));
      const gdp = yield call(() => axiosMicro.post('/files/upload', payloadImage));

      payload.delete('gdp');
      payload.append('certificat[phan_phoi_thuoc]', gdp.data.data[0]);
    }
    if (payload.get('gpp')) {
      const payloadImage = new FormData();
      payloadImage.append('type', 'file');
      payloadImage.append('files[]', payload.get('gpp'));
      const gpp = yield call(() => axiosMicro.post('/files/upload', payloadImage));

      payload.delete('gpp');
      payload.append('certificat[co_so_ban_le]', gpp.data.data[0]);
    }
    if (payload.get('pbc')) {
      const payloadImage = new FormData();
      payloadImage.append('type', 'file');
      payloadImage.append('files[]', payload.get('pbc'));
      const pbc = yield call(() => axiosMicro.post('/files/upload', payloadImage));

      payload.delete('pbc');
      payload.append('certificat[dieu_kien_kinh_doanh]', pbc.data.data[0]);
    }
    if (payload.get('erc')) {
      const payloadImage = new FormData();
      payloadImage.append('type', 'file');
      payloadImage.append('files[]', payload.get('erc'));
      const erc = yield call(() => axiosMicro.post('/files/upload', payloadImage));

      payload.delete('erc');
      payload.append('certificat[giay_phep_doanh_nghiep]', erc.data.data[0]);
    }

    const response = yield call(() => axiosMicro.post('/register', payload));
    yield put(Actions.addMemberSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addMemberFailure(messages));
    }
  }
}

function* updateMember({ payload }) {
  try {
    const form = new FormData();

    form.append('name', payload.name);
    form.append('email', payload.email);

    if (payload.gender) {
      form.append('gender', payload.gender);
    }
    if (payload.company) {
      form.append('company', payload.company);
    }
    if (payload.birthday) {
      form.append('birthday', payload.birthday);
    }
    if (payload.avatar) {
      form.append('avatar', payload.avatar);
    }
    if (payload.phone) {
      form.append('phone', payload.phone);
    }
    if (payload.zalo !== '') {
      form.append('zalo', payload.zalo);
    }
    if (payload.facebook !== '') {
      form.append('facebook', payload.facebook);
    }
    if (payload.address !== '') {
      form.append('address', payload.address);
    }
    if (payload.province_id) {
      form.append('province_id', payload.province_id);
    }
    if (payload.district_id) {
      form.append('district_id', payload.district_id);
    }
    if (payload.ward_id) {
      form.append('ward_id', payload.ward_id);
    }
    if (payload.status) {
      form.append('status', payload.status || 'disable');
    }

    if (payload.gdp) {
      if (payload.gdp === 'cancel') {
        form.append('certificat[phan_phoi_thuoc]', '');
      } else {
        const payloadImage = new FormData();
        payloadImage.append('type', 'file');
        payloadImage.append('files[]', payload.gdp);
        const gdp = yield call(() => axiosMicro.post('/files/upload', payloadImage));

        form.append('certificat[phan_phoi_thuoc]', gdp.data.data[0]);
      }
    }
    if (payload.gpp) {
      if (payload.gpp === 'cancel') {
        form.append('certificat[co_so_ban_le]', '');
      } else {
        const payloadImage = new FormData();
        payloadImage.append('type', 'file');
        payloadImage.append('files[]', payload.gpp);
        const gpp = yield call(() => axiosMicro.post('/files/upload', payloadImage));

        form.append('certificat[co_so_ban_le]', gpp.data.data[0]);
      }
    }
    if (payload.pbc) {
      if (payload.pbc === 'cancel') {
        form.append('certificat[dieu_kien_kinh_doanh]', '');
      } else {
        const payloadImage = new FormData();
        payloadImage.append('type', 'file');
        payloadImage.append('files[]', payload.pbc);
        const pbc = yield call(() => axiosMicro.post('/files/upload', payloadImage));

        form.append('certificat[dieu_kien_kinh_doanh]', pbc.data.data[0]);
      }
    }
    if (payload.erc) {
      if (payload.erc === 'cancel') {
        form.append('certificat[giay_phep_doanh_nghiep]', '');
      } else {
        const payloadImage = new FormData();
        payloadImage.append('type', 'file');
        payloadImage.append('files[]', payload.erc);
        const erc = yield call(() => axiosMicro.post('/files/upload', payloadImage));

        form.append('certificat[giay_phep_doanh_nghiep]', erc.data.data[0]);
      }
    }
    const response = yield call(() => axiosMicro.post(`/users/${payload.id}?_method=PATCH`, form));
    yield put(Actions.updateMemberSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateMemberFailure(messages));
    }
  }
}

function* deleteMember({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/users/${payload}`));
    yield put(Actions.deleteMemberSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteMemberFailure(messages));
    }
  }
}

function* getDetailMember({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/users/${payload}`));
    yield put(Actions.getDetailMemberSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getDetailMemberFailure(messages));
    }
  }
}

function* checkEmailExist({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/users?search=email:${payload}`));
    yield put(Actions.checkEmailExistSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.checkEmailExistFailure(messages));
    }
  }
}

function* resetPasswordMember({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/users/${payload.id}`, { password: payload.password }));
    yield put(Actions.resetPasswordMemberSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.resetPasswordMemberFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllMembersRequest, getAllMembers);
  yield takeLatest(Actions.addMemberRequest, addMember);
  yield takeLatest(Actions.updateMemberRequest, updateMember);
  yield takeLatest(Actions.deleteMemberRequest, deleteMember);
  yield takeLatest(Actions.getDetailMemberRequest, getDetailMember);
  yield takeLatest(Actions.checkEmailExistRequest, checkEmailExist);
  yield takeLatest(Actions.resetPasswordMemberRequest, resetPasswordMember);
}
