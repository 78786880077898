/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
import 'dropzone/dist/min/dropzone.min.css';
import 'react-image-lightbox/style.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-sortable-tree/style.css';

import './styles/vendors/bootstrap.min.css';
import './styles/vendors/bootstrap.rtl.only.min.css';

import { getCurrentColor } from './helpers/utils';

const color = getCurrentColor();

const render = () => {
  if (color === 'dark') {
    import('./styles/themes/satek.dark.bluenavy.scss').then(() => {
      require('./AppRenderer');
    });
  } else {
    import('./styles/themes/satek.light.blueyale.scss').then(() => {
      require('./AppRenderer');
    });
  }
};
render();
