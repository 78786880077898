/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllProductsKiotviet({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/kiotviet/products?${payload}`)
        : () => axiosMicro.get('/kiotviet/products', { params: payload }),
    );
    yield put(Actions.getAllProductsKiotvietSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllProductsKiotvietFailure(messages));
    }
  }
}

function* updateProductKiotviet({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/kiotviet/products/${payload.id}`, payload.body));
    yield put(Actions.updateProductKiotvietSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateProductKiotvietFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllProductsKiotvietRequest, getAllProductsKiotviet);
  yield takeLatest(Actions.updateProductKiotvietRequest, updateProductKiotviet);
}
