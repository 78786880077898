/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllGroups({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/groups?include=type,fields&${payload.params}` : '/groups?limit=0&include=type,fields',
    ));
    yield put(Actions.getAllGroupsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllGroupsFailure(messages));
    }
  }
}

function* createGroup({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/groups?include=type,fields', payload));
    yield put(Actions.createGroupSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createGroupFailure(messages));
    }
  }
}

function* updateGroup({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/groups/${payload.id}?include=type,fields`, payload.body));
    yield put(Actions.updateGroupSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateGroupFailure(messages));
    }
  }
}

function* deleteGroup({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/groups/${payload}`));
    yield put(Actions.deleteGroupSuccess(payload));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteGroupFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllGroupsRequest, getAllGroups);
  yield takeLatest(Actions.createGroupRequest, createGroup);
  yield takeLatest(Actions.updateGroupRequest, updateGroup);
  yield takeLatest(Actions.deleteGroupRequest, deleteGroup);
}
