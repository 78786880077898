// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  // Get All Kiotviet Price
  isFirstGetAllKiotvietPrices: false,
  isGetAllKiotvietPricesRequest: false,
  isGetAllKiotvietPricesSuccess: false,
  isGetAllKiotvietPricesFailure: false,
  getAllKiotvietPricesState: {},
  // Create Kiotviet Price
  isCreateKiotvietPriceRequest: false,
  isCreateKiotvietPriceSuccess: false,
  isCreateKiotvietPriceFailure: false,
  // Update Kiotviet Price
  isUpdateKiotvietPriceRequest: false,
  isUpdateKiotvietPriceSuccess: false,
  isUpdateKiotvietPriceFailure: false,
  // Delete Kiotviet Price
  isDeleteKiotvietPriceRequest: false,
  isDeleteKiotvietPriceSuccess: false,
  isDeleteKiotvietPriceFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Kiotviet Price
    [Actions.getAllKiotvietPricesRequest]: (state) => ({
      ...state,
      isGetAllKiotvietPricesRequest: true,
      isGetAllKiotvietPricesSuccess: false,
      isGetAllKiotvietPricesFailure: false,
    }),
    [Actions.getAllKiotvietPricesSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllKiotvietPrices: true,
      isGetAllKiotvietPricesRequest: false,
      isGetAllKiotvietPricesSuccess: true,
      isGetAllKiotvietPricesFailure: false,
      getAllKiotvietPricesState: payload,
    }),
    [Actions.getAllKiotvietPricesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllKiotvietPricesRequest: false,
      isGetAllKiotvietPricesSuccess: false,
      isGetAllKiotvietPricesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Kiotviet Price
    [Actions.createKiotvietPriceRequest]: (state) => ({
      ...state,
      isCreateKiotvietPriceRequest: true,
      isCreateKiotvietPriceSuccess: false,
      isCreateKiotvietPriceFailure: false,
    }),
    [Actions.createKiotvietPriceSuccess]: (state) => ({
      ...state,
      isCreateKiotvietPriceRequest: false,
      isCreateKiotvietPriceSuccess: true,
      isCreateKiotvietPriceFailure: false,
    }),
    [Actions.createKiotvietPriceFailure]: (state, { payload }) => ({
      ...state,
      isCreateKiotvietPriceRequest: false,
      isCreateKiotvietPriceSuccess: false,
      isCreateKiotvietPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateKiotvietPrice]: (state) => ({
      ...state,
      isCreateKiotvietPriceRequest: false,
      isCreateKiotvietPriceSuccess: false,
      isCreateKiotvietPriceFailure: false,
    }),
    // #endregion
    // #region : Update Kiotviet Price
    [Actions.updateKiotvietPriceRequest]: (state) => ({
      ...state,
      isUpdateKiotvietPriceRequest: true,
      isUpdateKiotvietPriceSuccess: false,
      isUpdateKiotvietPriceFailure: false,
    }),
    [Actions.updateKiotvietPriceSuccess]: (state) => ({
      ...state,
      isUpdateKiotvietPriceRequest: false,
      isUpdateKiotvietPriceSuccess: true,
      isUpdateKiotvietPriceFailure: false,
    }),
    [Actions.updateKiotvietPriceFailure]: (state, { payload }) => ({
      ...state,
      isUpdateKiotvietPriceRequest: false,
      isUpdateKiotvietPriceSuccess: false,
      isUpdateKiotvietPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateKiotvietPrice]: (state) => ({
      ...state,
      isUpdateKiotvietPriceRequest: false,
      isUpdateKiotvietPriceSuccess: false,
      isUpdateKiotvietPriceFailure: false,
    }),
    // #endregion
    // #region : Delete Kiotviet Price
    [Actions.deleteKiotvietPriceRequest]: (state) => ({
      ...state,
      isDeleteKiotvietPriceRequest: true,
      isDeleteKiotvietPriceSuccess: false,
      isDeleteKiotvietPriceFailure: false,
    }),
    [Actions.deleteKiotvietPriceSuccess]: (state) => ({
      ...state,
      isDeleteKiotvietPriceRequest: false,
      isDeleteKiotvietPriceSuccess: true,
      isDeleteKiotvietPriceFailure: false,
    }),
    [Actions.deleteKiotvietPriceFailure]: (state, { payload }) => ({
      ...state,
      isDeleteKiotvietPriceRequest: false,
      isDeleteKiotvietPriceSuccess: false,
      isDeleteKiotvietPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteKiotvietPrice]: (state) => ({
      ...state,
      isDeleteKiotvietPriceRequest: false,
      isDeleteKiotvietPriceSuccess: false,
      isDeleteKiotvietPriceFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetKiotvietPriceState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
