/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllFields({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/fields?${payload.params}` : '/fields?limit=0',
    ));
    yield put(Actions.getAllFieldsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllFieldsFailure(messages));
    }
  }
}

function* createField({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/fields', payload));
    yield put(Actions.createFieldSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createFieldFailure(messages));
    }
  }
}

function* updateField({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/fields/${payload.id}`, payload.body));
    yield put(Actions.updateFieldSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateFieldFailure(messages));
    }
  }
}

function* deleteField({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/fields/${payload}`));
    yield put(Actions.deleteFieldSuccess(payload));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteFieldFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllFieldsRequest, getAllFields);
  yield takeLatest(Actions.createFieldRequest, createField);
  yield takeLatest(Actions.updateFieldRequest, updateField);
  yield takeLatest(Actions.deleteFieldRequest, deleteField);
}
