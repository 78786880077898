// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetAllUsersHasRoleRequest: false,
  isGetAllUsersHasRoleSuccess: false,
  isGetAllUsersHasRoleFailure: false,
  getAllUsersHasRoleState: {},
  //
  isGetAllUsersNoRoleRequest: false,
  isGetAllUsersNoRoleSuccess: false,
  isGetAllUsersNoRoleFailure: false,
  getAllUsersNoRoleState: {},
  //
  isSyncUserRolesRequest: false,
  isSyncUserRolesSuccess: false,
  isSyncUserRolesFailure: false,
  //
  isRevokeRolesFromUserRequest: false,
  isRevokeRolesFromUserSuccess: false,
  isRevokeRolesFromUserFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all users has role
    [Actions.getAllUsersHasRoleRequest]: (state) => ({
      ...state,
      isGetAllUsersHasRoleRequest: true,
      isGetAllUsersHasRoleSuccess: false,
      isGetAllUsersHasRoleFailure: false,
    }),
    [Actions.getAllUsersHasRoleSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllUsersHasRoleRequest: false,
      isGetAllUsersHasRoleSuccess: true,
      isGetAllUsersHasRoleFailure: false,
      getAllUsersHasRoleState: payload,
    }),
    [Actions.getAllUsersHasRoleFailure]: (state, { payload }) => ({
      ...state,
      isGetAllUsersHasRoleRequest: false,
      isGetAllUsersHasRoleSuccess: false,
      isGetAllUsersHasRoleFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get all users no role
    [Actions.getAllUsersNoRoleRequest]: (state) => ({
      ...state,
      isGetAllUsersNoRoleRequest: true,
      isGetAllUsersNoRoleSuccess: false,
      isGetAllUsersNoRoleFailure: false,
    }),
    [Actions.getAllUsersNoRoleSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllUsersNoRoleRequest: false,
      isGetAllUsersNoRoleSuccess: true,
      isGetAllUsersNoRoleFailure: false,
      getAllUsersNoRoleState: payload,
    }),
    [Actions.getAllUsersNoRoleFailure]: (state, { payload }) => ({
      ...state,
      isGetAllUsersNoRoleRequest: false,
      isGetAllUsersNoRoleSuccess: false,
      isGetAllUsersNoRoleFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetAllUsersNoRoleState]: (state) => ({
      ...state,
      isGetAllUsersNoRoleRequest: false,
      isGetAllUsersNoRoleSuccess: false,
      isGetAllUsersNoRoleFailure: false,
      getAllUsersNoRoleState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Sync user roles
    [Actions.syncUserRolesRequest]: (state) => ({
      ...state,
      isSyncUserRolesRequest: true,
      isSyncUserRolesSuccess: false,
      isSyncUserRolesFailure: false,
    }),
    [Actions.syncUserRolesSuccess]: (state) => ({
      ...state,
      isSyncUserRolesRequest: false,
      isSyncUserRolesSuccess: true,
      isSyncUserRolesFailure: false,
    }),
    [Actions.syncUserRolesFailure]: (state, { payload }) => ({
      ...state,
      isSyncUserRolesRequest: false,
      isSyncUserRolesSuccess: false,
      isSyncUserRolesFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetSyncUserRolesState]: (state) => ({
      ...state,
      isSyncUserRolesRequest: false,
      isSyncUserRolesSuccess: false,
      isSyncUserRolesFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Revoke roles from user
    [Actions.revokeRolesFromUserRequest]: (state) => ({
      ...state,
      isRevokeRolesFromUserRequest: true,
      isRevokeRolesFromUserSuccess: false,
      isRevokeRolesFromUserFailure: false,
    }),
    [Actions.revokeRolesFromUserSuccess]: (state) => ({
      ...state,
      isRevokeRolesFromUserRequest: false,
      isRevokeRolesFromUserSuccess: true,
      isRevokeRolesFromUserFailure: false,
    }),
    [Actions.revokeRolesFromUserFailure]: (state, { payload }) => ({
      ...state,
      isRevokeRolesFromUserRequest: false,
      isRevokeRolesFromUserSuccess: false,
      isRevokeRolesFromUserFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetRevokeRolesFromUserState]: (state) => ({
      ...state,
      isRevokeRolesFromUserRequest: false,
      isRevokeRolesFromUserSuccess: false,
      isRevokeRolesFromUserFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetPermissionState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
