/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllPosts({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/posts?include=type,category,relates,chapters,prices&${payload.params}` : '/posts?include=type,category,relates,chapters,prices&limit=0',
    ));
    yield put(Actions.getAllPostsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllPostsFailure(messages));
    }
  }
}

function* createPost({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/posts', payload));
    yield put(Actions.createPostSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createPostFailure(messages));
    }
  }
}

function* updatePost({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/posts/${payload.id}`, payload.body));
    yield put(Actions.updatePostSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updatePostFailure(messages));
    }
  }
}

function* deletePost({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/posts/${payload}`));
    yield put(Actions.deletePostSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deletePostFailure(messages));
    }
  }
}

function* getGroupsMetaDiv({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/groups?include=type,fields&style=field&${payload.params}` : '/groups?include=type,fields&style=field&limit=0',
    ));
    yield put(Actions.getGroupsMetaDivSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getGroupsMetaDivFailure(messages));
    }
  }
}

function* getTrademarksDiv({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/groups?include=type,fields&style=trademark&${payload.params}` : '/groups?limit=0&include=type,fields&style=trademark',
    ));
    yield put(Actions.getTrademarksDivSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getTrademarksDivFailure(messages));
    }
  }
}

function* getRelateIdsDiv({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/posts?include=type,category,relates,chapters&${payload.params}` : '/posts?include=type,category,relates,chapters&limit=0',
    ));
    yield put(Actions.getRelateIdsDivSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getRelateIdsDivFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllPostsRequest, getAllPosts);
  yield takeLatest(Actions.createPostRequest, createPost);
  yield takeLatest(Actions.updatePostRequest, updatePost);
  yield takeLatest(Actions.deletePostRequest, deletePost);
  yield takeLatest(Actions.getGroupsMetaDivRequest, getGroupsMetaDiv);
  yield takeLatest(Actions.getTrademarksDivRequest, getTrademarksDiv);
  yield takeLatest(Actions.getRelateIdsDivRequest, getRelateIdsDiv);
}
