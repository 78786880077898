/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllMedia({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/media?${payload.params}` : '/media?limit=0'));
    yield put(Actions.getAllMediaSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllMediaFailure(messages));
    }
  }
}

function* createMedia({ payload }) {
  const form = new FormData();
  form.append('file', payload.file);
  try {
    const response = yield call(() => axiosMicro.post('/media', form));
    yield put(Actions.createMediaSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createMediaFailure(messages));
    }
  }
}

function* updateMedia({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/media/${payload.id}`, { name: payload.name }));
    yield put(Actions.updateMediaSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateMediaFailure(messages));
    }
  }
}

function* deleteMedia({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/media/${payload}`));
    yield put(Actions.deleteMediaSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteMediaFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllMediaRequest, getAllMedia);
  yield takeLatest(Actions.createMediaRequest, createMedia);
  yield takeLatest(Actions.updateMediaRequest, updateMedia);
  yield takeLatest(Actions.deleteMediaRequest, deleteMedia);
}
