/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getSettings() {
  try {
    const response = yield call(() => axiosMicro.get('/settings'));
    yield put(Actions.getSettingsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getSettingsFailure(messages));
    }
  }
}

// eslint-disable-next-line no-unused-vars
function* getAllSettings({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload.params ? `/settings/all?limit=0${payload.params}` : '/settings/all?limit=0'));
    // const response = yield call(() => axiosMicro.get('/settings/all?limit=0'));
    yield put(Actions.getAllSettingsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllSettingsFailure(messages));
    }
  }
}

function* createSetting({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/settings', payload));
    yield put(Actions.createSettingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createSettingFailure(messages));
    }
  }
}

function* saveSetting({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch('/settings', payload));
    yield put(Actions.saveSettingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.saveSettingFailure(messages));
    }
  }
}

function* updateBankSetting({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch('/settings/bank', payload));
    yield put(Actions.updateBankSettingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateBankSettingFailure(messages));
    }
  }
}

function* deleteSetting({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete('/settings', {
      params: payload,
    }));
    yield put(Actions.deleteSettingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteSettingFailure(messages));
    }
  }
}

function* getAllSettingRedirect({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/redirects', {
      params: payload,
    }));
    yield put(Actions.getAllSettingRedirectSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllSettingRedirectFailure(messages));
    }
  }
}

function* createSettingRedirect({ payload }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(() => axiosMicro.post('/redirects', payload));
    yield put(Actions.createSettingRedirectSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createSettingRedirectFailure(messages));
    }
  }
}

function* updateSettingRedirect({ payload }) {
  try {
    const { id, ...data } = payload;
    // eslint-disable-next-line no-unused-vars
    const response = yield call(() => axiosMicro.patch(`/redirects/${id}`, data));
    // if(response?.)
    yield put(Actions.updateSettingRedirectSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateSettingRedirectFailure(messages));
    }
  }
}

function* deleteSettingRedirect({ payload }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(() => axiosMicro.delete(`/redirects/${payload}`));
    // if(response?.)
    yield put(Actions.deleteSettingRedirectSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteSettingRedirectFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getSettingsRequest, getSettings);
  yield takeLatest(Actions.getAllSettingsRequest, getAllSettings);
  yield takeLatest(Actions.createSettingRequest, createSetting);
  yield takeLatest(Actions.saveSettingRequest, saveSetting);
  yield takeLatest(Actions.updateBankSettingRequest, updateBankSetting);
  yield takeLatest(Actions.deleteSettingRequest, deleteSetting);
  yield takeLatest(Actions.getAllSettingRedirectRequest, getAllSettingRedirect);
  yield takeLatest(Actions.createSettingRedirectRequest, createSettingRedirect);
  yield takeLatest(Actions.updateSettingRedirectRequest, updateSettingRedirect);
  yield takeLatest(Actions.deleteSettingRedirectRequest, deleteSettingRedirect);
}
