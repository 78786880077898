import { usLang, vnLang } from '~/configs/langs';
import { getCurrentLanguage } from './common';

export const translate = (id) => {
  const currentLang = getCurrentLanguage();
  let translateLang = '';
  if (currentLang === 'vn') {
    if (id) {
      translateLang = vnLang.messages[id];
    }
  } else if (currentLang === 'us') {
    if (id) {
      translateLang = usLang.messages[id];
    }
  }

  return translateLang;
};
