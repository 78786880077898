const warehouse = {
  'admin.warehouse.hetmet': 'Warehouse management',
  'admin.warehouse.breadcrumb.heading': 'Warehouse management',
  'admin.warehouse.breadcrumb.parent': 'Warehouse',
  'admin.warehouse.btn.add': 'Add Warehouse',
  'admin.warehouse.noti.create-warehouse.success': 'Add Warehouse success!',
  'admin.warehouse.noti.create-warehouse.failure': 'More Warehouse failed!Please try again later.',
  'admin.warehouse.noti.update-warehouse.success': 'Warehouse update!',
  'admin.warehouse.noti.update-warehouse.failure': 'Warehouse update failed!Please try again later.',
  'admin.warehouse.noti.delete-warehouse.success': 'Delete Warehouse successfully',
  'admin.warehouse.noti.delete-warehouse.failure': 'Delete Warehouse failed!Please try again later.',
  'admin.warehouse.modal.action.title.create': 'Add new',
  'admin.warehouse.modal.action.title.update': 'Update',
  'admin.warehouse.modal.action.title.details': 'Detail',
  'admin.warehouse.modal.action.btn.create': 'More',
  'admin.warehouse.modal.action.btn.update': 'Update',
  'admin.warehouse.modal.action.label.name': 'Name',
  'admin.warehouse.modal.action.label.phone': 'Phone',
  'admin.warehouse.modal.action.label.email': 'Email',
  'admin.warehouse.modal.action.label.province': 'Province',
  'admin.warehouse.modal.action.label.district': 'District',
  'admin.warehouse.modal.action.label.ward': 'Ward',
  'admin.warehouse.modal.action.label.address': 'Address',
  'admin.warehouse.modal.delete.title': 'Confirm delete Warehouse!',
  'admin.warehouse.modal.delete.description': 'All data associated with this warehouse will be permanently deleted, are you sure ?',
  'admin.warehouse.modal.views.title.products': 'List Products',
  'admin.warehouse.modal.views.title.product-history': 'Product History',
  'admin.warehouse.column.name': 'Name',
  'admin.warehouse.column.phone': 'Phone',
  'admin.warehouse.column.email': 'Email',
  'admin.warehouse.column.province': 'Province',
  'admin.warehouse.column.district': 'District',
  'admin.warehouse.column.ward': 'Ward',
  'admin.warehouse.column.address': 'Address',
  'admin.warehouse.product.column.code': 'Code',
  'admin.warehouse.product.column.name': 'Name',
  'admin.warehouse.product.column.price': 'Price',
  'admin.warehouse.product.column.number': 'Number',
  'admin.warehouse.product.column.in-stock': 'In stock',
  'admin.warehouse.product.column.export': 'Export',
  'admin.warehouse.product.column.expiry': 'Expiry',
  'admin.warehouse.product.column.expired': 'Expired',
  'admin.warehouse.product.column.type': 'type',
  'admin.warehouse.product.column.time': 'Time',
  'admin.warehouse.product.column.revenue': 'Revenue',
  'admin.warehouse.product.column.profit': 'Profit',
  'admin.warehouse.product.column.status': 'Status',
  'admin.warehouse.product.column.status-1': 'Complete',
  'admin.warehouse.product.column.status-2': 'Canceled',
  'admin.warehouse.type.item.website': 'website',
  'admin.warehouse.type.item.article': 'Posts',
  'admin.warehouse.type.item.profile': 'profile',
  'admin.warehouse.type.item.book': 'Book',
  'admin.warehouse.error-msg.name': 'Please fill out this school!',
  'admin.warehouse.error-msg.phone': 'Invalid phone number!',
  'admin.warehouse.error-msg.email': 'Invalid email format!',
  'admin.warehouse.error-msg.province': 'Please fill out this school!',
  'admin.warehouse.error-msg.district': 'Please fill out this school!',
  'admin.warehouse.error-msg.ward': 'Please fill out this school!',
  'admin.warehouse.error-msg.address': 'Please fill out this school!',
};

const warehouseChange = {
  'admin.warehouse-change.hetmet': 'Import and Export management',
  'admin.warehouse-change.breadcrumb.heading': 'Import and Export management',
  'admin.warehouse-change.breadcrumb.parent': 'Import and Export',
  'admin.warehouse-change.btn.add': 'Add Change Warehouse',
  'admin.warehouse-change.noti.create-warehouse.success': 'Add Change Warehouse success!',
  'admin.warehouse-change.noti.create-warehouse.failure': 'Add Change Warehouse failed!Please try again later.',
  'admin.warehouse-change.noti.update-warehouse.success': 'Warehouse Change update!',
  'admin.warehouse-change.noti.update-warehouse.failure': 'Warehouse Change update failed!Please try again later.',
  'admin.warehouse-change.noti.cancel-warehouse.success': 'Cancel Warehouse Change successfully',
  'admin.warehouse-change.noti.cancel-warehouse.failure': 'Cancel Warehouse Change failed!Please try again later.',
  'admin.warehouse-change.modal.action.title.create': 'Add new',
  'admin.warehouse-change.modal.action.title.update': 'Update',
  'admin.warehouse-change.modal.action.title.details': 'Detail',
  'admin.warehouse-change.modal.action.btn.create': 'More',
  'admin.warehouse-change.modal.action.btn.update': 'Update',
  'admin.warehouse-change.modal.action.label.name': 'Name',
  'admin.warehouse-change.modal.action.label.code': 'Code',
  'admin.warehouse-change.modal.action.label.style': 'Style',
  'admin.warehouse-change.modal.action.label.date': 'Date',
  'admin.warehouse-change.modal.action.label.number': 'Number',
  'admin.warehouse-change.modal.action.label.money': 'Money',
  'admin.warehouse-change.modal.action.label.supplier': 'Supplier',
  'admin.warehouse-change.modal.action.label.warehouse': 'Warehouse',
  'admin.warehouse-change.modal.action.label.warehouse-search': 'Search Warehouse..',
  'admin.warehouse-change.modal.action.label.user': 'User',
  'admin.warehouse-change.modal.action.label.order': 'Order',
  'admin.warehouse-change.modal.action.label.order-checked': 'Use Order',
  'admin.warehouse-change.modal.action.label.order-search': 'Search Order..',
  'admin.warehouse-change.modal.action.label.search-result': 'Search result',
  'admin.warehouse-change.modal.action.label.search-selected': 'Selected',
  'admin.warehouse-change.modal.action.label.status': 'Status',
  'admin.warehouse-change.modal.action.label.status-1': 'Complete',
  'admin.warehouse-change.modal.action.label.status-2': 'Canceled',
  'admin.warehouse-change.modal.action.label.note': 'Note',
  'admin.warehouse-change.modal.action.label.profit': 'Profit',
  'admin.warehouse-change.modal.action.label.products': 'List Product',
  'admin.warehouse-change.modal.action.label.product-code': 'Code',
  'admin.warehouse-change.modal.action.label.product-price': 'Price',
  'admin.warehouse-change.modal.action.label.product-number': 'Number',
  'admin.warehouse-change.modal.action.label.product-expiry': 'Expiry',
  'admin.warehouse-change.modal.action.label.products-search': 'Search Product',
  'admin.warehouse-change.modal.action.label.order-code': 'Code',
  'admin.warehouse-change.modal.column.code': 'Code',
  'admin.warehouse-change.modal.column.name': 'Name',
  'admin.warehouse-change.modal.column.number': 'Number',
  'admin.warehouse-change.modal.column.price': 'Price',
  'admin.warehouse-change.modal.column.money': 'Money',
  'admin.warehouse-change.modal.column.in-stock': 'In Stock',
  'admin.warehouse-change.modal.column.export': 'Export',
  'admin.warehouse-change.modal.column.expiry': 'Expiry',
  'admin.warehouse-change.modal.column.expiried': 'Expiried',
  'admin.warehouse-change.modal.cancel.title': 'Confirm cancel!',
  'admin.warehouse-change.modal.cancel.description': 'Are you sure to cancel this Warehouse Change?',
  'admin.warehouse-change.modal.views.title.products': 'List Products',
  'admin.warehouse-change.column.code': 'Code',
  'admin.warehouse-change.column.style': 'Style',
  'admin.warehouse-change.column.date': 'Date',
  'admin.warehouse-change.column.number': 'Number',
  'admin.warehouse-change.column.money': 'Money',
  'admin.warehouse-change.column.supplier': 'Supplier',
  'admin.warehouse-change.column.warehouse': 'Warehouse',
  'admin.warehouse-change.column.user': 'User',
  'admin.warehouse-change.column.order': 'Order',
  'admin.warehouse-change.column.status': 'Status',
  'admin.warehouse-change.column.status-1': 'Complete',
  'admin.warehouse-change.column.status-2': 'Canceled',
  'admin.warehouse-change.column.note': 'Note',
  'admin.warehouse-change.product.column.code': 'Code',
  'admin.warehouse-change.product.column.name': 'Name',
  'admin.warehouse-change.product.column.price': 'Price',
  'admin.warehouse-change.product.column.number': 'Number',
  'admin.warehouse-change.type.item.website': 'website',
  'admin.warehouse-change.type.item.article': 'Posts',
  'admin.warehouse-change.type.item.profile': 'profile',
  'admin.warehouse-change.type.item.book': 'Book',
  'admin.warehouse-change.error-msg.style': 'Please fill out this school!',
  'admin.warehouse-change.error-msg.warehouse': 'Please select warehouse!',
  'admin.warehouse-change.error-msg.products': 'Please select products/order!',
  'admin.warehouse-change.style-list.import': 'Import',
  'admin.warehouse-change.style-list.export': 'Export',
};

const supplier = {
  'admin.supplier.hetmet': 'Supplier management',
  'admin.supplier.breadcrumb.heading': 'Supplier management',
  'admin.supplier.breadcrumb.parent': 'Supplier',
  'admin.supplier.btn.add': 'Add Supplier',
  'admin.supplier.noti.create-supplier.success': 'Add Supplier success!',
  'admin.supplier.noti.create-supplier.failure': 'More Supplier failed!Please try again later.',
  'admin.supplier.noti.update-supplier.success': 'Supplier update!',
  'admin.supplier.noti.update-supplier.failure': 'Supplier update failed!Please try again later.',
  'admin.supplier.noti.delete-supplier.success': 'Delete Supplier successfully',
  'admin.supplier.noti.delete-supplier.failure': 'Delete Supplier failed!Please try again later.',
  'admin.supplier.modal.action.title.create': 'Add new',
  'admin.supplier.modal.action.title.update': 'Update',
  'admin.supplier.modal.action.title.details': 'Detail',
  'admin.supplier.modal.action.btn.create': 'More',
  'admin.supplier.modal.action.btn.update': 'Update',
  'admin.supplier.modal.action.label.name': 'Name',
  'admin.supplier.modal.action.label.phone': 'Phone',
  'admin.supplier.modal.action.label.email': 'Email',
  'admin.supplier.modal.action.label.province': 'Province',
  'admin.supplier.modal.action.label.district': 'District',
  'admin.supplier.modal.action.label.ward': 'Ward',
  'admin.supplier.modal.action.label.address': 'Address',
  'admin.supplier.modal.delete.title': 'Confirm delete Supplier!',
  'admin.supplier.modal.delete.description': 'Are you sure to delete this Supplier?',
  'admin.supplier.modal.views.title.products': 'List Products',
  'admin.supplier.modal.views.title.history': 'Import history',
  'admin.supplier.column.name': 'Name',
  'admin.supplier.column.phone': 'Phone',
  'admin.supplier.column.email': 'Email',
  'admin.supplier.column.province': 'Province',
  'admin.supplier.column.district': 'District',
  'admin.supplier.column.ward': 'Ward',
  'admin.supplier.column.address': 'Address',
  'admin.supplier.product.column.code': 'Code',
  'admin.supplier.product.column.name': 'Name',
  'admin.supplier.product.column.price': 'Price',
  'admin.supplier.product.column.number': 'Number',
  'admin.supplier.type.item.website': 'website',
  'admin.supplier.type.item.article': 'Posts',
  'admin.supplier.type.item.profile': 'profile',
  'admin.supplier.type.item.book': 'Book',
  'admin.supplier.error-msg.name': 'Please fill out this school!',
  'admin.supplier.error-msg.phone': 'Invalid phone number!',
  'admin.supplier.error-msg.email': 'Invalid email format!',
  'admin.supplier.error-msg.province': 'Please fill out this school!',
  'admin.supplier.error-msg.district': 'Please fill out this school!',
  'admin.supplier.error-msg.ward': 'Please fill out this school!',
  'admin.supplier.error-msg.address': 'Please fill out this school!',
  'admin.supplier.column.import-code': 'Code',
};

const usAdminWarehouseLangLib = {
  ...warehouse,
  ...warehouseChange,
  ...supplier,
};

export default usAdminWarehouseLangLib;
