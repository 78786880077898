const rating = {
  'admin.rating.hetmet': 'Đánh giá',
  'admin.rating.breadcrumb.heading': 'Danh sách các đánh giá',
  'admin.rating.breadcrumb.parent': 'Đánh giá',
  'admin.rating.noti.create-rating.success': 'Thêm đánh giá mới thành công!',
  'admin.rating.noti.create-rating.failure': 'Thêm đánh giá mới không thành công! Vui lòng thử lại sau.',
  'admin.rating.noti.delete-rating.success': 'Xoá đánh giá thành công!',
  'admin.rating.noti.delete-rating.failure': 'Thêm đánh giá không thành công! Vui lòng thử lại sau.',
  'admin.rating.column.created_at': 'Đăng vào lúc',
  'admin.rating.column.content': 'Nội dung',
  'admin.rating.column.score': 'Điểm',
  'admin.rating.column.rating-for': 'Đánh giá cho',
  'admin.rating.column.author': 'Người đánh giá',
  'admin.rating.label.client': 'Người dùng chưa đăng ký',
  'admin.rating.modal.delete.title': 'Xác nhận xoá đánh giá!',
  'admin.rating.modal.delete.description': 'Bạn chắc chắn muốn xoá đánh giá này?',
};

const vnAdminRatingLangLib = {
  ...rating,
};

export default vnAdminRatingLangLib;
