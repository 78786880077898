const feedback = {
  'admin.feedback.hetmet': 'Yêu cầu',
  'admin.feedback.breadcrumb.heading': 'Danh sách các yêu cầu',
  'admin.feedback.breadcrumb.parent': 'Yêu cầu',
  'admin.feedback.btn.add': 'Thêm yêu cầu mới',
  'admin.feedback.noti.create-feedback.success': 'Thêm yêu cầu thành công!',
  'admin.feedback.noti.create-feedback.failure': 'Thêm yêu cầu thất bại! Vui lòng thử lại sau.',
  'admin.feedback.noti.update-feedback.success': 'Cập nhật yêu cầu thành công!',
  'admin.feedback.noti.update-feedback.failure': 'Cập nhật yêu cầu thất bại! Vui lòng thử lại sau.',
  'admin.feedback.noti.delete-feedback.success': 'Xóa yêu cầu thành công',
  'admin.feedback.noti.delete-feedback.failure': 'Xóa yêu cầu thất bại! Vui lòng thử lại sau.',
  'admin.feedback.modal.action.title.create': 'Thêm yêu cầu mới',
  'admin.feedback.modal.action.title.update': 'Cập nhật yêu cầu',
  'admin.feedback.modal.action.title.details': 'Chi tiết yêu cầu',
  'admin.feedback.modal.action.btn.create': 'Thêm yêu cầu',
  'admin.feedback.modal.action.btn.update': 'Cập nhật yêu cầu',
  'admin.feedback.modal.action.label.type': 'Loại',
  'admin.feedback.modal.action.label.name': 'Người gửi',
  'admin.feedback.modal.action.label.phone': 'Số điện thoại',
  'admin.feedback.modal.action.label.email': 'Địa chỉ E-mail',
  'admin.feedback.modal.action.label.address': 'Địa chỉ',
  'admin.feedback.modal.action.label.content': 'Nội dung',
  'admin.feedback.modal.delete.title': 'Xác nhận xóa yêu cầu!',
  'admin.feedback.modal.delete.description': 'Bạn có chắc chắn muốn xóa yêu cầu này?',
  'admin.feedback.error-msg.type': 'Tối đa 50 ký tự!',
  'admin.feedback.error-msg.name': 'Vui lòng nhập họ tên!',
  'admin.feedback.column.type': 'Loại',
  'admin.feedback.column.name': 'Người gửi',
  'admin.feedback.column.phone': 'Số điện thoại',
  'admin.feedback.column.created_at': 'Ngày gửi',
};

const vnAdminFeedbackLangLib = {
  ...feedback,
};

export default vnAdminFeedbackLangLib;
