// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllSeosRequest: false,
  isGetAllSeosSuccess: false,
  isGetAllSeosFailure: false,
  getAllSeosState: {},
  //
  isCreateSeoRequest: false,
  isCreateSeoSuccess: false,
  isCreateSeoFailure: false,
  //
  isUpdateSeoRequest: false,
  isUpdateSeoSuccess: false,
  isUpdateSeoFailure: false,
  //
  isDeleteSeoRequest: false,
  isDeleteSeoSuccess: false,
  isDeleteSeoFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all seos
    [Actions.getAllSeosRequest]: (state) => ({
      ...state,
      isGetAllSeosRequest: true,
      isGetAllSeosSuccess: false,
      isGetAllSeosFailure: false,
    }),
    [Actions.getAllSeosSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllSeosRequest: false,
      isGetAllSeosSuccess: true,
      isGetAllSeosFailure: false,
      getAllSeosState: payload,
    }),
    [Actions.getAllSeosFailure]: (state, { payload }) => ({
      ...state,
      isGetAllSeosRequest: false,
      isGetAllSeosSuccess: false,
      isGetAllSeosFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create seo
    [Actions.createSeoRequest]: (state) => ({
      ...state,
      isCreateSeoRequest: true,
      isCreateSeoSuccess: false,
      isCreateSeoFailure: false,
    }),
    [Actions.createSeoSuccess]: (state, { payload }) => ({
      ...state,
      isCreateSeoRequest: false,
      isCreateSeoSuccess: true,
      isCreateSeoFailure: false,
      getAllSeosState:
        state.getAllSeosState?.meta?.pagination?.current_page === 1
          ? {
            ...state.getAllSeosState,
            data:
                state.getAllSeosState.meta.pagination.count
                === state.getAllSeosState.meta.pagination.per_page
                  ? [payload.data].concat(
                    state.getAllSeosState.data.slice(
                      0,
                      state.getAllSeosState.data.length - 1,
                    ),
                  )
                  : [payload.data].concat(state.getAllSeosState.data),
            meta: {
              ...state.getAllSeosState.meta,
              pagination: {
                ...state.getAllSeosState.meta.pagination,
                count:
                    state.getAllSeosState.meta.pagination.count
                    === state.getAllSeosState.meta.pagination.per_page
                      ? state.getAllSeosState.meta.pagination.per_page
                      : state.getAllSeosState.meta.pagination.count + 1,
                total: state.getAllSeosState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.getAllSeosState.meta.pagination.total + 1)
                      / state.getAllSeosState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.getAllSeosState },
    }),
    [Actions.createSeoFailure]: (state, { payload }) => ({
      ...state,
      isCreateSeoRequest: false,
      isCreateSeoSuccess: false,
      isCreateSeoFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateSeoState]: (state) => ({
      ...state,
      isCreateSeoRequest: false,
      isCreateSeoSuccess: false,
      isCreateSeoFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update seo
    [Actions.updateSeoRequest]: (state) => ({
      ...state,
      isUpdateSeoRequest: true,
      isUpdateSeoSuccess: false,
      isUpdateSeoFailure: false,
    }),
    [Actions.updateSeoSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateSeoRequest: false,
      isUpdateSeoSuccess: true,
      isUpdateSeoFailure: false,
      getAllSeosState: {
        ...state.getAllSeosState,
        data: state.getAllSeosState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.updateSeoFailure]: (state, { payload }) => ({
      ...state,
      isUpdateSeoRequest: false,
      isUpdateSeoSuccess: false,
      isUpdateSeoFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateSeoState]: (state) => ({
      ...state,
      isUpdateSeoRequest: false,
      isUpdateSeoSuccess: false,
      isUpdateSeoFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete seo
    [Actions.deleteSeoRequest]: (state) => ({
      ...state,
      isDeleteSeoRequest: true,
      isDeleteSeoSuccess: false,
      isDeleteSeoFailure: false,
    }),
    [Actions.deleteSeoSuccess]: (state) => ({
      ...state,
      isDeleteSeoRequest: false,
      isDeleteSeoSuccess: true,
      isDeleteSeoFailure: false,
    }),
    [Actions.deleteSeoFailure]: (state, { payload }) => ({
      ...state,
      isDeleteSeoRequest: false,
      isDeleteSeoSuccess: false,
      isDeleteSeoFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteSeoState]: (state) => ({
      ...state,
      isDeleteSeoRequest: false,
      isDeleteSeoSuccess: false,
      isDeleteSeoFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetSeoState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
