// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllFieldsRequest: false,
  isGetAllFieldsSuccess: false,
  isGetAllFieldsFailure: false,
  getAllFieldsState: {},
  //
  isCreateFieldRequest: false,
  isCreateFieldSuccess: false,
  isCreateFieldFailure: false,
  //
  isUpdateFieldRequest: false,
  isUpdateFieldSuccess: false,
  isUpdateFieldFailure: false,
  //
  isDeleteFieldRequest: false,
  isDeleteFieldSuccess: false,
  isDeleteFieldFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all types
    [Actions.getAllFieldsRequest]: (state) => ({
      ...state,
      isGetAllFieldsRequest: true,
      isGetAllFieldsSuccess: false,
      isGetAllFieldsFailure: false,
    }),
    [Actions.getAllFieldsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllFieldsRequest: false,
      isGetAllFieldsSuccess: true,
      isGetAllFieldsFailure: false,
      getAllFieldsState: {
        ...payload,
        data: payload.data.sort((a, b) => (a.sort > b.sort ? 0 : -1)),
      },
    }),
    [Actions.getAllFieldsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllFieldsRequest: false,
      isGetAllFieldsSuccess: false,
      isGetAllFieldsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create type
    [Actions.createFieldRequest]: (state) => ({
      ...state,
      isCreateFieldRequest: true,
      isCreateFieldSuccess: false,
      isCreateFieldFailure: false,
    }),
    [Actions.createFieldSuccess]: (state, { payload }) => ({
      ...state,
      isCreateFieldRequest: false,
      isCreateFieldSuccess: true,
      isCreateFieldFailure: false,
      getAllFieldsState: {
        ...state.getAllFieldsState,
        data: [...state.getAllFieldsState.data, payload],
      },
    }),
    [Actions.createFieldFailure]: (state, { payload }) => ({
      ...state,
      isCreateFieldRequest: false,
      isCreateFieldSuccess: false,
      isCreateFieldFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateFieldState]: (state) => ({
      ...state,
      isCreateFieldRequest: false,
      isCreateFieldSuccess: false,
      isCreateFieldFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update type
    [Actions.updateFieldRequest]: (state) => ({
      ...state,
      isUpdateFieldRequest: true,
      isUpdateFieldSuccess: false,
      isUpdateFieldFailure: false,
    }),
    [Actions.updateFieldSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateFieldRequest: false,
      isUpdateFieldSuccess: true,
      isUpdateFieldFailure: false,
      getAllFieldsState: {
        ...state.getAllFieldsState,
        data: state.getAllFieldsState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.updateFieldFailure]: (state, { payload }) => ({
      ...state,
      isUpdateFieldRequest: false,
      isUpdateFieldSuccess: false,
      isUpdateFieldFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateFieldState]: (state) => ({
      ...state,
      isUpdateFieldRequest: false,
      isUpdateFieldSuccess: false,
      isUpdateFieldFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete type
    [Actions.deleteFieldRequest]: (state) => ({
      ...state,
      isDeleteFieldRequest: true,
      isDeleteFieldSuccess: false,
      isDeleteFieldFailure: false,
    }),
    [Actions.deleteFieldSuccess]: (state, { payload }) => ({
      ...state,
      isDeleteFieldRequest: false,
      isDeleteFieldSuccess: true,
      isDeleteFieldFailure: false,
      getAllFieldsState: {
        ...state.getAllFieldsState,
        data: state.getAllFieldsState.data.filter(
          (item) => item.id !== payload,
        ),
      },
    }),
    [Actions.deleteFieldFailure]: (state, { payload }) => ({
      ...state,
      isDeleteFieldRequest: false,
      isDeleteFieldSuccess: false,
      isDeleteFieldFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteFieldState]: (state) => ({
      ...state,
      isDeleteFieldRequest: false,
      isDeleteFieldSuccess: false,
      isDeleteFieldFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetFieldState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
