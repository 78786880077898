// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllWidgetRequest: false,
  isGetAllWidgetSuccess: false,
  isGetAllWidgetFailure: false,
  getAllWidgetState: {},
  //
  isCreateWidgetRequest: false,
  isCreateWidgetSuccess: false,
  isCreateWidgetFailure: false,
  //
  isUpdateWidgetRequest: false,
  isUpdateWidgetSuccess: false,
  isUpdateWidgetFailure: false,
  //
  isDeleteWidgetRequest: false,
  isDeleteWidgetSuccess: false,
  isDeleteWidgetFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    [Actions.getAllWidgetRequest]: (state) => ({
      ...state,
      isGetAllWidgetRequest: true,
      isGetAllWidgetSuccess: false,
      isGetAllWidgetFailure: false,
    }),
    [Actions.getAllWidgetSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllWidgetRequest: false,
      isGetAllWidgetSuccess: true,
      isGetAllWidgetFailure: false,
      getAllWidgetState: payload,
    }),
    [Actions.getAllWidgetFailure]: (state, { payload }) => ({
      ...state,
      isGetAllWidgetRequest: false,
      isGetAllWidgetSuccess: false,
      isGetAllWidgetFailure: true,
      errorMessages: payload,
    }),

    // #region
    [Actions.createWidgetRequest]: (state) => ({
      ...state,
      isCreateWidgetRequest: true,
      isCreateWidgetSuccess: false,
      isCreateWidgetFailure: false,
    }),
    [Actions.createWidgetSuccess]: (state) => ({
      ...state,
      isCreateWidgetRequest: false,
      isCreateWidgetSuccess: true,
      isCreateWidgetFailure: false,
    }),
    [Actions.createWidgetFailure]: (state, { payload }) => ({
      ...state,
      isCreateWidgetRequest: false,
      isCreateWidgetSuccess: false,
      isCreateWidgetFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateWidgetState]: (state) => ({
      ...state,
      isCreateWidgetRequest: false,
      isCreateWidgetSuccess: false,
      isCreateWidgetFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region
    [Actions.updateWidgetRequest]: (state) => ({
      ...state,
      isUpdateWidgetRequest: true,
      isUpdateWidgetSuccess: false,
      isUpdateWidgetFailure: false,
    }),
    [Actions.updateWidgetSuccess]: (state) => ({
      ...state,
      isUpdateWidgetRequest: false,
      isUpdateWidgetSuccess: true,
      isUpdateWidgetFailure: false,
    }),
    [Actions.updateWidgetFailure]: (state, { payload }) => ({
      ...state,
      isUpdateWidgetRequest: false,
      isUpdateWidgetSuccess: false,
      isUpdateWidgetFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateWidgetState]: (state) => ({
      ...state,
      isUpdateWidgetRequest: false,
      isUpdateWidgetSuccess: false,
      isUpdateWidgetFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region
    [Actions.deleteWidgetRequest]: (state) => ({
      ...state,
      isDeleteWidgetRequest: true,
      isDeleteWidgetSuccess: false,
      isDeleteWidgetFailure: false,
    }),
    [Actions.deleteWidgetSuccess]: (state) => ({
      ...state,
      isDeleteWidgetRequest: false,
      isDeleteWidgetSuccess: true,
      isDeleteWidgetFailure: false,
    }),
    [Actions.deleteWidgetFailure]: (state, { payload }) => ({
      ...state,
      isDeleteWidgetRequest: false,
      isDeleteWidgetSuccess: false,
      isDeleteWidgetFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteWidgetState]: (state) => ({
      ...state,
      isDeleteWidgetRequest: false,
      isDeleteWidgetSuccess: false,
      isDeleteWidgetFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.resetWidgetState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
