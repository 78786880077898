/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllWidget({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/widgets${payload.params}` : '/widgets'));
    yield put(Actions.getAllWidgetSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllWidgetFailure(messages));
    }
  }
}

function* createWidget({ payload }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(() => axiosMicro.post('/widgets', payload));
    yield put(Actions.createWidgetSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createWidgetFailure(messages));
    }
  }
}

function* updateWidget({ payload }) {
  try {
    const { id, ...data } = payload;
    // eslint-disable-next-line no-unused-vars
    const response = yield call(() => axiosMicro.patch(`/widgets/${id}`, data));
    yield put(Actions.updateWidgetSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateWidgetFailure(messages));
    }
  }
}

function* deleteWidget({ payload }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = yield call(() => axiosMicro.delete(`/widgets/${payload}`));
    yield put(Actions.deleteWidgetSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteWidgetFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllWidgetRequest, getAllWidget);
  yield takeLatest(Actions.createWidgetRequest, createWidget);
  yield takeLatest(Actions.updateWidgetRequest, updateWidget);
  yield takeLatest(Actions.deleteWidgetRequest, deleteWidget);
}
