// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllKiotvietCategoriesRequest = createAction('GET_ALL_KIOTVIET_CATEGORIES_REQUEST');
export const getAllKiotvietCategoriesSuccess = createAction('GET_ALL_KIOTVIET_CATEGORIES_SUCCESS');
export const getAllKiotvietCategoriesFailure = createAction('GET_ALL_KIOTVIET_CATEGORIES_FAILURE');

export const createKiotvietCategoryRequest = createAction('CREATE_KIOTVIET_CATEGORY_REQUEST');
export const createKiotvietCategorySuccess = createAction('CREATE_KIOTVIET_CATEGORY_SUCCESS');
export const createKiotvietCategoryFailure = createAction('CREATE_KIOTVIET_CATEGORY_FAILURE');
export const resetCreateKiotvietCategory = createAction('RESET_CREATE_KIOTVIET_CATEGORY');

export const updateKiotvietCategoryRequest = createAction('UPDATE_KIOTVIET_CATEGORY_REQUEST');
export const updateKiotvietCategorySuccess = createAction('UPDATE_KIOTVIET_CATEGORY_SUCCESS');
export const updateKiotvietCategoryFailure = createAction('UPDATE_KIOTVIET_CATEGORY_FAILURE');
export const resetUpdateKiotvietCategory = createAction('RESET_UPDATE_KIOTVIET_CATEGORY');

export const deleteKiotvietCategoryRequest = createAction('DELETE_KIOTVIET_CATEGORY_REQUEST');
export const deleteKiotvietCategorySuccess = createAction('DELETE_KIOTVIET_CATEGORY_SUCCESS');
export const deleteKiotvietCategoryFailure = createAction('DELETE_KIOTVIET_CATEGORY_FAILURE');
export const resetDeleteKiotvietCategory = createAction('RESET_DELETE_KIOTVIET_CATEGORY');

export const resetKiotvietCategoryState = createAction('RESET_KIOTVIET_CATEGORY_STATE');
