// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  // Get All Kiotviet Product
  isFirstGetAllProductsKiotviet: false,
  isGetAllProductsKiotvietRequest: false,
  isGetAllProductsKiotvietSuccess: false,
  isGetAllProductsKiotvietFailure: false,
  getAllProductsKiotvietState: {},
  // Update Kiotviet Product
  isUpdateProductKiotvietRequest: false,
  isUpdateProductKiotvietSuccess: false,
  isUpdateProductKiotvietFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Kiotviet Product
    [Actions.getAllProductsKiotvietRequest]: (state) => ({
      ...state,
      isGetAllProductsKiotvietRequest: true,
      isGetAllProductsKiotvietSuccess: false,
      isGetAllProductsKiotvietFailure: false,
    }),
    [Actions.getAllProductsKiotvietSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllProductsKiotviet: true,
      isGetAllProductsKiotvietRequest: false,
      isGetAllProductsKiotvietSuccess: true,
      isGetAllProductsKiotvietFailure: false,
      getAllProductsKiotvietState: payload,
    }),
    [Actions.getAllProductsKiotvietFailure]: (state, { payload }) => ({
      ...state,
      isGetAllProductsKiotvietRequest: false,
      isGetAllProductsKiotvietSuccess: false,
      isGetAllProductsKiotvietFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Update information product
    [Actions.updateProductKiotvietRequest]: (state) => ({
      ...state,
      isUpdateProductKiotvietRequest: true,
      isUpdateProductKiotvietSuccess: false,
      isUpdateProductKiotvietFailure: false,
    }),
    [Actions.updateProductKiotvietSuccess]: (state) => ({
      ...state,
      isUpdateProductKiotvietRequest: false,
      isUpdateProductKiotvietSuccess: true,
      isUpdateProductKiotvietFailure: false,
    }),
    [Actions.updateProductKiotvietFailure]: (state, { payload }) => ({
      ...state,
      isUpdateProductKiotvietRequest: false,
      isUpdateProductKiotvietSuccess: false,
      isUpdateProductKiotvietFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateProductKiotviet]: (state) => ({
      ...state,
      isUpdateProductKiotvietRequest: false,
      isUpdateProductKiotvietSuccess: false,
      isUpdateProductKiotvietFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetProductKiotvietState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
