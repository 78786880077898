// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllSeosRequest = createAction('GET_ALL_SEOS_REQUEST');
export const getAllSeosSuccess = createAction('GET_ALL_SEOS_SUCCESS');
export const getAllSeosFailure = createAction('GET_ALL_SEOS_FAILURE');

export const createSeoRequest = createAction('CREATE_SEO_REQUEST');
export const createSeoSuccess = createAction('CREATE_SEO_SUCCESS');
export const createSeoFailure = createAction('CREATE_SEO_FAILURE');
export const resetCreateSeoState = createAction('RESET_CREATE_SEO_STATE');

export const updateSeoRequest = createAction('UPDATE_SEO_REQUEST');
export const updateSeoSuccess = createAction('UPDATE_SEO_SUCCESS');
export const updateSeoFailure = createAction('UPDATE_SEO_FAILURE');
export const resetUpdateSeoState = createAction('RESET_UPDATE_SEO_STATE');

export const deleteSeoRequest = createAction('DELETE_SEO_REQUEST');
export const deleteSeoSuccess = createAction('DELETE_SEO_SUCCESS');
export const deleteSeoFailure = createAction('DELETE_SEO_FAILURE');
export const resetDeleteSeoState = createAction('RESET_DELETE_SEO_STATE');

export const resetSeoState = createAction('RESET_SEO_STATE');
