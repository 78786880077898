import { NavLink } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { routesAdmin } from '~/configs/routes';
import { translate } from '~/helpers/utils';

const getMenuTitle = (sub) => {
  if (`/${sub}` === routesAdmin.dashboard1) {
    return translate('common.sidebar-main.dashboard.home');
  }
  return translate(sub);
};

export const BreadcrumbCustom = ({
  heading,
  parent,
  children,
  match,
  isProfile,
  customHeading,
  customParent,
}) => (
  <>
    <h1>{customHeading ? heading : translate(heading)}</h1>
    {children}
    <BreadcrumbItems
      parent={parent}
      match={match}
      isProfile={isProfile}
      customParent={customParent}
    />
  </>
);

const BreadcrumbItems = ({
  parent, match, isProfile, customParent,
}) => {
  const path = match.path.substr(1);
  let paths = path.split('/');
  if (paths[paths.length - 1].indexOf(':') > -1) {
    paths = paths.filter((x) => x.indexOf(':') === -1);
  }

  return (
    <>
      <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
        {isProfile ? (
          <>
            <BreadcrumbItem>
              <NavLink to={routesAdmin.dashboard1}>
                {translate('common.sidebar-main.dashboard.home')}
              </NavLink>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {translate('common.top-nav-dropdown-menu.account')}
            </BreadcrumbItem>
          </>
        ) : (
          <BreadcrumbItem active>
            {customParent ? parent : getMenuTitle(parent)}
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </>
  );
};
