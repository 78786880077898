// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllGroupsRequest: false,
  isGetAllGroupsSuccess: false,
  isGetAllGroupsFailure: false,
  getAllGroupsState: {},
  //
  isCreateGroupRequest: false,
  isCreateGroupSuccess: false,
  isCreateGroupFailure: false,
  //
  isUpdateGroupRequest: false,
  isUpdateGroupSuccess: false,
  isUpdateGroupFailure: false,
  //
  isDeleteGroupRequest: false,
  isDeleteGroupSuccess: false,
  isDeleteGroupFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all groups
    [Actions.getAllGroupsRequest]: (state) => ({
      ...state,
      isGetAllGroupsRequest: true,
      isGetAllGroupsSuccess: false,
      isGetAllGroupsFailure: false,
    }),
    [Actions.getAllGroupsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllGroupsRequest: false,
      isGetAllGroupsSuccess: true,
      isGetAllGroupsFailure: false,
      getAllGroupsState: payload,
    }),
    [Actions.getAllGroupsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllGroupsRequest: false,
      isGetAllGroupsSuccess: false,
      isGetAllGroupsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create group
    [Actions.createGroupRequest]: (state) => ({
      ...state,
      isCreateGroupRequest: true,
      isCreateGroupSuccess: false,
      isCreateGroupFailure: false,
    }),
    [Actions.createGroupSuccess]: (state, { payload }) => ({
      ...state,
      isCreateGroupRequest: false,
      isCreateGroupSuccess: true,
      isCreateGroupFailure: false,
      getAllGroupsState: {
        ...state.getAllGroupsState,
        data: [...state.getAllGroupsState.data, payload.data],
      },
    }),
    [Actions.createGroupFailure]: (state, { payload }) => ({
      ...state,
      isCreateGroupRequest: false,
      isCreateGroupSuccess: false,
      isCreateGroupFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateGroupState]: (state) => ({
      ...state,
      isCreateGroupRequest: false,
      isCreateGroupSuccess: false,
      isCreateGroupFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update group
    [Actions.updateGroupRequest]: (state) => ({
      ...state,
      isUpdateGroupRequest: true,
      isUpdateGroupSuccess: false,
      isUpdateGroupFailure: false,
    }),
    [Actions.updateGroupSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateGroupRequest: false,
      isUpdateGroupSuccess: true,
      isUpdateGroupFailure: false,
      getAllGroupsState: {
        ...state.getAllGroupsState,
        data: [...state.getAllGroupsState.data.map((item) => item.id === payload.data.id ? payload.data : item)],
      },
    }),
    [Actions.updateGroupFailure]: (state, { payload }) => ({
      ...state,
      isUpdateGroupRequest: false,
      isUpdateGroupSuccess: false,
      isUpdateGroupFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateGroupState]: (state) => ({
      ...state,
      isUpdateGroupRequest: false,
      isUpdateGroupSuccess: false,
      isUpdateGroupFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete group
    [Actions.deleteGroupRequest]: (state) => ({
      ...state,
      isDeleteGroupRequest: true,
      isDeleteGroupSuccess: false,
      isDeleteGroupFailure: false,
    }),
    [Actions.deleteGroupSuccess]: (state, { payload }) => ({
      ...state,
      isDeleteGroupRequest: false,
      isDeleteGroupSuccess: true,
      isDeleteGroupFailure: false,
      getAllGroupsState: {
        ...state.getAllGroupsState,
        data: [...state.getAllGroupsState.data.filter((item) => item.id !== payload)],
      },
    }),
    [Actions.deleteGroupFailure]: (state, { payload }) => ({
      ...state,
      isDeleteGroupRequest: false,
      isDeleteGroupSuccess: false,
      isDeleteGroupFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteGroupState]: (state) => ({
      ...state,
      isDeleteGroupRequest: false,
      isDeleteGroupSuccess: false,
      isDeleteGroupFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetGroupState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
