/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllKiotvietCategories({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/kiotviet/categories?${payload}`)
        : () => axiosMicro.get('/kiotviet/categories', { params: payload }),
    );
    yield put(Actions.getAllKiotvietCategoriesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllKiotvietCategoriesFailure(messages));
    }
  }
}

function* createKiotvietCategory({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/kiotviet/categories', payload));
    yield put(Actions.createKiotvietCategorySuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createKiotvietCategoryFailure(messages));
    }
  }
}

function* updateKiotvietCategory({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/kiotviet/categories/${payload.id}`, payload.body));
    yield put(Actions.updateKiotvietCategorySuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateKiotvietCategoryFailure(messages));
    }
  }
}

function* deleteKiotvietCategory({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/kiotviet/categories/${payload}`));
    yield put(Actions.deleteKiotvietCategorySuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteKiotvietCategoryFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllKiotvietCategoriesRequest, getAllKiotvietCategories);
  yield takeLatest(Actions.createKiotvietCategoryRequest, createKiotvietCategory);
  yield takeLatest(Actions.updateKiotvietCategoryRequest, updateKiotvietCategory);
  yield takeLatest(Actions.deleteKiotvietCategoryRequest, deleteKiotvietCategory);
}
